<template>
	<section id="overlay" v-if="showOverlayShare || showOverlaySaveModele || showOverlayGallery">
		<div class="overlaybox" id="gallery-overlay" v-if="showOverlayGallery">
			<div class="closeOverlay" @click="showGallery()"><i class="fal fa-times"></i></div>
			<h3>Choisissez une image de la galerie</h3>

			<p>
				En choisissant une image de la galerie, celle-ci sera mise à jour à chaque ouverture de votre présentation. Les images sont gérées par les administrateurs
				de l'outils.
			</p>

			<p v-if="globales_image.length === 0">
				<br />
				<strong style="color: #e30613">Aucune image dynamique n'est disponible pour le moment.</strong>
			</p>
			<div class="gallery" v-else>
				<div class="image" v-for="img in globales_image" :key="img.id" :data-id="img.id" :data-codecourt="img.fields.code_court">
					<div class="gauche">
						<img :src="img.fields.image" />
					</div>
					<div class="droite">
						<strong>{{ img.fields.code_court }}</strong>
						{{ img.fields.description }}

						<div v-if="img.fields.image_width">Longueur : {{ Math.round(img.fields.image_width) }}px</div>
						<div v-if="img.fields.image_height">Hauteur : {{ Math.round(img.fields.image_height) }}px</div>

						<button
							@click="
								useAsDynamicImage({
									dynamic_code: img.fields.code_court,
									is_dynamic: true,
								}).then(() => showGallery())
							"
						>
							Utiliser
						</button>
					</div>
				</div>
			</div>
		</div>
		<div class="overlaybox" id="share-overlay" v-if="showOverlayShare">
			<div class="closeOverlay" @click="showShare()"><i class="fal fa-times"></i></div>
			<h3>Partagez votre présentation</h3>
			<p>
				Ci-dessous, vous pouvez ajouter et supprimer les personnes avec qui vous souhaitez partager ce document. Une fois ajoutés, s'ils possède un compte, ils
				verront la présentation dans leur tableau de bord et pourront la modifier.
			</p>
			<vue-tags-input
				placeholder="Ajouter un email"
				v-model="tag"
				:tags="sharedWith"
				@adding-duplicate="handleDuplicate($event)"
				@before-adding-tag="verifTag($event)"
				@tags-changed="updateSharingPeople($event)"
			/>
		</div>
		<div class="overlaybox" id="save-overlay" v-if="showOverlaySaveModele">
			<div class="closeOverlay" @click="showSaveModele()"><i class="fal fa-times"></i></div>
			<h3>Sauvegardez votre présentation comme modèle</h3>
			<p style="margin-bottom: 30px">Une fois sauvegardée, votre présentation sera accessible à tous lors du processus de création d'une nouvelle offre.</p>
			<div class="form-group name">
				<label>Nom</label>
				<input @focus="is_focus = true" type="text" minlength="3" maxlength="40" v-model="modele_name" placeholder="Nommez votre modèle" @keyup="verifyName" />
				<ul
					v-if="currentUser.fields.role === 'super_administrateur' || currentUser.fields.role === 'administrateur'"
					class="suggestions"
					v-show="modele_name.length >= 3 && is_focus"
				>
					<h4>Modèles existants</h4>
					<li @click="setModeleName" v-for="(myOption, index) in myOptions" :key="`suggestion${index}`" class="suggestion">{{ myOption }}</li>
				</ul>
				<div class="retour_direct" v-if="retour">{{ retour }}</div>
			</div>
			<div class="form-group">
				<label>Type</label>
				<select v-model="modele_type">
					<option value="presentation">Présentation complète</option>
					<option value="groupe_slides">Groupe de diapositives</option>
				</select>
			</div>
			<div class="form-group" v-if="modele_type === 'groupe_slides'">
				<label>Périmètre</label>
				<select v-model="perimetre">
					<option value="all">Toutes les diaposivites</option>
					<option value="une_partie">Seulement les diaposivites n°...</option>
				</select>
			</div>
			<div class="form-group" v-if="modele_type === 'groupe_slides' && perimetre === 'une_partie'">
				<label>Exporter les diapositives de x à x</label>
				De <input type="number" v-model="diapo_debut" min="1" :max="presentation.slides.length + 1" placeholder="Début" /> à
				<input type="number" min="1" :max="presentation.slides.length + 1" v-model="diapo_fin" placeholder="Fin" />
			</div>
			<div class="form-group half">
				<label>Groupe Mauffrey</label>
				<select v-model="modele_groupe">
					<option value="">Choisissez...</option>
					<option v-for="item in categories.groupe_mauffrey" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
				</select>
			</div>
			<div class="form-group half">
				<label>Géographie</label>
				<select v-model="modele_geographie">
					<option value="">Choisissez...</option>
					<option v-for="item in categories.geographie" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
				</select>
			</div>
			<div class="form-group half">
				<label>Qualité</label>
				<select v-model="modele_qualite">
					<option value="">Choisissez...</option>
					<option v-for="item in categories.qualite" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
				</select>
			</div>
			<div class="form-group half">
				<label>Formation</label>
				<select v-model="modele_formation">
					<option value="">Choisissez...</option>
					<option v-for="item in categories.formation" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
				</select>
			</div>
			<div class="form-group half">
				<label>Digitalisation</label>
				<select v-model="modele_digitalisation">
					<option value="">Choisissez...</option>
					<option v-for="item in categories.digitalisation" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
				</select>
			</div>
			<div class="form-group half">
				<label>Métiers</label>
				<select v-model="modele_prestations">
					<option value="">Choisissez...</option>
					<option v-for="item in categories.metiers" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
				</select>
			</div>
			<div class="form-group half">
				<label>Filière</label>
				<select v-model="modele_filieres">
					<option value="">Choisissez...</option>
					<option v-for="item in categories.filieres" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
				</select>
			</div>
			<div class="form-group half">
				<label>Matériel</label>
				<select v-model="modele_materiel">
					<option value="">Choisissez...</option>
					<option v-for="item in categories.materiel" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
				</select>
			</div>
			<div class="form-group half">
				<label>Toolbox</label>
				<select v-model="modele_toolbox">
					<option value="">Choisissez...</option>
					<option v-for="item in categories.toolbox" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
				</select>
			</div>
			<div class="form-group half">
				<label>Procédures</label>
				<select v-model="modele_procedures">
					<option value="">Choisissez...</option>
					<option v-for="item in categories.procedures" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
				</select>
			</div>
			<div class="form-group full">
				<label>Tags</label>
				<vue-tags-input placeholder="Ajouter un tag" v-model="modele_tag" :tags="modele_tags" @tags-changed="(newTags) => (modele_tags = newTags)" />
			</div>

			<div class="save" v-if="ready_save_modele"><button @click="saveModele()">Sauvegarder en tant que modèle</button></div>
		</div>
	</section>
</template>
<script>
import VueTagsInput from "@johmun/vue-tags-input";
import { Notify } from "vant";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
	name: "Overlay",
	components: { VueTagsInput },
	data() {
		return {
			tag: "",
			quelles_prestations: "",
			users_tags: [],
			modele_groupe: "",
			modele_geographie: "",
			modele_qualite: "",
			modele_formation: "",
			modele_prestations: "",
			modele_digitalisation: "",
			modele_filieres: "",
			modele_materiel: "",
			modele_toolbox: "",
			modele_procedures: "",
			modele_tag: "",
			modele_tags: [],
			modele_name: "",
			modele_type: "presentation",
			perimetre: "all",
			diapo_debut: 1,
			diapo_fin: 1,
			retour: null,
			replace: false,
			replace_id: null,
			is_focus: false,
		};
	},
	computed: {
		globales_image() {
			let images = this.$store.state.globales.filter((el) => el.fields.type === "image");
			let filiale_choisie = this.$store.state.current_presentation.fields.filiale;
			if (filiale_choisie) {
				let zone = this.$store.state.zones.find((el) => el.id === this.$store.state.current_presentation.fields.filiale[0]);
				let final = [];
				images.forEach((el) => {
					if (el.fields.valeurs) {
						let valeurs = JSON.parse(el.fields.valeurs);
						let jeton_de_valeur = valeurs.filter((item) => item.zone === zone.fields.nom_zone);

						if (jeton_de_valeur && jeton_de_valeur.length > 0) {
							el.fields.image = jeton_de_valeur[0].valeur;
						}
					}
					console.log(el);
					final.push(el);
				});
				return final;
			} else {
				return images;
			}
		},
		// current_filiale() {
		// 	if (this.$store.state.current_presentation.fields.filiale && this.$store.state.current_presentation.fields.filiale.length) {
		// 		let zone = this.$store.state.zones.find((el) => el.id === this.$store.state.current_presentation.fields.filiale[0]);

		// 		let configuration = this.$store.state.zones[zone_index].fields.configuration;
		// 		return configuration;
		// 	} else {
		// 		return null;
		// 	}
		// },
		ready_save_modele() {
			return this.modele_name.length > 3;
		},
		myOptions() {
			let temp = [];
			this.$store.state.modeles.forEach((item) => {
				if (item.fields.nom.trim().toUpperCase().includes(this.modele_name.trim().toUpperCase())) {
					temp.push(item.fields.nom);
				}
			});
			return temp;
		},
		...mapGetters({
			tagsAutoComplete: "tagsAutoComplete",
			presentation: "currentPresentation",
			showOverlayShare: "showOverlayShare",
			showOverlayGallery: "showOverlayGallery",
			showOverlayCreate: "showOverlayCreate",
			showOverlaySaveModele: "showOverlaySaveModele",
			sharedWith: "sharedWith",
			tags: "tags",
			prestations: "prestations",
			filieres: "filieres",
			//globales_image: "globales_image",
			categories: "categories",
			currentUser: "currentUser",
		}),
	},
	methods: {
		setModeleName(e) {
			this.modele_name = e.currentTarget.innerHTML;
			this.is_focus = false;
			this.verifyName();
		},
		verifyName(e) {
			this.retour = "";
			this.replace = false;
			this.replace_id = null;
			this.modele_groupe = "";
			this.modele_geographie = "";
			this.modele_qualite = "";
			this.modele_formation = "";
			this.modele_toolbox = "";
			this.modele_prestations = "";
			this.modele_digitalisation = "";
			this.modele_filieres = "";
			this.modele_materiel = "";
			this.modele_procedures = "";
			this.modele_tags = [];
			this.modele_type = "presentation";
			this.$store.state.modeles.forEach((el) => {
				if (el.fields.nom.toUpperCase().trim() == this.modele_name.toUpperCase().trim()) {
					this.retour = "Le nom existe. Le modèle sera remplacé.";
					this.replace = true;
					this.replace_id = el.id;
					if (el.fields.groupe) {
						this.modele_groupe = el.fields.groupe[0];
					}
					if (el.fields.geographie) {
						this.modele_geographie = el.fields.geographie[0];
					}
					if (el.fields.qualite) {
						this.modele_qualite = el.fields.qualite[0];
					}
					if (el.fields.formation) {
						this.modele_formation = el.fields.formation[0];
					}
					if (el.fields.prestations) {
						this.modele_prestations = el.fields.prestations[0];
					}
					if (el.fields.digitalisation) {
						this.modele_digitalisation = el.fields.digitalisation[0];
					}
					if (el.fields.filieres) {
						this.modele_filieres = el.fields.filieres[0];
					}
					if (el.fields.materiel) {
						this.modele_materiel = el.fields.materiel[0];
					}
					if (el.fields.procedures) {
						this.modele_procedures = el.fields.procedures[0];
					}
					if (el.fields.toolbox) {
						this.modele_toolbox = el.fields.toolbox[0];
					}
					if (el.fields.tags) {
						this.modele_tags = JSON.parse(el.fields.tags);
					}
					if (el.fields.type) {
						this.modele_type = el.fields.type;
					}
				}
			});
		},
		...mapMutations({
			showSaveModele: "showSaveModele",
			showGallery: "showGallery",
		}),
		...mapActions({
			updateSharingPeople: "updateSharingPeople",
			showShare: "showShare",
			saveModeleToAirtable: "saveModeleToAirtable",
			useAsDynamicImage: "useAsDynamicImage",
		}),
		verifTag(e) {
			var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			if (re.test(e.tag.text)) {
				if (e.tag.text.includes("@mauffrey.com")) {
					e.addTag();
				} else {
					Notify({
						message: `Désolé! Seuls les emails @mauffrey.com sont autorisés pour le partage.`,
						background: "#fdf4e0",
						color: "#ffab00",
						duration: 4000,
					});
				}
			} else {
				Notify({
					message: `Oups! Il semblerait que cela ne ressemble pas à une adresse mail`,
					background: "#fdf4e0",
					color: "#ffab00",
					duration: 4000,
				});
			}
		},
		handleDuplicate(e) {
			Notify({
				message: `Oups! Vous partagez déjà cette présentation avec ${e.text} !`,
				background: "#fdf4e0",
				color: "#ffab00",
				duration: 4000,
			});
		},
		saveModele() {
			if (this.fin > this.presentation.slides.length + 1) {
				this.fin = this.presentation.slides.length + 1;
			}
			this.saveModeleToAirtable({
				nom: this.modele_name,
				filieres: [this.modele_filieres],
				prestations: [this.modele_prestations],
				procedures: [this.modele_procedures],
				toolbox: [this.modele_toolbox],
				materiel: [this.modele_materiel],
				digitalisation: [this.modele_digitalisation],
				formation: [this.modele_formation],
				qualite: [this.modele_qualite],
				geographie: [this.modele_geographie],
				groupe: [this.modele_groupe],
				tags: JSON.stringify(this.modele_tags),
				type: this.modele_type,
				perimetre: this.perimetre,
				debut: this.diapo_debut,
				fin: this.diapo_fin,
				replace: this.replace,
				replace_id: this.replace_id,
			})
				.then((res) => {
					this.modele_type = "presentation";
					this.perimetre = "all";
					this.nom = "";
					(this.debut = 1), (this.fin = 1);
					Notify({
						message: `Parfait ! Votre présentation est maintenant disponible en tant que modèle.`,
						background: "#e5f7ef",
						color: "#38cb89",
						duration: 4000,
					});
					this.showSaveModele();
				})
				.catch((err) => {
					this.modele_type = "presentation";
					this.perimetre = "all";
					this.nom = "";
					(this.debut = 1), (this.fin = 1);
					Notify({
						message: `Oups! Il semblerait que votre modèle n'ait pas été sauvegardé`,
						background: "#fdf4e0",
						color: "#ffab00",
						duration: 4000,
					});
					this.showSaveModele();
				});
		},
	},
};
</script>

<style lang="scss">
#overlay {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: rgba($gris, 0.97);
	backdrop-filter: blur(20px) saturate(20);
	z-index: 88;
	display: flex;
	align-items: flex-start;
	justify-content: flex-end;
	border-radius: 0;
	.overlaybox {
		padding: 50px;
		background: white;
		box-shadow: 2px 3px 20px rgba($black, 0.1);
		font-size: 12px;
		width: calc(100vw - 200px);
		animation: appearFromTop 0.5s ease backwards;
		animation-delay: 0.2s;
		color: rgba(black, 0.7);
		position: relative;
		height: 100%;
		gap: 30px;
		-webkit-overflow-scrolling: touch;
		overflow-y: auto;
		.closeOverlay {
			position: absolute;
			width: 50px;
			height: 50px;
			top: 0;
			right: 0;
			background: white;
			border-radius: 0 0 0 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			font-size: 20px;
			cursor: pointer;
			transition: all 0.3s ease;
			box-shadow: inset 2px -2px 4px rgba(black, 0.05);
			background: rgba($rouge, 0.1);
			color: $rouge;
			&:hover {
				box-shadow: inset 2px -2px 10px rgba($rouge, 0.2);
				background: $rouge;
				color: white;
			}
		}
		h3 {
			font-size: 16px;
			margin-bottom: 10px;
			color: black;
		}
		.save {
			display: flex;
			justify-content: flex-end;
			padding: 20px 0 0;
			button {
				background: $rouge;
				&:hover {
					background: darken($rouge, 5%);
				}
			}
		}
		.form-group {
			background: $gris;
			padding: 16px;
			border-radius: 20px;
			margin-bottom: 10px;
			display: flex;
			align-items: center;
			position: relative;
			&.name {
				ul.suggestions {
					position: absolute;
					top: 40px;
					left: 40px;
					z-index: 10;
					background: rgba($gris, 0.9);
					backdrop-filter: blur(6px);
					padding: 20px;
					box-shadow: 4px 4px 12px rgba($black, 0.1);
					max-height: 200px;
					overflow-x: hidden;
					overflow-y: auto;
					-webkit-overflow-scrolling: touch;
					list-style: none;
					margin: 0;
					padding: 0;
					h4 {
						font-size: 9px;
						text-transform: uppercase;
						padding: 16px 16px 8px;
						font-weight: 500;
						color: $vert;
					}
					.suggestion {
						padding: 12px 16px;
						font-size: 13px;
						font-weight: 500;
						cursor: pointer;
						&:hover {
							background: $vertclair;
							color: $vert;
						}
					}
				}
			}
			.retour_direct {
				position: absolute;
				bottom: 5px;
				left: 60px;
				color: $vert;
				font-size: 9px;
			}
			label {
				font-weight: 500;
				font-size: 9px;
				text-transform: uppercase;
				display: block;
				color: $rouge;
				display: block;
				text-align: right;
				flex: 0 0 30%;
				padding-right: 10px;
				border-right: solid 1px rgba(black, 0.1);
				margin-right: 10px;
			}
			input[type="text"],
			input[type="number"],
			select {
				height: 100%;
				background: transparent;
				border: none;
				outline: none;
				width: 100%;
				font-size: 13px;
				color: black;
				font-weight: 500;
				padding: 0;
			}
			input[type="number"] {
				background: white;
				width: 60px;
				height: 30px;
				margin: 0 10px;
				padding: 0 10px;
				text-align: center;
				box-shadow: inset 1px 1px 4px rgba(black, 0.1);
			}
			.vue-tags-input {
				border-radius: 0;
				margin: 0;
				.ti-input {
					padding: 0;
				}
			}
		}
		.vue-tags-input {
			border-radius: 20px;
			background: $gris;
			border: none;
			margin: 20px 0 0;
			transition: all 0.3s ease;
			&:hover {
				box-shadow: inset 2px 2px 4px rgba(black, 0.03);
			}
			.ti-input {
				border: none;
				padding: 16px;
				.ti-tag {
					border-radius: 3px;
					background: $vertclair;
					color: $vert;
					font-weight: 500;
					font-size: 12px;
					padding: 8px;
				}
				.ti-new-tag-input-wrapper {
					input {
						background: transparent;
					}
				}
			}
		}
		.gallery {
			display: flex;
			gap: 20px;
			flex: 1;
			background: $gris;
			border-radius: 20px;
			padding: 20px;
			gap: 20px;
			overflow-y: auto;
			-webkit-overflow-scrolling: touch;
			margin-top: 20px;
			box-shadow: inset 1px 1px 3px rgba(black, 0.1);
			height: calc(100% - 50px);
			background-image: url("../../assets/img/fond-alveole.png");
			background-position: top left;
			background-repeat: repeat;
			flex-wrap: wrap;
			align-content: flex-start;
			align-items: stretch;
			justify-content: flex-start;
			box-sizing: border-box;
			border-radius: 20px;
			.image {
				display: flex;
				align-items: center;
				flex: 0 0 auto;
				width: 32%;
				box-sizing: border-box;
				background: white;
				overflow: hidden;
				padding: 10px;
				@media screen and (max-width: 1440px) {
					width: 48%;
				}
				.gauche {
					flex: 0 0 40%;
					text-align: center;
					img {
						max-width: 100%;
						height: auto;
						flex: 0 0 50%;
						border-radius: 20px;
					}
				}
				.droite {
					flex: 0 0 60%;
					padding: 20px;
					line-height: 1.4;
					overflow: hidden;
					button {
						margin-top: 10px;
						line-height: 1;
					}
					strong {
						display: block;
						font-size: 14px;
						font-weight: bold;
						margin-bottom: 5px;
					}
				}
			}
		}
		&#save-overlay {
			max-width: 500px;
		}
	}
}
</style>
