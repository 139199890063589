<template>
	<section id="dashboardintroduction_archives" class="gauche">
		<div class="loading" v-if="!ready">
			<div>
				<i class="fal fa-spin fa-spinner"></i>
				Nous recherchons vos présentations. <br />Merci pour votre patience...
			</div>
		</div>
		<div class="tablezone">
			<table cellpadding="0" cellspacing="0" id="myTableArchives" class="table">
				<thead>
					<tr>
						<th width="40%">Nom</th>
						<th width="15%">Créé par</th>
						<th width="15%">Membres</th>
						<th width="17%" data-type="date" data-format="YYYY/MM/DD">Dernière modification</th>
						<th width="13%">Statut</th>
					</tr>
				</thead>
				<tbody v-if="ready">
					<tr v-for="pres in archives" :key="pres.id" :data-id="pres.id">
						<td>{{ pres.fields.nom }}</td>
						<td>
							<div v-if="pres.fields.auteur_email[0] === currentUser.email">Vous</div>
							<div v-else>{{ pres.fields.auteur_email[0] }}</div>
						</td>
						<td>
							<div v-if="pres.fields.partagee_avec.length === 1">Vous et {{ pres.fields.partagee_avec.length }} personne</div>
							<div v-if="pres.fields.partagee_avec.length > 1">Vous et {{ pres.fields.partagee_avec.length }} personnes</div>
							<div v-if="pres.fields.partagee_avec.length === 0">Aucun</div>
						</td>
						<td>{{ pres.fields.date_modifie_formatee }}</td>
						<td>
							<span :class="pres.fields.statut">{{ pres.fields.statut_formate }}</span>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</section>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { Dialog } from "vant";
export default {
	name: "Introduction",
	components: {},
	data() {
		return {
			ready: false,
		};
	},
	computed: {
		...mapGetters({
			archives: "presentations_archives",
			currentUser: "currentUser",
			showOverlayCreate: "showOverlayCreate",
		}),
	},
	methods: {
		...mapActions({
			modifyStatut: "modifyStatut",
			getPresentationsArchives: "getPresentationsArchives",
		}),
		...mapMutations({
			showCreate: "showCreate",
		}),
		changeStatut() {},
	},
	mounted() {
		let myTable = document.getElementById("myTableArchives");
		// const dataTable = new DataTable(myTable, {
		// 	searchable: true,
		// 	prevText: "Précédent",
		// 	nextText: "Suivant",
		// 	perPage: 15,
		// 	perPageSelect: [10, 15, 20, 25, 30, 50, 100, 200],
		// 	labels: {
		// 		placeholder: "Rechercher une présentation...",
		// 		perPage: "Afficher {select} présentations par page",
		// 		noRows: "Aucune présentation à afficher",
		// 		info: "Affichage de {start} à {end} sur {rows} présentation(s)",
		// 	},
		// });
		this.$store
			.dispatch("getPresentationsArchives")
			.then(() => {
				this.ready = true;
			})
			.then(() => {
				let rows = myTable.querySelectorAll("tbody tr");
				rows.forEach((el) => {
					el.addEventListener("click", () => {
						Dialog.confirm({
							title: "Action disponible",
							message: "Voulez-vous modifier la présentation de 'Archivée' à 'Brouillon' ?",
							confirmButtonText: "Oui",
							cancelButtonText: "Annuler",
						})
							.then(() => {
								this.modifyStatut({
									statut: "brouillon",
									id: el.dataset.id,
								}).then(() => {
									this.getPresentationsArchives();
								});
							})

							.catch(() => {
								// on cancel
							});
					});
				});
			})
			.catch((err) => {
				return;
			});
	},
};
</script>

<style lang="scss">
#dashboardintroduction_archives {
	background: white;
	padding: 30px;
	position: relative;
	overflow: hidden;
	.loading {
		padding: 40px;
		border-radius: 20px;
		background: $gris;
		div {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			text-align: center;
			font-weight: normal;
			font-size: 12px;
			line-height: 15px;
			i {
				color: $rouge;
				font-size: 20px;
				margin-bottom: 20px;
			}
		}
	}
	.tablezone {
		padding: 0px;
		border-radius: 20px;
		.dataTable-bottom {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			font-size: 11px;
			font-weight: 500;
			background: $gris;
			border-radius: 0 0 20px 20px;
			padding-bottom: 40px;
			.dataTable-info {
				padding: 0px 20px 15px 20px;
				opacity: 0.7;
				font-style: italic;
			}
			.dataTable-pagination {
				background: white;
				border-radius: 8px;
				box-shadow: 3px 3px 12px rgba(black, 0.05);
				ul {
					display: flex;
					margin: 0;
					padding: 0;
					list-style: none;
					li {
						border-left: solid 1px rgba(black, 0.05);
						transition: all 0.3s ease;
						a {
							color: black;
							padding: 12px 16px;
							display: block;
						}
						&.pager {
							font-size: 10px;
						}
						&.active {
							a {
								color: $rouge;
							}
						}
						&:hover {
							background: $gris;
						}
					}
				}
			}
		}
		.dataTable-top {
			display: flex;
			padding: 20px 0 20px 20px;
			align-items: center;
			justify-content: space-between;
			font-size: 12px;
			font-weight: 500;
			select {
				padding: 10px;
				border: none;
				border-radius: 15px;
				background: $gris;
				margin: 0 6px;
				font-weight: 500;
				outline: none;
			}
			.dataTable-search {
				border-radius: 15px;
				padding: 0;
				background: $gris;
				border-radius: solid 1px $gris2;
				border: solid 1px transparent;
				transition: all 0.3s ease;
				input {
					width: 100%;
					height: 43px;
					border: none;
					padding: 0 16px;
					border-radius: 15px;
					width: 280px;
					background: transparent;
					font-weight: 500;
					font-size: 13px;
				}
			}
		}
		table {
			width: 100%;
			background: white;
			border-radius: 8px;
			border: solid 1px $gris2;
			box-shadow: 3px 3px 12px rgba(black, 0.05);
			thead {
				tr {
					th {
						border-bottom: thin solid $gris2;
						height: 48px;
						padding: 0 16px;
						user-select: none;
						text-align: left;
						font-size: 11px;
						a {
							font-weight: 600;
							color: black !important;
						}
					}
				}
			}
			tbody {
				tr {
					padding: 0;
					margin: 0;
					border: none;
					cursor: pointer;
					td {
						border: none;
						border-bottom: thin solid $gris;
						height: 48px;
						font-size: 13px;
						padding: 0 16px;
						font-weight: 300;
						transition: all 0.3s ease;
						&.dataTables-empty {
							display: none;
						}
						&:first-child {
							font-weight: bold;
						}
						&:last-child {
							span {
								background: $gris2;
								display: inline-block;
								padding: 6px 12px;
								font-weight: 500;
								font-size: 12px;
								border-radius: 20px;
								&.brouillon {
									background: #fdf4e0;
									color: $jaune;
								}
								&.pret {
									background: #f4f8ff;
									color: $bleu;
								}
								&.envoye {
									background: #e5f7ef;
									color: $vert;
								}
							}
						}
					}
					&:hover {
						td {
							background: $gris;
						}
					}
				}
			}
		}
		.dataTable-container {
			padding: 40px;
			border-radius: 20px 20px 0 0;
			background: $gris;
		}
	}
}
</style>
