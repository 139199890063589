<template>
	<div class="box_ajout_cp">
		<h3>Ajouter un modèle ou un squelette</h3>
		<div :class="[!currentUser.fields.role.includes('administrateur') ? 'editeur' : 'admin', 'liste_des_modeles']">
			<div class="filtres">
				<div class="search">
					<input type="text" v-model="filtre" @keyup="filterModeles()" placeholder="Tapez un mot clé pour filtrer les résultats" />
					<i class="fa-regular fa-search"></i>
				</div>
				<div class="categories" v-if="active_tab === 'modele'">
					<select v-model="classes_filter" @change="filterModeles()">
						<option value="" selected="selected">Filtrer par...</option>
						<optgroup label="Type de modèle">
							<option value="slidevierge">Vierge</option>
							<option value="slideslide">Prêt à l'emploi</option>
							<option value="private" v-if="currentUser.fields.role.includes('administrateur')">Privés</option>
						</optgroup>
						<optgroup label="Groupe Mauffrey">
							<option value="all-groupe_mauffrey">Tous</option>
							<option v-for="item in $store.state.groupe_mauffrey" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
						</optgroup>
						<optgroup label="Géographie">
							<option value="all-geographie">Tous</option>
							<option v-for="item in $store.state.geographie" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
						</optgroup>
						<optgroup label="Environnement / Sécurité">
							<option value="all-qualite">Tous</option>
							<option v-for="item in $store.state.qualite" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
						</optgroup>
						<optgroup label="Formation">
							<option value="all-formation">Tous</option>
							<option v-for="item in $store.state.formation" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
						</optgroup>
						<optgroup label="Digitalisation">
							<option value="all-digitalisation">Tous</option>
							<option v-for="item in $store.state.digitalisation" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
						</optgroup>
						<optgroup label="Métiers">
							<option value="all-prestations">Tous</option>
							<option v-for="item in $store.state.prestations" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
						</optgroup>
						<optgroup label="Filières">
							<option value="all-filieres">Tous</option>
							<option v-for="item in $store.state.filieres" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
						</optgroup>
						<optgroup label="Matériel">
							<option value="all-materiel">Tous</option>
							<option v-for="item in $store.state.materiel" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
						</optgroup>
						<optgroup label="Toolbox">
							<option value="all-toolbox">Tous</option>
							<option v-for="item in $store.state.toolbox" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
						</optgroup>
						<optgroup label="Procédures">
							<option value="all-procedures">Tous</option>
							<option v-for="item in $store.state.procedures" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
						</optgroup>
					</select>
				</div>
			</div>
			<div v-if="!$store.state.squelettes_ready" class="chargement">
				<i class="fad fa-spinner-third fa-spin"></i>
				<div>
					<strong>Nous sommes en train de charger les modèles</strong>
					Le chargement des modèles peut durer un certain temps car ils sont nombreux. La vitesse de téléchargement dépend essentiellement de la vitesse de
					votre connexion.
					<br />Veuillez patienter quelques instants.
				</div>
			</div>
			<div class="nav_bar" v-show="$store.state.squelettes_ready">
				<div :class="`item modele ${active_tab === 'modele' ? 'active' : ''}`" @click="active_tab = 'modele'">Modèles</div>
				<div :class="`item squelette ${active_tab === 'squelette' ? 'active' : ''}`" @click="active_tab = 'squelette'">Squelettes</div>
			</div>

			<div class="liste" v-show="active_tab === 'modele' && $store.state.squelettes_ready">
				<div
					:class="[selected_modele && selected_modele.filter((e) => e.id === modele.id).length > 0 ? 'current item' : 'item', modele.classes]"
					v-for="modele in modeles"
					:key="modele.id"
					@click="select_modele(modele)"
				>
					<div class="preview">
						<img :src="modele.fields.preview_url" />
					</div>
					<div class="name">{{ modele.fields.nom }}</div>
					<div class="hidden">{{ modele.fields.tags }} {{ modele.fields.nom }}</div>
				</div>
			</div>
			<div class="liste" v-show="active_tab === 'squelette' && $store.state.squelettes_ready">
				<div
					:class="[selected_squelette && selected_squelette.filter((e) => e.id === squelette.id).length > 0 ? 'current item' : 'item']"
					v-for="squelette in squelettes"
					:key="squelette.id"
					@click="addSquelette(squelette)"
				>
					<div class="preview">
						<img v-if="squelette.fields.preview_url" :src="squelette.fields.preview_url" />
					</div>
					<div class="name">{{ squelette.fields.nom }}</div>
					<div class="hidden">{{ squelette.fields.tags }} {{ squelette.fields.nom }}</div>
				</div>
			</div>
		</div>
		<div class="addToComposition" v-if="selected_modele && active_tab === 'modele'" @click="addSlideModele">Ajouter le modèle</div>
		<div class="addToComposition" v-if="selected_squelette && active_tab === 'squelette' && !isloadingSquelette" @click="addSlidesSquelette">Ajouter le squelette</div>
		<div class="addToComposition" v-if="selected_squelette && active_tab === 'squelette' && isloadingSquelette">
			Chargement du squelette <i class="fal fa-spin fa-spinner-third" style="margin-left: 10px"></i>
		</div>
	</div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import slugify from "slugify";
import { runInThisContext } from "vm";

export default {
	name: "boxajout",
	components: {},
	data() {
		return {
			filtre: "",
			active_tab: "modele",
			selected_modele: [],
			selected_squelette: [],
			isloadingSquelette: false,
		};
	},
	computed: {
		modeles() {
			return this.modeles_base.filter(el => el.fields.langue === this.currentPresentation.langue);
		},
		squelettes() {
			return this.squelettes_base.filter(el => el.fields.langue === this.currentPresentation.langue);
		},
		classes_filter: {
			get() {
				return this.$store.state.active_filtre_box_ajout;
			},
			set(value) {
				this.$store.commit("updateFiltreBoxAjout", value);
			},
		},
		...mapGetters({
			modeles_base: "modeles",
			squelettes_base: "squelettes_publies",
			currentDiapositive: "currentDiapositive",
			currentUser: "currentUser",
			currentPresentation: "currentPresentation",
			//classes_filter: "active_filtre_box_ajout",
		}),
	},
	watch: {
		active_tab: function () {
			this.classes_filter = "";
			this.filtre = "";
			this.filterModeles();
		},
	},
	methods: {
		...mapMutations({
			updateFiltreBoxAjout: "updateFiltreBoxAjout",
		}),
		async selectSlide(slide) {
			this.$store.dispatch("selectSlide", slide);
		},
		filterModeles() {
			if (this.active_tab === "squelette") {
				if (this.filtre.length >= 3) {
					let items = document.querySelectorAll(".box_ajout_cp .liste .item");
					items.forEach((el) => {
						let tags = el.querySelector(".hidden").innerText;
						if (tags.toLowerCase().includes(this.filtre.toLowerCase())) {
							el.classList.remove("hidden");
						} else {
							el.classList.add("hidden");
						}
					});
				} else {
					let items = document.querySelectorAll(".box_ajout_cp .liste .item");
					items.forEach((el) => {
						el.classList.remove("hidden");
					});
				}
			} else {
				let items = document.querySelectorAll(".box_ajout_cp .liste .item");
				if (this.filtre.length < 3 && this.classes_filter === "") {
					items.forEach((el) => {
						if(el.classList.contains("français")) {
							el.classList.remove("hidden");
						} else {
							el.classList.add("hidden");
						}
						el.classList.remove("hidden");
					});
				} else {
					items.forEach((el) => {
						let must_be_show = 0;
						if (this.filtre.length >= 3) {
							let tags = el.querySelector(".hidden").innerText;
							if (slugify(tags).toLowerCase().includes(slugify(this.filtre).toLowerCase())) {
								must_be_show++;
							}
						}
						if (this.classes_filter != "") {
							if (el.classList.contains(this.classes_filter)) {
								must_be_show++;
							}
						}
						if (must_be_show >= 1) {
							el.classList.remove("hidden");
						} else {
							el.classList.add("hidden");
						}
					});
				}
			}
		},
		addSlideModele() {
			if (this.selected_modele.length) {
				let i = 0;
				this.selected_modele.forEach((item) => {
					i++;
					if (item.fields.fichier_de_config) {
						fetch(item.fields.fichier_de_config[0].url)
							.then((res) => res.json())
							.then((json) => {
								this.$store.dispatch("incrementUseCounter", item.id);
								this.$store
									.dispatch("addSlide", {
										modele: json,
										position: this.$store.state.currentDiapositive + i,
									})
									.then(() => {
										let currentSlideSelected = document.querySelector("#editor .slide-wrapper .slide.selected");
										let next = currentSlideSelected.parentNode.nextElementSibling;
										if (next) {
											let navigationbar = next.querySelector(".navigation");
											if (navigationbar) {
												navigationbar.click();
											}
										} else {
											setTimeout(() => {
												let last_slide = document.querySelector("#editor #rendu .slide-wrapper:last-child");
												let navigationbar = last_slide.querySelector(".navigation");
												if (navigationbar) {
													navigationbar.click();
												}
											}, 200);
										}
									});
							});
					}
				});
			}
		},
		addSlidesSquelette() {
			if (this.selected_squelette.length) {
				this.selected_squelette.forEach((item) => {
					let temp = [];
					this.isloadingSquelette = true;
					this.$store.dispatch("incrementUseCounterSquelette", item.id);
					if (item.fields.composition_new) {
						let parsing = JSON.parse(item.fields.composition_new);
						parsing.forEach(async (slide, index) => {
							let modele = this.modeles.find((el) => el.id === slide);
							if (modele) {
								if (modele.fields.fichier_de_config) {
									fetch(modele.fields.fichier_de_config[0].url)
										.then((res) => res.json())
										.then((json) => {
											let config;
											if (json[0]) {
												config = json[0];
											} else {
												config = json;
											}
											temp.push({
												modele: config,
												position: this.currentDiapositive + (index + 1),
											});
										});
								} else {
									console.log("Attention, pas de config", index);
								}
							} else {
								console.log("Attention, pas de modèle", slide);
							}
						});
						setTimeout(() => {
							this.isloadingSquelette = false;
							temp.sort((a, b) => a.position - b.position);
							this.$store.dispatch("addSlideSquelette", temp);
							setTimeout(() => {
								window.scrollTo({
									top: window.scrollY + 1200,
									left: 0,
									behavior: "smooth",
								});
							}, 50);
						}, 5000);
					}
				});
			}
		},
		select_modele(modele) {
			// fonction qui supprime l'element d'un tableau à un index donné
			let present = this.selected_modele.filter((e) => e.id === modele.id);
			if (present.length > 0) {
				let finded_index = this.selected_modele.findIndex((el) => el.id === modele.id);
				if (finded_index >= 0) {
					this.selected_modele.splice(finded_index, 1);
				}
			} else {
				this.selected_modele.push(modele);
			}
		},
		addSquelette(squelette) {
			// fonction qui supprime l'element d'un tableau à un index donné
			let present = this.selected_squelette.filter((e) => e.id === squelette.id);
			if (present.length > 0) {
				let finded_index = this.selected_squelette.findIndex((el) => el.id === squelette.id);
				if (finded_index >= 0) {
					this.selected_squelette.splice(finded_index, 1);
				}
			} else {
				this.selected_squelette.push(squelette);
			}
		},
	},
	mounted() {
		this.filterModeles();
	},
};
</script>

<style lang="scss" scoped>
.box_ajout_cp {
	width: 100%;
	max-width: 700px;
	margin: -50px auto 80px;
	background: white;
	border-radius: 10px;
	padding: 10px 0 0;
	display: flex;
	align-items: center;
	flex-direction: column;
	box-shadow: 3px 3px 20px rgba(black, 0.2);
	position: relative;
	.liste_des_modeles {
		width: 100%;
	}
	&:before {
		content: "";
		width: 4px;
		height: 80px;
		position: absolute;
		top: -80px;
		background: white;
		left: calc(50% - 2px);
		box-shadow: 0px 0px 10px rgba(black, 0.2);
	}
	&:after {
		content: "";
		width: 4px;
		height: 80px;
		position: absolute;
		bottom: -80px;
		background: white;
		left: calc(50% - 2px);
		box-shadow: 0px 0px 10px rgba(black, 0.2);
	}
	h3 {
		font-size: 15px;
		font-weight: 500;
		text-align: center;
		margin-top: 10px;
		span {
			font-size: 12px;
			margin-top: 6px;
			display: block;
			color: slategray;
		}
	}
	.chargement {
		display: flex;
		align-items: flex-start;
		padding: 30px;
		font-weight: 500;
		flex: 1;
		i {
			font-size: 20px;
			color: $rouge;
			margin-right: 10px;
			display: inline-block;
		}
		div {
			margin-top: 0px;
			font-weight: 300;
			font-size: 12px;
			line-height: 20px;
			strong {
				display: block;
				font-weight: 600;
				font-size: 14px;
				margin-bottom: 5px;
			}
		}
	}
	.nav_bar {
		display: flex;
		.item {
			flex: 1;
			cursor: pointer;
			padding: 10px;
			font-weight: 500;
			text-align: center;
			color: rgba(black, 0.4);
			font-size: 12px;
			border-bottom: solid 1px rgba(black, 0.08);
			&.active {
				border-bottom-color: $rouge;
				color: $rouge;
			}
		}
	}
	.filtres {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 15px auto 10px;
		select {
			background: rgba(black, 0.04);
			height: 40px;
			border-radius: 5px;
			border: none;
			padding: 0 10px;
			font-size: 13px;
			margin-left: 10px;
		}
	}
	.search {
		width: 100%;
		max-width: 300px;
		position: relative;
		background: rgba(black, 0.04);
		padding: 0 10px;
		border-radius: 5px;

		input {
			width: 100%;
			outline: none;
			height: 40px;
			font-size: 13px;
			font-weight: 500;
			border: none;
			background: transparent;
			&::placeholder {
				font-weight: 300;
				font-size: 11px;
			}
		}
		i,
		svg {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 10px;
		}
	}
	.liste {
		display: flex;
		width: 100%;
		height: 300px;
		background: rgba(black, 0.06);
		overflow: auto;
		-webkit-overflow-scrolling: touch;
		overscroll-behavior: none;
		flex-wrap: wrap;
		padding: 10px;
		align-items: flex-start;
		.item {
			flex: 0 0 33.3333%;
			padding: 6px;
			position: relative;
			img {
				max-width: 100%;
				border-radius: 4px;
			}
			.hidden {
				display: none;
			}
			.name {
				font-size: 12px;
				position: absolute;
				bottom: 6px;
				right: 6px;
				left: 6px;
				background: black;
				color: white;
				padding: 5px 10px;
				border-radius: 0 0 4px 4px;
			}
			.preview {
				aspect-ratio: 16/9;
				background-color: white;
				border-radius: 4px;
			}
			&.hidden {
				display: none;
			}
			&.current {
				display: block !important;
				img {
					outline: solid 4px $bleu;
					box-shadow: 0px 0px 10px rgba($bleu, 0.3);
				}
				.name {
					background: $bleu;
				}
			}
		}
	}
	.addToComposition {
		margin-top: 10px;
		margin-bottom: 10px;
		display: inline-block;
		padding: 12px 30px;
		border-radius: 5px;
		background: $rouge;
		color: $rougeclair;
		transition: all 0.3s ease;
		cursor: pointer;
		&:hover {
			transform: translateY(2px);
			box-shadow: 1px 1px 8px rgba(black, 0.1);
			background: $rouge;
		}
	}
	.liste_des_modeles.editeur {
		.liste {
			.item.private {
				display: none;
			}
		}
	}
}
</style>
