<template>
	<div class="context_menus">
		<div
			class="menu"
			v-if="composantContext.state"
			id="composant_menu"
			:style="`left: ${composantContext.x}px; top: ${composantContext.y}px`"
			@mouseleave="hideComposantSubMenu()"
		>
			<h6>Composant</h6>
			<div @click="clone() && hideComposantSubMenu()"><i class="fal fa-clone"></i>Dupliquer</div>
			<div @click="copy() && hideComposantSubMenu()"><i class="fal fa-copy"></i>Copier</div>
			<div v-if="pressePapier.type" @click="paste() && hideComposantSubMenu()"><i class="fal fa-paste"></i>Coller</div>
			<div @click="remove() && hideComposantSubMenu()"><i class="fal fa-trash"></i>Supprimer</div>
			<div @click="goUp()"><i class="fal fa-level-up-alt"></i>Mettre en avant</div>
			<div @click="goDown()"><i class="fal fa-level-down-alt"></i>Mettre en arrière</div>
			<div @click="goUpForce()"><i class="fal fa-level-up-alt"></i>Forcer au premier plan</div>
			<div @click="goDownForce()"><i class="fal fa-level-down-alt"></i>Forcer à l'arrière plan</div>
			<div class="sep"></div>
			<h6>Diapositive</h6>
			<div @click="duplicateSlide() && hideComposantSubMenu()"><i class="fal fa-copy"></i>Dupliquer la diapositive</div>
			<div
				@click="createSlideModele() && hideComposantSubMenu()"
				v-if="currentUser.fields.role === 'super_administrateur' || currentUser.fields.role === 'administrateur'"
			>
				<i class="fal fa-file"></i>Exporter en tant que modèle
			</div>
		</div>
		<div class="menu" v-if="slideContext.state" id="composant_menu" :style="`left: ${slideContext.x}px; top: ${slideContext.y}px`" @mouseleave="hideSlideSubMenu()">
			<h6>Composant</h6>
			<div v-if="pressePapier.type" @click="paste() && hideComposantSubMenu()"><i class="fal fa-paste"></i>Coller</div>
			<h6>Diapositive</h6>
			<div @click="showChooseSlide() && hideComposantSubMenu()"><i class="fal fa-plus"></i>Ajouter une diapositive</div>
			<div @click="duplicateSlide() && hideComposantSubMenu()"><i class="fal fa-copy"></i>Dupliquer la diapositive</div>
			<div @click="removeSlide() && hideComposantSubMenu()"><i class="fal fa-trash"></i>Supprimer la diapositive</div>
			<div @click="moveSlide($event) && hideComposantSubMenu()"><i class="fal fa-arrows-alt-v"></i>Déplacer la diapositive vers...</div>
			<div
				@click="createSlideModele() && hideComposantSubMenu()"
				v-if="currentUser.fields.role === 'super_administrateur' || currentUser.fields.role === 'administrateur'"
			>
				<i class="fal fa-file"></i>Exporter en tant que modèle
			</div>
		</div>
	</div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { Dialog } from "vant";
export default {
	name: "contextmenu",
	components: {},
	data() {
		return {};
	},
	computed: {
		...mapGetters({
			composantContext: "composantContext",
			slideContext: "slideContext",
			pressePapier: "pressePapier",
			currentUser: "currentUser",
		}),
	},
	methods: {
		...mapMutations({
			showChooseSlide: "showChooseSlide",
			removeElement: "removeElement",
			removeElementSlide: "removeElementSlide",
			goUp: "upComposant",
			goUpForce: "upComposantForce",
			goDown: "downComposant",
			goDownForce: "downComposantForce",
			paste: "pasteComposant",
			moveSlide: "moveSlide",
		}),
		...mapActions({
			hideComposantSubMenu: "hideComposantSubMenu",
			hideSlideSubMenu: "hideSlideSubMenu",
			clone: "cloneComposant",
			copy: "copyComposant",
			duplicateSlide: "duplicateSlide",
			addSlide: "addSlide",
			createSlideModele: "exportSlideToModele",
		}),
		remove() {
			this.removeElement();
			this.hideComposantSubMenu();
		},
		removeSlide() {
			Dialog.confirm({
				title: "Suppression d'une diapositive",
				message: "Voulez-vous vraiment supprimer cette diapositive ?",
				confirmButtonText: "Oui",
				cancelButtonText: "Annuler",
			})
				.then(() => {
					this.removeElementSlide();
				})
				.catch(() => {
					// on cancel
				});
		},
	},
};
</script>

<style lang="scss">
.context_menus {
	.menu {
		position: fixed;
		z-index: 999;
		border-radius: 10px;
		background: white;
		border: solid 1px rgba(black, 0.1);
		filter: drop-shadow(2px 2px 6px rgba(black, 0.1));
		font-size: 12px;
		font-weight: 500;
		padding: 10px;
		width: 240px;
		animation: appearFromTop 0.2s ease-in-out backwards;
		div {
			padding: 10px;
			transition: all 0.2s ease;
			cursor: pointer;
			border-radius: 10px;
			display: flex;
			align-items: center;
			i {
				flex: 0 0 20px;
			}
			&:hover {
				background: $gris;
				box-shadow: inset 2px 2px 2px rgba(black, 0.1);
			}
			&.sep {
				border: none;
				padding: 0;
				height: 6px;
			}
		}
		h6 {
			text-transform: uppercase;
			margin-bottom: 10px;
			margin-top: 10px;
			color: black;
			letter-spacing: 0.03em;
		}
	}
}
</style>
