<template>
	<section id="categories">
		<Header />
		<div class="categories-content">
			<Categorie type="groupe_mauffrey" titre="Groupe Mauffrey" />
			<Categorie type="geographie" titre="Géographie" />
			<Categorie type="qualite" titre="Qualité, Sécurité Environnement" />
			<Categorie type="formation" titre="Formation" />
			<Categorie type="digitalisation" titre="Digitalisation" />
			<Categorie type="prestations" titre="Métiers" />
			<Categorie type="filieres" titre="Filières" />
			<Categorie type="materiel" titre="Matériel" />
			<Categorie type="toolbox" titre="Toolbox" />
			<Categorie type="procedures" titre="Procédures" />
		</div>
	</section>
</template>
<script>
import Header from "../components/Header";
import Categorie from "../components/categories/Categorie.vue";
export default {
	name: "Categories",
	components: { Header, Categorie },
	data() {
		return {};
	},
	methods: {},
};
</script>

<style lang="scss">
#categories {
	background: white;
	.categories-content {
		display: block;
		.col {
			flex: 1;
			background: $gris;
			background-image: url("../assets/img/fond-alveole.png");
			background-position: top left;
			background-repeat: repeat;
			padding: 20px;
			margin: 30px;
			border-radius: 20px;
			box-shadow: 1px 1px 6px rgba(black, 0.1);
			max-width: 800px;
			h3 {
				font-size: 20px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 10px 0;
				cursor: pointer;
				transition: all 0.3s ease;
				&:after {
					content: "\f013";
					font-family: "Font Awesome 5 Pro";
					font-weight: 300;
					font-size: 26px;
				}
				&:hover {
					color: $rouge;
				}
			}
			ul {
				padding: 20px 30px;
				background: white;
				border-radius: 10px;
				box-shadow: 2px 2px 8px rgba(black, 0.1);
				display: none;
				form {
					display: flex;
					align-items: stretch;
					justify-content: space-between;
					background: $gris;
					border-radius: 20px;
					padding: 8px;
					margin-bottom: 20px;
					input[type="text"] {
						background: white;
						flex: 1;
						border-radius: 20px 0 0 20px;
						border: none;
						min-height: 36px;
						padding: 0 16px;
						box-shadow: 1px 1px 3px rgba(black, 0.05);
					}
					button[type="submit"] {
						border-radius: 0 20px 20px 0;
						font-size: 14px;
						font-weight: 500;
						padding: 0 30px;
						&:hover {
							transform: none;
							padding: 0 40px;
						}
					}
				}
				h6 {
					margin-bottom: 10px;
				}
				li {
					padding: 2px 0;
					display: flex;
					font-size: 12px;
					font-weight: 500;
					align-items: center;
					justify-content: space-between;
					border-bottom: dotted 1px rgba(black, 0.1);
					&:last-child {
						border: none;
					}
					input[type="text"] {
						flex: 1;
						padding: 10px 0;
						border-radius: 20px;
						transition: all 0.3s ease;
						border: none;
						&:focus {
							outline: none;
							background: $rougeclair;
							padding: 10px 16px;
						}
					}
					.tools {
						span {
							font-size: 11px;
							border-radius: 4px;
							background: white;
							color: black;
							padding: 10px;
							transition: all 0.2s ease;
							cursor: pointer;
							i,
							svg {
								font-size: 12px;
								margin: 0 3px 0 0;
							}
							&:hover {
								background: $rougeclair;
								color: $rouge;
								transform: translateY(2px);
							}
						}
					}
				}
			}
			&.active {
				h3 {
					&:after {
						content: "\f106";
					}
				}
				ul {
					display: block;
					animation: appearFromTop 0.3s cubic-bezier(0.075, 0.82, 0.165, 1) backwards;
					animation-delay: 0.15s;
				}
			}
		}
	}
}
</style>
