<template>
	<div id="toolbox-element">
		<div @click="clone() && hideComposantSubMenu()" title="Dupliquer le composant"><i class="far fa-clone"></i></div>
		<div @click="copy() && hideComposantSubMenu()" title="Copier la mise en forme"><i class="far fa-brush"></i></div>
		<div @click="copyStyle() && hideComposantSubMenu()" title="Coller la mise en forme"><i class="far fa-paint-brush"></i></div>
		<div @click="goUpForce()" title="Mettre au premier plan"><i class="far fa-sort-amount-up"></i></div>
		<div @click="goDownForce()" title="Envoyer en arrière plan"><i class="far fa-sort-amount-down"></i></div>
		<div @click="remove() && hideComposantSubMenu()" title="Supprimer le composant"><i class="far fa-trash"></i></div>
	</div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
	name: "Toolbox",
	props: {
		slide: Number,
		composant: Number,
	},
	components: {},
	computed: {
		...mapGetters({
			composantContext: "composantContext",
			slideContext: "slideContext",
			pressePapier: "pressePapier",
			currentUser: "currentUser",
		}),
		currentComposant() {
			if (this.slide === this.$store.getters.currentDiapositive && this.composant === this.$store.getters.currentComposant.index) {
				return true;
			} else {
				return false;
			}
		},
	},
	data() {
		return {};
	},
	methods: {
		...mapMutations({
			showChooseSlide: "showChooseSlide",
			removeElement: "removeElement",
			removeElementSlide: "removeElementSlide",
			goUp: "upComposant",
			goUpForce: "upComposantForce",
			goDown: "downComposant",
			goDownForce: "downComposantForce",
			paste: "pasteComposant",
			copyStyle: "copyStyle",
		}),
		...mapActions({
			hideComposantSubMenu: "hideComposantSubMenu",
			hideSlideSubMenu: "hideSlideSubMenu",
			clone: "cloneComposant",
			copy: "copyComposant",
			duplicateSlide: "duplicateSlide",
			addSlide: "addSlide",
			createSlideModele: "exportSlideToModele",
		}),
		remove() {
			this.removeElement();
			this.hideComposantSubMenu();
		},
	},
};
</script>

<style lang="scss">
#toolbox-element {
	position: absolute;
	top: -5px;
	left: 50%;
	display: flex;
	font-size: 14px;
	border-radius: 3px;
	transform: translate(-50%, -100%);
	background: white;
	box-shadow: 0 0 10px rgba(black, 0.1);
	div {
		padding: 8px 10px;
		border-right: solid 1px rgba(black, 0.1);
		transition: all 0.2s ease;
		cursor: pointer;
		color: $rouge;
		&:hover {
			background: rgba(black, 0.05);
			color: $rouge;
		}
	}
}
</style>
