<template>
	<section id="archives">
		<Header />
		<Introduction />
	</section>
</template>

<script>
import Introduction from "../components/archives/introduction_archives";
import Header from "../components/Header";
export default {
	name: "Dashboard",
	components: { Introduction, Header },
};
</script>

<style lang="scss" scoped>
#archives {
	background: white;
	width: 100%;
	height: 100vh;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
}
</style>
