<template>
	<section id="messagerie">
		<Header />
		<form @submit.prevent="sendMessage" id="mailsender">
			<h1>Envoyer un message aux utilisateurs de la RUCHE</h1>
			<div class="form-group">
				<label for="destinaires">Destinataires</label>
				<div>
					<van-checkbox-group id="destinaires" v-model="destinaires" ref="checkboxGroup">
						<van-checkbox name="super_administrateur">Super-administrateurs</van-checkbox>
						<van-checkbox name="administrateur">Administrateurs</van-checkbox>
						<van-checkbox name="editeur">Editeurs</van-checkbox>
						<van-checkbox name="collaborateur">Collaborateurs</van-checkbox>
					</van-checkbox-group>
					<div @click="toggleAll" class="toggleall">Tout sélectionner</div>
				</div>
			</div>
			<div class="form-group">
				<label for="objet">Objet</label>
				<input id="objet" maxlength="80" v-model="objet" placeholder="Objet de mon message" />
			</div>
			<div class="form-group">
				<label for="message">Votre message</label>
				<vue-editor :editorToolbar="customToolbar" theme="snow" v-model="message"></vue-editor>
			</div>
			<button type="submit" v-if="!envoi_en_cours">Envoyer</button>
			<div class="envoi" v-else>Envoi en cours...</div>
			<div class="reponse" v-show="reponse.length > 1">{{ reponse }}</div>
		</form>
	</section>
</template>
<script>
import Header from "../components/Header";
import { mapActions } from "vuex";
import { VueEditor } from "vue2-editor";

export default {
	name: "Messagerie",
	components: { Header, VueEditor },
	data() {
		return {
			destinaires: [],
			message: "",
			objet: "",
			envoi_en_cours: false,
			reponse: "",
			customToolbar: [
				["bold", "italic", "underline", "link"],
				[{ align: "" }, { align: "center" }, { align: "right" }, { script: "sub" }, { script: "super" }],
				[{ list: "ordered" }, { list: "bullet" }],
				["clean"],
			],
		};
	},
	methods: {
		toggleAll() {
			this.$refs.checkboxGroup.toggleAll();
		},
		updateMessage(e) {
			this.message = e.target.innerHTML;
		},
		...mapActions({
			sendNewMessage: "sendNewMessage",
		}),
		sendMessage(e) {
			if (this.message.length > 10 && this.destinaires.length >= 1 && this.objet.length >= 3) {
				this.envoi_en_cours = true;
				this.sendNewMessage({ destinataires: this.destinaires, message: this.message, objet: this.objet })
					.then(() => {
						this.reponse = "Votre message a bien été envoyé aux destinataires sélectionnés.";
						this.message = "";
						this.objet = "";
						this.destinaires = [];
					})
					.catch((err) => {
						this.reponse = "Une erreur est survenue pendant l'envoi de votre message.";
					})
					.finally((el) => {
						this.envoi_en_cours = false;
						setTimeout(() => {
							this.reponse = "";
						}, 6000);
					});
			} else {
				alert("Vous devez remplir tous les champs avant d'envoyer votre message");
			}
		},
	},
};
</script>

<style lang="scss">
#messagerie {
	min-height: 100vh;
	#mailsender {
		padding: 60px;
		background: white;
		h1 {
			font-size: 20px;
			font-weight: 600;
			margin-bottom: 50px;
		}
		button[type="submit"] {
			margin-left: 160px;
		}
		.envoi {
			margin-left: 160px;
			padding: 30px 0;
			font-weight: 500;
		}
		.reponse {
			margin-left: 160px;
			padding: 30px 0;
			font-weight: 500;
		}
		.form-group {
			display: flex;
			gap: 40px;
			padding: 10px 0px;
			label {
				font-weight: 500;
				flex: 0 0 120px;
			}
			.van-checkbox-group {
				.van-checkbox {
					margin-bottom: 5px;
				}
			}

			.toggleall {
				font-size: 13px;
				margin-top: 15px;
				font-weight: 300;
				margin-bottom: 20px;
				color: rgba(black, 0.8);
				cursor: pointer;
				&:hover {
					text-decoration: underline;
				}
			}

			#objet {
				background: rgba(black, 0.05);
				border-radius: 10px;
				border: none;
				flex: 1;
				height: 50px;
				padding: 0 10px;
			}

			.quillWrapper {
				background: rgba(black, 0.05);
				padding: 0px;
				border-radius: 10px;
				border: none;
				flex: 1;
				overflow: hidden;
				min-height: 200px;
				margin-bottom: 30px;
				.ql-toolbar {
					border: none;
				}
				#quill-container {
					height: auto;
					border: none;
					strong,
					b {
						font-weight: bold;
					}
				}
			}
		}
	}
}
</style>
