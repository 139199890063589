<template>
	<section id="editor">
		<NavBar v-if="presentationReady" />
		<Rendu v-if="presentationReady" />
		<Overlay v-if="presentationReady" />
		<ContextMenu v-if="presentationReady" />
		<ChooseSlide v-if="presentationReady" />
		<ChooseImage v-if="presentationReady" />
		<div class="loading" v-else>
			<div><i class="fal fa-spin fa-spinner"></i></div>
			<strong>Récupération de votre présentation</strong>
			<p>Merci de patientez quelques instants...</p>
		</div>
	</section>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
//import Header from "../components/Header";
import NavBar from "../components/presentation/navbar";
import Rendu from "../components/presentation/rendu";
import Overlay from "../components/presentation/overlay";
import ContextMenu from "../components/presentation/contextmenu";
import ChooseSlide from "../components/chooseslide";
import ChooseImage from "../components/chooseimage";
export default {
	name: "",
	components: { ChooseImage, ChooseSlide, NavBar, Rendu, Overlay, ContextMenu },
	data() {
		return {
			autoSave: null,
			normal_redirect: true,
		};
	},
	methods: {
		getFullPresentation() {
			this.$store
				.dispatch("getFullPresentation", this.$route.params.id)
				.then((res) => {
					if (res.fields.en_cours_de_modif && res.fields.modifie_par != this.currentUser.id) {
						this.normal_redirect = false;
						alert(`Ce draft est en cours de modification par ${res.fields.nom_modification[0]}. Vous allez être redirigé vers le dashboard.`);
						this.$router.push({ name: "Dashboard" });
					} else {
						this.blockMultipleEdition();
					}
				})
				.catch((err) => {
					this.$router.push({ name: "Dashboard" });
				});
		},
		...mapMutations({
			refreshPresentation: "refreshPresentation",
		}),
		...mapActions({
			savePresentation: "savePresentation",
			getModeles: "getModeles",
			getSquelettes: "getSquelettes",
			blockMultipleEdition: "blockMultipleEdition",
			cleanMultipleEdition: "cleanMultipleEdition",
		}),
	},
	computed: {
		...mapGetters({
			appLoading: "appLoading",
			presentation: "currentPresentation",
			presentationReady: "presentationReady",
			currentUser: "currentUser",
		}),
	},
	mounted() {
		this.getFullPresentation();
	},
	beforeRouteLeave(to, from, next) {
		if (this.normal_redirect) {
			if (window.confirm("Voulez-vous quitter cette présentation ? Toute donnée non sauvegardée sera effacée. ")) {
				this.cleanMultipleEdition(this.$route.params.id);
				this.refreshPresentation();
				setTimeout(() => {
					next();
				}, 100);
			}
		} else {
			next();
		}
	},
	unmounted() {},
};
</script>

<style lang="scss">
#editor {
	width: 100%;
	min-height: 100vh;
	.loading {
		padding: 100px;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		flex-direction: column;
		background: white;
		padding: 20px;
		font-size: 12px;
		strong {
			font-weight: 600;
			font-size: 14px;
			display: block;
			margin-bottom: 5px;
		}
		img {
			max-width: 200px;
			height: auto;
		}
		i {
			width: 50px;
			height: 50px;
			font-size: 20px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}
</style>
