<template>
	<div :class="classes" :type="type" :data-slide="slide" :data-composant="composant"
		:ref="`slide${slide}composant${composant}`"
		:style="`transform: rotateZ(${rotate}deg); position: absolute; color: ${color}; text-align: ${align}; vertical-align: ${valign}; left: ${x}px; top: ${y}px; width: ${w}px; height: ${h}px; font-size: ${fontSize}pt; padding: ${margin}px; line-height: ${lineSpacing}; background-color: ${fill}`">
		<ul class="liste-mauffrey" v-if="puces.length > 0">
			<li v-for="(puce, index) in puces" :key="index">
				{{ puce.text }}
			</li>
		</ul>
		<div class="corner edge-top edge-left"></div>
		<div class="corner edge-top edge-right"></div>
		<div class="corner edge-bottom edge-right"></div>
		<div class="corner edge-bottom edge-left"></div>
	</div>
</template>
<script>
import interact from "interactjs";

export default {
	name: "PPTXTEXT",
	components: {},
	props: {
		slide: Number,
		composant: Number,
		type: String,
		x: Number,
		y: Number,
		w: Number,
		h: Number,
		fontSize: Number,
		lineSpacing: Number,
		align: String,
		valign: String,
		content: String,
		bold: Boolean,
		color: String,
		rotate: Number,
		italic: Boolean,
		margin: Number,
		puces: Array,
		fill: String,
	},
	data() {
		return {};
	},
	methods: {
		updateSize(el, sizes, slide, composant) {
			el.style.transform = "none";
			el.dataset.x = 0;
			el.dataset.y = 0;
			this.$store.dispatch("updateElementSize", { sizes, slide, composant });
		},
		updatePosition(el, slide, composant, startx, starty) {
			el.style.transform = "none";
			let sizes = {
				x: parseFloat(el.style.left),
				y: parseFloat(el.style.top),
				differencex: parseFloat(el.style.left) - startx,
				differencey: parseFloat(el.style.top) - starty,
			};
			this.$store.dispatch("updateElementPosition", { sizes, slide, composant });
		},
	},
	computed: {
		classes() {
			let classes = `pptx-liste resizable draggable ${this.valign} `;
			if (this.bold) {
				classes += "bold ";
			}
			if (this.italic) {
				classes += "italic ";
			}
			return classes;
		},
	},
	mounted() {
		let concatenate = `slide${this.slide}composant${this.composant}`;
		let el = this.$refs[concatenate];
		let position = {
			x: 0,
			y: 0,
			startx: 0,
			starty: 0,
		};
		interact(el)
			.resizable({
				margin: 5,
				edges: {
					top: ".edge-top",
					left: ".edge-left",
					bottom: ".edge-bottom",
					right: ".edge-right",
				},

				onmove: (event) => {
					let { x, y } = event.currentTarget.dataset;

					x = (parseFloat(x) || 0) + event.deltaRect.left;
					y = (parseFloat(y) || 0) + event.deltaRect.top;

					Object.assign(event.target.style, {
						width: `${event.rect.width}px`,
						height: `${event.rect.height}px`,
						transform: `translate(${x}px, ${y}px)`,
					});

					Object.assign(event.target.dataset, { x, y });
				},
				onend: (event) => {
					let { x, y } = event.currentTarget.dataset;
					let { width, height } = event.currentTarget.getBoundingClientRect();
					let { left, top } = event.currentTarget.style;
					let pLeft = parseFloat(x) + parseFloat(left);
					let pTop = parseFloat(y) + parseFloat(top);
					let sizes = {
						width,
						height,
						pLeft,
						pTop,
					};
					this.updateSize(event.currentTarget, sizes, this.slide, this.composant);
				},
			})
			.draggable({
				onstart: (event) => {
					position.x = parseFloat(event.currentTarget.style.left);
					position.y = parseFloat(event.currentTarget.style.top);
					position.startx = parseFloat(event.currentTarget.style.left);
					position.starty = parseFloat(event.currentTarget.style.top);
				},
				onmove: (event) => {
					position.x += event.dx;
					position.y += event.dy;
					event.currentTarget.style.left = `${position.x}px`;
					event.currentTarget.style.top = `${position.y}px`;
				},
				onend: (event) => {
					this.updatePosition(event.currentTarget, this.slide, this.composant, position.startx, position.starty);
				},
			});
	},
};
</script>

<style lang="scss">
.pptx-liste {
	line-height: 1.2;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	&.bold {
		font-weight: bold;
	}
	&.italic {
		font-style: italic;
	}
	.liste-mauffrey {
		margin: 0;
		padding: 0;
		font-weight: inherit;
		font-style: inherit;
		li {
			position: relative;
			font-weight: inherit;
			font-style: inherit;
			&:before {
				content: "> ";
				color: $rouge;
				font-weight: bold;
				display: inline-block;
			}
		}
	}
}
.pptx-element.selected {
	.pptx-liste {
		background: rgba(white, 0.1);
		backdrop-filter: blur(2px);
	}
}
</style>
