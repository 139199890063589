<template>
	<span
		:class="currentComposant ? 'pptx-element selected' : 'pptx-element'"
		:data-composant="composant"
		:data-slide="slide"
		:data-type="data.type"
		@click="setCurrentComposant($event, composant, slide, data.type)"
		@contextmenu="showComponentSubMenu($event, composant, slide, data.type)"
	>
		<pptxText
			v-if="data.type === 'text'"
			:type="data.type"
			:x="data.x"
			:y="data.y"
			:w="data.w"
			:h="data.h"
			:align="data.align"
			:valign="data.valign"
			:color="data.color"
			:content="data.content"
			:fontSize="data.fontSize"
			:bold="data.bold"
			:rotate="data.rotate"
			:italic="data.italic"
			:margin="data.margin"
			:slide="slide"
			:lineSpacing="data.lineSpacing"
			:composant="composant"
			:useForSommaire="data.useForSommaire"
			:isUpperCase="data.isUpperCase"
			:lien="data.hyperlink"
			:fill="data.fill"
			:current="currentComposant"
		/>
		<pptxShape
			v-if="data.type === 'shape'"
			:x="data.x"
			:y="data.y"
			:w="data.w"
			:h="data.h"
			:line="data.line"
			:fill="data.fill.color"
			:transparency="data.fill.transparency"
			:slide="slide"
			:composant="composant"
			:rotate="data.rotate"
			:rectRadius="data.rectRadius"
			:click_a_travers="data.click_a_travers"
			:shadow="data.shadow"
			:current="currentComposant"
		/>

		<pptxGraph
			v-if="data.type === 'graph'"
			:x="data.x"
			:y="data.y"
			:w="data.w"
			:h="data.h"
			:type_chart="data.type_chart"
			:slide="slide"
			:composant="composant"
			:data="data.data"
		/>

		<pptxImage
			v-if="data.type === 'image'"
			:x="data.x"
			:y="data.y"
			:w="data.w"
			:h="data.h"
			:data="data.data"
			:path="data.path"
			:slide="slide"
			:composant="composant"
			:rounding="data.rounding"
			:sizing="data.sizing"
			:is_dynamic="data.is_dynamic"
			:dynamic_code="data.dynamic_code"
			:lien="data.hyperlink"
			:current="currentComposant"
			:transparency="data.transparency"
		/>

		<pptxVideo v-if="data.type === 'online'" :x="data.x" :y="data.y" :w="data.w" :h="data.h" :link="data.link" :composant="composant" :slide="slide" />

		<pptxListe
			v-if="data.type === 'liste'"
			:type="data.type"
			:x="data.x"
			:y="data.y"
			:w="data.w"
			:h="data.h"
			:align="data.align"
			:valign="data.valign"
			:color="data.color"
			:content="data.content"
			:fontSize="data.fontSize"
			:bold="data.bold"
			:rotate="data.rotate"
			:italic="data.italic"
			:margin="data.margin"
			:slide="slide"
			:lineSpacing="data.lineSpacing"
			:composant="composant"
			:puces="data.puces"
			:fill="data.fill"
		/>

		<pptxTableau
			v-if="data.type === 'tableau'"
			:type="data.type"
			:x="data.x"
			:y="data.y"
			:w="data.w"
			:h="data.h"
			:align="data.align"
			:fill="data.fill.color"
			:valign="data.valign"
			:color="data.color"
			:content="data.content"
			:fontSize="data.fontSize"
			:margin="data.margin"
			:slide="slide"
			:borderColor="data.borderColor"
			:lineSpacing="data.lineSpacing"
			:composant="composant"
			:rows="data.rows"
			:cols="data.cols"
			:last_colonne_heading="data.last_colonne_heading"
			:last_row_heading="data.last_row_heading"
			:first_row_heading="data.first_row_heading"
			:first_colonne_heading="data.first_colonne_heading"
		/>
	</span>
</template>
<script>
import pptxText from "../pptx-library/pptx-text";
import pptxShape from "../pptx-library/pptx-shape";
import pptxImage from "../pptx-library/pptx-image";
import pptxListe from "../pptx-library/pptx-liste";
import pptxTableau from "../pptx-library/pptx-tableau";
import pptxGraph from "../pptx-library/pptx-graph";
import pptxVideo from "../pptx-library/pptx-video.vue";

import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
	name: "slideComponent",
	components: { pptxText, pptxShape, pptxImage, pptxListe, pptxTableau, pptxGraph, pptxVideo },
	props: {
		data: Object,
		slide: Number,
		composant: Number,
	},
	data() {
		return {};
	},
	computed: {
		currentComposant() {
			if (this.slide === this.$store.getters.currentDiapositive && this.composant === this.$store.getters.currentComposant.index) {
				return true;
			} else {
				return false;
			}
		},
	},
	methods: {
		...mapActions({
			deselectItem: "deselectItem",
		}),
		...mapMutations({
			clearSelectedItem: "clearSelectedItem",
		}),
		async setCurrentComposant(e, composant, slide, type) {
			if ((e.ctrlKey || e.shiftKey) && e.currentTarget.classList.contains("pptx-element")) {
				// Je récupére l'actuel ou les actuels elements sélectionnés
				let composant_deja_selectionnees = document.querySelectorAll(".pptx-element.selected");
				// Je supprime le composant courant du store
				this.deselectItem().then(() => {
					// Je reselectionne l'ancien composant
					composant_deja_selectionnees.forEach((el) => {
						el.classList.add("selected");
					});
					// J'ajoute les classes sur le nouveau composant
					e.currentTarget.classList.add("selected");
				});
			} else {
				this.$store.commit("selectComposant", {
					composant,
					slide,
					type,
				});
			}
		},
		showComponentSubMenu(e, composant, slide, type) {
			this.setCurrentComposant(e, composant, slide, type).then(() => {
				this.$store.dispatch("showComponentSubMenu", e);
			});
		},
	},
};
</script>

<style lang="scss">
.pptx-element {
	.corner {
		position: absolute;
		width: 5px;
		height: 5px;
		pointer-events: none;
	}

	/* [3] Define CSS for all edges to be used by our corner later */
	.edge-top {
		top: -3px
	}

	.edge-bottom {
		bottom: -3px;
	}

	.edge-left {
		left: -3px;
	}

	.edge-right {
		right: -3px;
	}

	&.selected {
		& > div {
			outline-style: solid;
			outline-width: 1px;
			outline-color: rgba($rouge, 0.8);
			outline-offset: 0px;
			position: relative;
			overflow: visible;
			z-index: 999;
		}
		.corner {
			position: absolute;
			width: 6px;
			height: 6px;
			background-color: $rougeclair;
			border: solid 1px $rouge;
			pointer-events: auto;
		}
	}
}
</style>
