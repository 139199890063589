<template>
	<section id="editor">
		<NavBar v-if="presentationReady" />
		<Rendu v-if="presentationReady" />
		<Overlay v-if="presentationReady" />
		<ContextMenu v-if="presentationReady" />
		<ChooseSlide v-if="presentationReady" />
		<ChooseImage v-if="presentationReady" />
		<div class="loading" v-else>
			<div><i class="fal fa-spin fa-spinner"></i></div>
			<strong>Récupération de votre modèle</strong>
			<p>Merci de patientez quelques instants...</p>
		</div>
	</section>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import NavBar from "../components/modele/navbar";
import Rendu from "../components/modele/rendu";
import Overlay from "../components/modele/overlay";
import ContextMenu from "../components/modele/contextmenu";
import ChooseSlide from "../components/chooseslide";
import ChooseImage from "../components/chooseimage";
export default {
	name: "",
	components: { ChooseImage, ChooseSlide, NavBar, Rendu, Overlay, ContextMenu },
	data() {
		return {
			autoSave: null,
			cool: false,
		};
	},
	methods: {
		getFullPresentation() {
			this.$store
				.dispatch("getFullModele", this.$route.params.id)
				.then(() => {
					//this.blockMultipleEdition();
				})
				.catch((err) => {
					this.$router.push({ name: "Modeles" });
				});
		},
		...mapMutations({
			refreshPresentation: "refreshPresentation",
		}),
		...mapActions({
			//savePresentation: "savePresentation",
			blockMultipleEdition: "blockMultipleEdition",
			getModeles: "getModeles",
			cleanMultipleEdition: "cleanMultipleEdition",
		}),
	},
	computed: {
		...mapGetters({
			modeles: "modeles",
			appLoading: "appLoading",
			presentation: "currentPresentation",
			presentationReady: "presentationReady",
		}),
	},
	mounted() {
		this.getFullPresentation();
	},
	unmounted() {
		this.refreshPresentation();
	},
};
</script>

<style lang="scss">
#editor {
	width: 100%;
	min-height: 100vh;
	.loading {
		padding: 100px;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		flex-direction: column;
		background: white;
		padding: 20px;
		font-size: 12px;
		strong {
			font-weight: 600;
			font-size: 14px;
			display: block;
			margin-bottom: 5px;
		}
		img {
			max-width: 200px;
			height: auto;
		}
		i {
			width: 50px;
			height: 50px;
			font-size: 20px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}
</style>
