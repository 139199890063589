<template>
	<section id="rendu" ref="rendu">
		<div class="slides" :style="`zoom:${$store.state.properties.zoom}`">
			<Slide
				v-for="(slide, index) in presentation.slides"
				:key="index"
				:fill="slide.fill"
				:color="slide.color"
				:order="slide.order"
				:index="index"
				:name="slide.name"
				:slide="index"
				:barre_color="slide.barre_color"
				:path="slide.path"
			>
				<slideComponent v-for="(el, i) in slide.composants" :key="i" :data="el" :slide="index" :composant="i" />
			</Slide>
		</div>
		<Editor />
	</section>
</template>
<script>
import Slide from "../pptx-library/slide";
import slideComponent from "../pptx-library/slideComponent";
import { mapGetters, mapActions, mapMutations } from "vuex";
import Editor from "./editor";
import interact from "interactjs";
import { Dialog } from "vant";

export default {
	name: "Rendu",
	components: { Editor, Slide, slideComponent },
	data() {
		return {};
	},
	methods: {
		showChooseSlideBis() {
			let slide_selected = document.querySelector(".slide.selected");
			if (slide_selected) {
				slide_selected.classList.remove("selected");
			}
			setTimeout(() => {
				this.showChooseSlide();
			}, 100);
		},

		...mapMutations({
			showChooseSlide: "showChooseSlide",
			moveUpByOnePixel: "moveUpByOnePixel",
			moveDownByOnePixel: "moveDownByOnePixel",
			moveLeftByOnePixel: "moveLeftByOnePixel",
			moveRightByOnePixel: "moveRightByOnePixel",
			refreshAllDynamicImages: "refreshAllDynamicImages",
			restoreHistorique: "restoreHistorique",
			setPositionForNewSlide: "setPositionForNewSlide",
			removeElement: "removeElement",
			replaceSlide: "replaceSlide",
			resetCurrentComposant: "resetCurrentComposant",
		}),
		...mapActions({
			savePresentation: "savePresentation",
			hideComposantSubMenu: "hideComposantSubMenu",
			saveModeleFromEditor: "saveModeleFromEditor",
		}),
		dragMoveListener(event) {
			var target = event.target;

			// keep the dragged position in the data-x/data-y attributes
			var x = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx;
			var y = (parseFloat(target.getAttribute("data-y")) || 0) + event.dy;
			// translate the element
			target.style.transform = "translate(" + x + "px, " + y + "px)";
			// update the posiion attributes
			target.setAttribute("data-x", x);
			target.setAttribute("data-y", y);
		},
		dragEndListener(event) {
			var target = event.target;
			let rendu = document.getElementById("rendu");
			target.style.transition = "all .1s ease";
			target.style.transform = "none";
			target.setAttribute("data-x", null);
			target.setAttribute("data-y", null);
			rendu.classList.remove("orderable");
			setTimeout(() => {
				target.style.transition = "none";
			}, 400);
		},
		dragStartListener(event) {
			let rendu = document.getElementById("rendu");
			rendu.classList.add("orderable");
		},
		// makeDraggable() {
		// 	let concatenate = `slide${this.slide}`;
		// 	let el = this.$refs[concatenate];
		// 	interact(".dropzone").dropzone({
		// 		accept: ".slide",
		// 		ondragenter: (event) => {
		// 			event.target.classList.add("hovered");
		// 		},
		// 		ondragleave: (event) => {
		// 			event.target.classList.remove("hovered");
		// 		},
		// 		ondrop: (event) => {
		// 			let target = event.target;
		// 			let related_target = event.relatedTarget;
		// 			this.$store.commit("changeSlideOrder", {
		// 				slide: related_target.dataset.slide,
		// 				position: target.dataset.position,
		// 			});
		// 			event.target.classList.remove("hovered");
		// 		},
		// 	});
		// 	interact(".slide").draggable({
		// 		inertia: false,
		// 		autoScroll: true,
		// 		modifiers: [
		// 			interact.modifiers.restrictRect({
		// 				endOnly: true,
		// 			}),
		// 		],
		// 		listeners: {
		// 			start: this.dragStartListener,
		// 			move: this.dragMoveListener,
		// 			end: this.dragEndListener,
		// 		},
		// 	});
		// },
		addNewSlide() {
			this.$store.dispatch("addSlide", "end");
		},

		resizeSlides() {
			let slides = document.querySelectorAll(".slides .slide");
			slides.forEach((el) => {
				el.style.transform = `scale(${this.properties.zoom / 100})`;
			});
		},
		keyboardEvent(event) {
			let rendu = document.getElementById("rendu");
			let selected_items = [...document.querySelectorAll(".pptx-element.selected")];
			if ((event.metaKey && event.key === "z") || (event.ctrlKey && event.key === "z")) {
				if (this.currentComposant.type != "text") {
					event.preventDefault();
					this.restoreHistorique();
				}
			} else if ((event.metaKey && event.key === "s") || (event.ctrlKey && event.key === "s")) {
				event.preventDefault();
				this.resetCurrentComposant();
				setTimeout(() => {
					this.saveModeleFromEditor();
				}, 300);
			} else if ((event.key === "k" && event.metaKey && rendu) || (event.ctrlKey && event.key === "k" && rendu)) {
				event.preventDefault();
				rendu.classList.toggle("previewmode");
			} else if (event.code === "Delete") {
				if (this.currentComposant.type != "text") {
					if (selected_items) {
						// Je trie les éléments par slide et par index
						selected_items.sort((a, b) => (parseInt(a.dataset.composant) < parseInt(b.dataset.composant) ? 1 : -1));
						selected_items.forEach((el) => {
							this.presentation.slides[el.dataset.slide].composants.splice(el.dataset.composant, 1);
						});
					} else if (this.currentComposant.index != null) {
						this.remove();
					}
				}
			} else if (event.code === "ArrowUp") {
				if (!this.quillfocus) {
					event.preventDefault();
					this.moveUpByOnePixel();
				}
			} else if (event.code === "ArrowDown") {
				if (!this.quillfocus) {
					event.preventDefault();
					this.moveDownByOnePixel();
				}
			} else if (event.code === "ArrowLeft") {
				if (!this.quillfocus) {
					event.preventDefault();
					this.moveLeftByOnePixel();
				}
			} else if (event.code === "ArrowRight") {
				if (!this.quillfocus) {
					event.preventDefault();
					this.moveRightByOnePixel();
				}
			}
		},
		remove() {
			this.removeElement();
			this.hideComposantSubMenu();
		},
		mousewheelEvent(event) {
			if (event.ctrlKey) {
				this.$store.commit("setZoom", event.deltaY / 1000);
			}
		},
	},
	mounted() {
		//this.makeDraggable();
		this.refreshAllDynamicImages();
		let wheelListener = document.querySelector("#rendu .slides").addEventListener("wheel", this.mousewheelEvent, { passive: true });
		document.addEventListener("keydown", this.keyboardEvent);
	},
	computed: {
		quillfocus() {
			return this.$store.state.quillfocus;
		},
		...mapGetters({
			currentComposant: "currentComposant",
			presentation: "currentPresentation",
			currentDiapositive: "currentDiapositive",
		}),
	},
	beforeDestroy() {
		if (document.querySelector("#rendu .slides")) {
			document.querySelector("#rendu .slides").removeEventListener("wheel", this.mousewheelEvent);
		}

		document.removeEventListener("keydown", this.keyboardEvent);
	},
};
</script>

<style lang="scss" scoped>
#rendu {
	width: 100%;
	height: calc(100vh - 60px);
	position: relative;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	padding: 0;
	background: white;
	background-image: url("../../assets/img/fond-alveole.png");
	.tools {
		top: 20px;
		background: white;
		display: flex;
		position: sticky;
		font-size: 11px;
		font-weight: bold;
		align-items: center;
		border-radius: 20px;
		z-index: 99;
		box-shadow: 1px 1px 5px rgba(black, 0.1);
		.pointer {
			padding: 10px 0 10px 20px;
			cursor: pointer;
			&:hover {
				color: $rouge;
			}
			&:last-child {
				padding: 10px 20px 10px 0px;
			}
		}
		.zoom {
			font-weight: 500;
			padding: 10px 15px;
		}
	}
	.slides {
		position: relative;
		transition: all 0.3s ease;
		padding: 80px 20px 40px 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
		flex: 1;
		background-position: top left;
		background-repeat: repeat;
		zoom: 1;
		z-index: 1;
		padding-right: 300px;
		.slide-wrapper {
			.slide {
				&.previewmode {
					.pptx-image {
						background: #eee;
					}
					.slide-mask {
						display: none;
					}
				}
				&.previewmodebis {
					.slide-mask {
						display: none;
					}
				}
			}
			&:last-child {
				.box_ajout_cp {
					&:after {
						content: none;
					}
				}
			}
		}
	}
	&.previewmode {
		.slide-mask {
			display: none !important;
			opacity: 0;
		}
	}

	&.orderable {
		.slides {
			.slide-wrapper {
				.dropzone {
					display: flex;
				}
			}
		}
	}
}
</style>
