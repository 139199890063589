<template>
	<div id="connexion-wrapper">
		<section id="connexion">
			<div class="logo">
				<img src="../assets/logo.png" title="La RUCHE" />
			</div>
			<button v-if="!trying_to_connect && !success" @click="connectUser()"><i class="fab fa-windows"></i> Connexion</button>
			<div class="confirmation" v-if="!trying_to_connect && success">Bienvenue sur la Ruche !<span>Redirection en cours, veuillez patienter...</span></div>
			<div class="animation-wait" v-if="trying_to_connect && !success">
				<i class="fal fa-spin fa-spinner"></i>
			</div>
			<div class="loginerror">{{ loginFailMessage }}</div>
		</section>
		<div id="infos">
			© Groupe Mauffrey - Tous droits réservés
			<br />version {{ version }}
		</div>
	</div>
</template>
<script>
import app from "../../package.json";
import { mapMutations, mapActions, mapGetters } from "vuex";
export default {
	name: "Connexion",
	components: {},
	data() {
		return {
			version: "",
		};
	},
	mounted() {
		this.version = app.version;
	},
	computed: {
		...mapGetters({
			loginFailMessage: "loginFailMessage",
			trying_to_connect: "trying_to_connect",
			success: "success",
		}),
	},
	methods: {
		...mapActions({
			getNewGlobales: "getNewGlobales",
			getClassement: "getNewClassement",
			getNewModeles: "getNewModeles",
			getPrestations: "getPrestations",
			getFilieres: "getFilieres",
			getProcedures: "getProcedures",
			getToolbox: "getToolbox",
			getMateriel: "getMateriel",
			getDigitalisation: "getDigitalisation",
			getFormation: "getFormation",
			getQualite: "getQualite",
			getGeographie: "getGeographie",
			getGroupe: "getGroupe",
			getGlobales: "getGlobales",
			getModeles: "getModeles",
			getSquelettes: "getSquelettes",
		}),
		...mapMutations({
			showAppLoading: "showAppLoading",
			hideAppLoading: "hideAppLoading",
		}),
		connectUser() {
			this.$store.dispatch("connectUser").then(() => {
				this.showAppLoading("Chargement des catégories...");
				Promise.all([this.getClassement(), this.getNewGlobales(), this.getNewModeles()]).then((values) => {
					this.hideAppLoading();
					this.$router.push({ name: "Dashboard" });
				});
			});
		},
	},
};
</script>

<style lang="scss">
#connexion-wrapper {
	margin-left: -80px;
	position: relative;
	z-index: 9999;
	background-image: url("../assets/img/fondruche3.jpg");
	background-position: bottom center;
	background-size: cover;
	#connexion {
		display: flex;
		width: 100vw;
		height: 100vh;
		padding: 30px;
		align-items: center;
		justify-content: center;
		text-align: center;
		flex-direction: column;
		.logo {
			img {
				max-width: 140px;
				height: auto;
			}
		}
		button {
			background: $rouge;
			color: white;
			&:hover {
				background: darken($rouge, 5%);
				box-shadow: 0px 10px 20px rgba(black, 0.2);
			}
		}
	}
	#infos {
		position: absolute;
		background: rgba(white, 0.97);
		bottom: 0px;
		font-size: 11px;
		color: rgba(black, 0.5);
		text-align: center;
		line-height: 18px;
		padding: 15px 30px;
		left: 50%;
		transform: translateX(-50%);
	}
	.loginerror {
		padding: 20px;
		max-width: 500px;
		font-size: 12px;
		line-height: 18px;
		font-weight: 500;
	}
	.animation-wait {
		width: 200px;
		height: 200px;
		position: relative;
		img {
			object-fit: cover;
			height: 100%;
			width: 100%;
		}
	}
	.confirmation {
		font-size: 18px;
		font-weight: 500;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		line-height: 20px;
		padding: 20px;
		span {
			font-size: 0.7em;
		}
	}
}
</style>
