<template>
	<div class="slide-wrapper" @click="clearSelectedItem($event)" ref="slide">
		<div
			:data-slide="slide"
			:data-order="order"
			:class="index === currentDiapositive ? 'slide selected' : 'slide'"
			:style="`background-color: ${fill}; color: ${color};`"
			:data-slide-name="name"
		>
			<div class="navigation" :style="`background-color: ${barre_color}`" @click="selectSlide($event, slide)" @contextmenu="showSlideSubMenu($event, slide)">
				<div class="slide-number">{{ index + 1 }}</div>
				<div class="titre">{{ presentation.barre_laterale }}</div>
				<div class="mauffrey">{{ presentation.nom_client }}</div>
			</div>
			<div class="logo"></div>
			<div class="slide-mask">
				<div class="colonne"></div>
				<div class="colonne"></div>
				<div class="colonne"></div>
				<div class="colonne"></div>
				<div class="colonne"></div>
				<div class="colonne"></div>
				<div class="colonne"></div>
				<div class="colonne"></div>
				<div class="ligne ligne1"></div>
				<div class="ligne ligne2"></div>
				<div class="ligne ligne3"></div>
				<div class="ligne ligne4"></div>
				<div class="ligne ligne5"></div>
				<div class="ligne ligne6"></div>
				<div class="ligne ligne7"></div>
			</div>
			<slot />
		</div>
		<boxajoutVue v-if="index === currentDiapositive && $route.name != 'Modèle'" />
	</div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import boxajoutVue from "../presentation/boxajout.vue";
export default {
	name: "Slide",
	components: { boxajoutVue },
	props: {
		fill: String,
		color: String,
		order: Number,
		index: Number,
		slide: Number,
		path: String,
		barre_color: String,
		name: String,
	},
	data() {
		return {
			options: {
				root: null,
				rootMargin: "0px",
				threshold: [0.52],
			},
		};
	},
	methods: {
		...mapMutations({
			clearSelectedItem: "clearSelectedItem",
		}),
		async selectSlide(e, slide) {
			this.$store.dispatch("selectSlide", slide);
		},
		showSlideSubMenu(e, slide) {
			this.selectSlide(e, slide).then(() => {
				this.$store.dispatch("showSlideSubMenu", e);
			});
		},
	},
	computed: {
		...mapGetters({
			presentation: "currentPresentation",
			currentDiapositive: "currentDiapositive",
		}),
	},
	mounted() {
		// console.log(this.slide);
		// var observer = new IntersectionObserver((entries) => {
		// 	entries.forEach((entry) => {
		// 		if (entry.intersectionRatio > 0.5) {
		// 			// if (!offres_store.tdm_patch) {
		// 			// 	offres_store.select_current_page(props.page_id);
		// 			// }
		// 			console.log("coucou");
		// 			this.selectSlide(null, this.slide);
		// 		}
		// 	});
		// }, this.options);
		// observer.observe(this.$refs.slide);
	},
};
</script>

<style lang="scss">
.slide-mask {
	position: absolute;
	left: 60px;
	right: 0;
	bottom: 0;
	top: 0;
	padding: 40px 50px;
	display: grid;
	gap: 20px;
	grid-template-columns: repeat(8, 1fr);
	z-index: 10;
	pointer-events: none;
	.colonne {
		background: rgba($rouge, 0.05);
		border: solid 1px rgba(white, 0.1);
	}
	.ligne {
		background: rgba($rouge, 0.04);
		position: absolute;
		height: 2px;
		left: 50px;
		right: 50px;
		top: 40px;
		&.ligne1 {
			top: 97px;
		}
		&.ligne2 {
			top: 154px;
		}
		&.ligne3 {
			top: 211px;
		}
		&.ligne4 {
			top: 268px;
		}
		&.ligne5 {
			top: 325px;
		}
		&.ligne6 {
			top: 382px;
		}
		&.ligne7 {
			top: 439px;
		}
	}
}

.slide {
	overflow: hidden;
	background-color: white;
	background-color: attr(data-fill);
	background-size: cover;
	background-position: center;
	width: 960px;
	height: 540px;
	border-radius: 0px;
	border: thin $gris2;
	font-size: 12pt;
	position: relative;
	margin-bottom: 80px;
	transform-origin: top left;
	box-shadow: 2px 2px 6px $gris2;
	outline-color: rgba($rouge, 0.1);
	touch-action: none;
	font-family: "Arial";
	position: relative;
	z-index: 10;
	&:hover {
		filter: drop-shadow(0px 23px 44px rgba(black, 0.14));
	}
	&.selected {
		filter: drop-shadow(0px 23px 44px rgba(black, 0.14));
		outline-width: 10px;
		outline-color: rgba($rouge, 0.1);
		outline-style: solid;
	}
	.navigation {
		background: $rouge;
		color: white;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 20px;
		width: 540px;
		height: 60px;
		transform: rotateZ(-90deg) translateX(-540px);
		transform-origin: top left;
		font-size: 8pt;
		font-style: italic;
		text-transform: uppercase;
		.slide-number {
			position: absolute;
			left: 20px;
			font-size: 10pt;
			transform: rotateZ(90deg);
			font-style: normal;
		}
		.mauffrey {
			position: absolute;
			right: 20px;
			font-size: 8pt;
			font-weight: bold;
			font-style: italic;
		}
	}
	&.previewmode {
		margin: 0 0 120px 0 !important;
		box-shadow: none !important;
		outline: none !important;
		border: none !important;
		.slide-mask {
			display: none !important;
		}
	}
	&:before {
		content: attr(data-slide-name);
		position: absolute;
		color: $rouge;
		font-weight: 900;
		top: -52px;
		font-size: 50px;
		opacity: 0.2;
		display: -webkit-box;
		z-index: 1;
		line-clamp: 1;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}
}
#rendu.previewmode {
	.slide {
		.slide-mask {
			display: none;
		}
	}
}
</style>
