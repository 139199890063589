<template>
	<section id="squelette">
		<Header style="position: sticky; top: 0px; z-index: 900"></Header>
		<div class="page" v-if="ready">
			<div class="squelette_editor">
				<draggable
					class="slides"
					v-model="squelette.previews_elements"
					draggable=".skeleton"
					ghost-class="ghost"
					:multiDrag="true"
					multiDragKey="Shift"
					selectedClass="tomove"
				>
					<div class="skeleton" v-for="(slide, index) in squelette.previews_elements" :key="`slide${index}${slide.id}`">
						<div class="move_composition" @click="movePageToPosition(index)"><i class="fa-regular fa-sort"></i></div>
						<div class="delete_composition" @click="$store.commit('removeFromComposition', slide.id)"><i class="fa-solid fa-times"></i></div>
						<div class="order">{{ index + 1 }}</div>
						<img :src="slide.url" />
					</div>
				</draggable>
				<div class="box_ajout">
					<h3>Choisissez un modèle <br /><span>dans la liste ci-dessous</span></h3>
					<div class="liste_des_modeles">
						<div class="filtres">
							<div class="search">
								<input type="text" v-model="filtre" @keyup="filterModeles" placeholder="Tapez un mot clé pour filtrer les modèles" />
								<i class="fa-regular fa-search"></i>
							</div>
							<div class="categories">
								<select v-model="classes_filter" @change="filterModeles">
									<option value="" selected="selected">Filtrer par...</option>
									<optgroup label="Type de modèle">
										<option value="slidevierge">Vierge</option>
										<option value="slideslide">Prêt à l'emploi</option>
										<option value="private">Privés</option>
									</optgroup>
									<optgroup label="Groupe Mauffrey">
										<option value="all-groupe_mauffrey">Tous</option>
										<option v-for="item in $store.state.groupe_mauffrey" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
									</optgroup>
									<optgroup label="Géographie">
										<option value="all-geographie">Tous</option>
										<option v-for="item in $store.state.geographie" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
									</optgroup>
									<optgroup label="Environnement / Sécurité">
										<option value="all-qualite">Tous</option>
										<option v-for="item in $store.state.qualite" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
									</optgroup>
									<optgroup label="Formation">
										<option value="all-formation">Tous</option>
										<option v-for="item in $store.state.formation" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
									</optgroup>
									<optgroup label="Digitalisation">
										<option value="all-digitalisation">Tous</option>
										<option v-for="item in $store.state.digitalisation" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
									</optgroup>
									<optgroup label="Métiers">
										<option value="all-prestations">Tous</option>
										<option v-for="item in $store.state.prestations" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
									</optgroup>
									<optgroup label="Filières">
										<option value="all-filieres">Tous</option>
										<option v-for="item in $store.state.filieres" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
									</optgroup>
									<optgroup label="Matériel">
										<option value="all-materiel">Tous</option>
										<option v-for="item in $store.state.materiel" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
									</optgroup>
									<optgroup label="Toolbox">
										<option value="all-toolbox">Tous</option>
										<option v-for="item in $store.state.toolbox" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
									</optgroup>
									<optgroup label="Procédures">
										<option value="all-procedures">Tous</option>
										<option v-for="item in $store.state.procedures" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
									</optgroup>
								</select>
							</div>
						</div>

						<div class="liste">
							<div
								:class="[selected_modele && selected_modele.id === modele.id ? 'current item' : 'item', modele.classes]"
								v-for="modele in modeles"
								:key="modele.id"
								@click="selected_modele = modele"
							>
								<div class="preview">
									<img :src="modele.fields.preview_url" />
								</div>
								<div class="name">{{ modele.fields.nom }}</div>
								<div class="hidden">{{ modele.tags }} {{ modele.fields.nom }}</div>
							</div>
						</div>

					</div>
					<div class="addToComposition" @click="addToComposition(selected_modele)" v-if="selected_modele">Ajouter</div>
				</div>
			</div>
			<div class="parameters">
				<div class="form_group">
					<label>Nom du squelette</label>
					<input type="text" v-model="squelette.fields.nom" />
				</div>
				<div class="form_group select" v-if="user.fields.role.includes('administrateur')">
					<label>Visibilité</label>
					<select v-model="squelette.fields.status">
						<option value="visible">Visible</option>
						<option value="brouillon">Brouillon</option>
						<option value="prive">Privé</option>
					</select>
				</div>
				<div class="form_group select" v-if="user.fields.role.includes('administrateur')">
					<label>Langue</label>
					<select v-model="squelette.fields.langue">
						<option value="français">Français</option>
						<option value="english">English</option>
						<option value="espanol">Espanol</option>
					</select>
				</div>
				<div class="form_group">
					<label>Tags</label>
					<small style="margin-bottom: 10px; color: gray">Séparés par une virgule</small>
					<input type="text" v-model="squelette.fields.tags" />
				</div>

				<div class="form_group" v-if="!user.fields.role.includes('administrateur') && !squelette.fields.proposer_comme_public">
					<label>Rendre public</label>
					<small style="margin-bottom: 10px; color: gray">Demander aux administrateurs de la Ruche de rendre votre squelette public.</small>
					<button class="low" @click="makeSquelettePublic">Faire la demande</button>
				</div>

				<div class="form_group" v-if="!user.fields.role.includes('administrateur') && squelette.fields.proposer_comme_public">
					<label>Rendre public</label>
					<small style="margin-bottom: 10px; color: gray">Votre demande de publication est en cours d'études.</small>
					
				</div>

				<button @click="localsaveSquelette">
					<span v-if="!save_encours">Sauvegarder</span>
					<span v-else>...</span>
				</button>
			</div>
		</div>
		<div class="loading" v-else>
			<i class="fa-duotone fa-spin fa-spinner-third"></i>
			Chargement de votre squelette <br />et récupération des dernières versions de modèles. <br />Ceci peut durer quelques instants
		</div>
	</section>
</template>
<script>
import Draggable from "vuedraggable-multi";
import Header from "../components/Header";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { arrayMoveMutable } from "array-move";
import slugify from "slugify";

export default {
	name: "Squelette",
	components: { Header, Draggable },
	data() {
		return {
			filtre: "",
			save_encours: false,
			ready: false,
			selected_modele: null,
			classes_filter: "",
		};
	},
	computed: {
		modeles() {
			return this.modeles_base.filter((el) => el.fields.langue === this.squelette.fields.langue);
		},
		...mapGetters({
			squelette: "currentSquelette",
			modeles_base: "modeles",
			categories: "categories",
			user: "currentUser",
		}),
	},
	watch: {
		modeles(newValue, oldValue) {
			if (newValue.length) {
				this.getFullSquelette(this.$route.params.id)
					.then(() => {
						this.ready = true;
					})
					.catch((err) => {
						console.log(err);
					});
			}
		},
	},

	methods: {
		localsaveSquelette() {
			this.save_encours = true;
			this.saveSquelette()
				.then((res) => {
					this.save_encours = false;
				})
				.catch((err) => {
					this.save_encours = false;
				});
		},
		movePageToPosition(index) {
			let new_position = prompt("Indiquez vers quel emplacement vous souhaitez déplacer ce modèle :");
			console.log("Position ----", index, " New position ----", new_position);
			if (parseInt(new_position) >= 1 && parseInt(new_position) <= this.squelette.previews_elements.length) {
				arrayMoveMutable(this.squelette.previews_elements, parseInt(index), parseInt(new_position) - 1);
			}
		},
		filterModeles() {
			let items = document.querySelectorAll(".box_ajout .liste .item");
			if (this.filtre.length < 3 && this.classes_filter === "") {
				items.forEach((el) => {
					el.classList.remove("hidden");
				});
			} else {
				items.forEach((el) => {
					let must_be_show = 0;
					if (this.filtre.length >= 3) {
						let tags = el.querySelector(".hidden").innerText;
						if (tags.toLowerCase().includes(slugify(this.filtre).toLowerCase())) {
							must_be_show++;
						}
					}
					if (this.classes_filter != "") {
						if (el.classList.contains(this.classes_filter)) {
							must_be_show++;
						}
					}
					if (must_be_show >= 1) {
						el.classList.remove("hidden");
					} else {
						el.classList.add("hidden");
					}
				});
			}
		},
		...mapActions({
			removeFromComposition: "removeFromComposition",
			getFullSquelette: "getFullSquelette",
			getModeles: "getModeles",
			getSquelettes: "getSquelettes",
			addToComposition: "addToComposition",
			resetCurrentSquelette: "resetCurrentSquelette",
			saveSquelette: "saveSquelette",
			getNewModeles: "getNewModeles",
			makeSquelettePublic: "makeSquelettePublic"
		}),
	},
	mounted() {
		if (this.$store.state.modeles.length > 0) {
			this.getFullSquelette(this.$route.params.id)
				.then(() => {
					this.ready = true;
				})
				.catch((err) => {
					console.log(err);
				});
		} else {
			this.$store.dispatch("getNewModeles").then(() => {
				console.log("je charge les modèles");
			});
		}
	},
	unmounted() {
		this.ready = false;
	},
};
</script>
<style lang="scss" scoped>
#squelette {
	background-image: url("../assets/img/fond-alveole.png");
	background-position: top left;
	background-repeat: repeat;

	.loading {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		font-size: 13px;
		line-height: 20px;
		padding: 100px 30px;
		font-weight: 500;
		i,
		svg {
			color: $rouge;
			margin-bottom: 10px;
			font-size: 20px;
		}
	}

	.page {
		display: flex;

		.squelette_editor {
			flex: 1;
			padding-bottom: 100px;
		}
		.parameters {
			flex: 0.2;
			height: calc(100vh - 80px);
			background: white;
			padding: 30px;
			position: sticky;
			top: 80px;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			overflow-y: auto;
			-webkit-overflow-scrolling: touch;
			overscroll-behavior: none;
			h6 {
				font-size: 14px;
				font-weight: 600;
				margin: 20px 0 15px;
			}
			.form_group {
				display: flex;
				flex-direction: column;
				margin-bottom: 30px;
				width: 100%;
				align-items: flex-start;
				button.low {
					background: transparent;
					color: $bleu;
					padding: 0;
					border: none;
					border-radius: 0px;
				}
				label {
					color: slategray;
					font-weight: 500;
					font-size: 10px;
					text-transform: uppercase;
					margin-bottom: 4px;
				}
				input[type="text"] {
					height: 30px;
					background: rgba(black, 0.05);
					padding: 0 10px;
					border: none;
					outline: none;
					border-radius: 2px;
					width: 100%;
				}
				&.select {
					display: flex;
					select {
						height: 30px;
						background: rgba(black, 0.05);
						padding: 0 10px;
						border: none;
						outline: none;
						border-radius: 2px;
					}
				}
			}
		}
	}
	.slides {
		display: flex;
		//flex-direction: column;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		padding: 60px 30px 0;

		.skeleton {
			max-width: 320px;
			border: solid 2px white;
			border-radius: 10px;
			box-shadow: 3px 3px 20px rgba(black, 0.2);
			margin: 0 40px 40px 0;
			position: relative;
			.delete_composition {
				position: absolute;
				top: 0px;
				right: 0px;
				width: 30px;
				height: 30px;
				display: flex;
				border-radius: 50%;
				transform: translate(50%, -50%);
				justify-content: center;
				align-items: center;
				cursor: pointer;
				background: $rougeclair;
				border: solid 1px $rouge;
				color: $rouge;
				transition: all 0.3s ease;
				i,
				svg {
					transform: translateY(1px);
				}
				&:hover {
					background: $rouge;
					color: $rougeclair;
				}
			}
			.move_composition {
				position: absolute;
				top: 0px;
				right: 36px;
				width: 30px;
				height: 30px;
				display: flex;
				border-radius: 50%;
				transform: translate(50%, -50%);
				justify-content: center;
				align-items: center;
				cursor: pointer;
				background: $vertclair;
				border: solid 1px $vert;
				color: $vert;
				transition: all 0.3s ease;
				i,
				svg {
					transform: translateY(1px);
				}
				&:hover {
					background: $vert;
					color: $vertclair;
				}
			}
			&.ghost {
				box-shadow: 3px 3px 30px rgba($vert, 0.2);
			}
			&.tomove {
				border: dashed 2px $bleu;
				box-shadow: 3px 3px 30px rgba($bleu, 0.2);
			}
			.order {
				position: absolute;
				bottom: 0;
				box-shadow: 1px 1px 5px rgba(black, 0.4);
				z-index: 99;
				left: 0;
				font-weight: bold;
				width: 50px;
				height: 40px;
				background: black;
				color: white;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 0 20px 0 10px;
				border-right: solid 2px white;
				border-top: solid 1px white;
			}
			img {
				width: 100%;
				height: auto;
				border-radius: 10px;
			}
		}
	}
	.box_ajout {
		width: 100%;
		max-width: 700px;
		margin: 0 auto;
		background: white;
		border-radius: 10px;
		padding: 30px;
		display: flex;
		align-items: center;
		flex-direction: column;
		box-shadow: 3px 3px 20px rgba(black, 0.2);
		h3 {
			font-size: 15px;
			font-weight: 500;
			text-align: center;
			span {
				font-size: 12px;
				color: slategray;
			}
		}
		.liste_des_modeles {
			width: 100%;
		}
		.filtres {
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 15px auto 30px;
			select {
				background: rgba(black, 0.04);
				height: 40px;
				border-radius: 5px;
				border: none;
				padding: 0 10px;
				font-size: 13px;
				margin-left: 10px;
			}
		}
		.search {
			width: 100%;
			max-width: 300px;
			position: relative;
			background: rgba(black, 0.04);
			padding: 0 10px;
			border-radius: 5px;
			input {
				width: 100%;
				outline: none;
				height: 40px;
				font-size: 13px;
				font-weight: 500;
				border: none;
				background: transparent;

				&::placeholder {
					width: 100%;
					outline: none;
					height: 40px;
					font-size: 13px;
					font-weight: 500;
					border: none;
					background: transparent;
					&::placeholder {
						font-weight: 300;
						font-size: 11px;
					}
				}
			}
			i,
			svg {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				right: 10px;
			}
		}
		.liste {
			display: flex;
			height: 250px;
			background: rgba(black, 0.05);
			overflow: auto;
			-webkit-overflow-scrolling: touch;
			overscroll-behavior: none;
			flex-wrap: wrap;
			padding: 5px;
			align-items: flex-start;

			.item {
				flex: 0 0 33.3333%;
				padding: 8px;
				position: relative;
				img {
					max-width: 100%;
					border-radius: 4px;
				}
				.hidden {
					display: none;
				}
				.name {
					font-size: 12px;
					position: absolute;
					bottom: 8px;
					left: 8px;
					background: black;
					color: white;
					padding: 4px 8px;
					border-radius: 0 0 0 4px;
				}
				&.hidden {
					display: none;
				}
				&.current {
					img {
						outline: solid 4px $bleu;
						box-shadow: 0px 0px 10px rgba($bleu, 0.3);
					}
					.name {
						background: $bleu;
					}
				}
			}
		}
		.addToComposition {
			margin-top: 20px;
			display: inline-block;
			padding: 10px 20px;
			border-radius: 30px;
			background: $bleu;
			color: $bleuclair;
			transition: all 0.3s ease;
			cursor: pointer;
			&:hover {
				transform: translateY(2px);
				box-shadow: 1px 1px 8px rgba(black, 0.1);
				background: $rouge;
			}
		}
	}
}
</style>
