<template>
	<div :class="classes" :type="type" :data-slide="slide" :data-composant="composant"
		:ref="`slide${slide}composant${composant}`"
		:style="`position: absolute; left: ${x}px; top: ${y}px; width: ${sizing.w}px; height: ${sizing.h}px;`"
		@dblclick="switchMode">
		<div class="non-crop-style" v-if="path.length > 0 && sizing.type != 'crop'">
			<img v-if="lien === undefined || lien.url === ''" :src="path"
				:style="`opacity: ${1 - transparency / 100}`" />
			<a v-else :href="lien.url" target="_blank" :style="`opacity: ${1 - transparency / 100}`">
				<img :src="path" />
			</a>
			
		</div>

		<div class="crop-style" v-if="path.length > 0 && sizing.type === 'crop'">
			<img :style="`width: ${w * (sizing.zoom / 100)}px; height: ${h * (sizing.zoom / 100)}px; left: ${sizing.decalage_x}px; top: ${sizing.decalage_y}px; opacity: ${
					1 - transparency / 100
				}`" v-if="lien === undefined || lien.url === ''" :src="path" />
			<a v-else :href="lien.url" target="_blank">
				<img :style="`width: ${w * (sizing.zoom / 100)}px; height: ${h * (sizing.zoom / 100)}px; left: ${sizing.decalage_x}px; top: ${
						sizing.decalage_y
					}px; opacity: ${1 - transparency / 100}`" :src="path" />
			</a>
			
		</div>
		<toolbox :data-slide="slide" :data-composant="composant" v-if="current" />
		<div class="corner edge-top edge-left"></div>
		<div class="corner edge-top edge-right"></div>
		<div class="corner edge-bottom edge-right"></div>
		<div class="corner edge-bottom edge-left"></div>
	</div>
</template>
<script>
import interact from "interactjs";
import { mapActions, mapGetters, mapMutations } from "vuex";
import toolbox from "../pptx-library/toolbox.vue";

export default {
	name: "PPTXIMAGE",
	components: { toolbox },
	props: {
		type: String,
		slide: Number,
		composant: Number,
		x: Number,
		y: Number,
		w: Number,
		h: Number,
		data: String,
		transparency: Number,
		path: String,
		sizing: Object,
		is_dynamic: Boolean,
		dynamic_code: String,
		rounding: Boolean,
		lien: Object,
		current: Boolean,
	},
	data() {
		return {};
	},
	computed: {
		classes() {
			let classes = `pptx-image resizable draggable `;
			if (this.sizing.type === "cover") {
				classes += "cover";
			} else if (this.sizing.type === "crop") {
				classes += "crop";
			} else {
				classes += "contain";
			}

			if (this.rounding) {
				classes += " rounded";
			}
			return classes;
		},
		...mapGetters({
			globales_image: "globales_image",
		}),
	},
	methods: {
		...mapMutations({
			updateZoomLevel: "updateZoomLevel",
		}),
		...mapActions({
			updateCropPosition: "updateCropPosition",
		}),
		updateCropSize(el, sizes, slide, composant) {
			el.style.transform = "none";
			el.dataset.x = 0;
			el.dataset.y = 0;
			this.$store.dispatch("updateElementSizeCropImage", { sizes, slide, composant });
		},
		updateSize(el, sizes, slide, composant) {
			el.style.transform = "none";
			el.dataset.x = 0;
			el.dataset.y = 0;
			this.$store.dispatch("updateElementSizeImage", { sizes, slide, composant });
		},
		updatePosition(el, slide, composant, startx, starty) {
			el.style.transform = "none";
			let sizes = {
				x: parseFloat(el.style.left),
				y: parseFloat(el.style.top),
				differencex: parseFloat(el.style.left) - startx,
				differencey: parseFloat(el.style.top) - starty,
			};
			this.$store.dispatch("updateElementPosition", { sizes, slide, composant });
		},
		updateCropPosition(el, slide, composant, startx, starty) {
			el.style.transform = "none";
			let sizes = {
				x: parseFloat(el.style.left),
				y: parseFloat(el.style.top),
				differencex: startx,
				differencey: starty,
			};
			this.$store.dispatch("updateElementCropPosition", { sizes, slide, composant });
		},
		getTranslateX(domEl) {
			var style = window.getComputedStyle(domEl);
			return style.transform;
		},
		switchMode() {
			let concatenate = `slide${this.slide}composant${this.composant}`;
			let el = this.$refs[concatenate];
			if (el.classList.contains("edit-mode")) {
				let image = el.querySelector("img");
				// Je récupère les valeurs
				let sizes = {
					x: parseInt(image.style.left),
					y: parseInt(image.style.top),
					width: parseInt(image.style.width),
					height: parseInt(image.style.height),
				};
				this.$store.dispatch("updateElementSizeCropImage", { sizes, slide: this.slide, composant: this.composant });

				// Je supprime l'édition de l'image
				interact(el).resizable(true);
				interact(el).draggable(true);

				interact(image).resizable(false);
				interact(image).draggable(false);
				el.classList.remove("edit-mode");
			} else {
				// Je rend possible l'édition de l'image
				interact(el).resizable(false);
				interact(el).draggable(false);
				el.classList.add("edit-mode");
				let position = {
					x: 0,
					y: 0,
					startx: 0,
					starty: 0,
				};
				let image = el.querySelector("img");
				interact(image).resizable({
					margin: 5,
					edges: {
						bottom: true,
						right: true,
					},
					modifiers: [
						interact.modifiers.aspectRatio({
							// make sure the width is always double the height
							ratio: "preserve",
							//equalDelta: true,
						}),
					],
					onmove: (event) => {
						let { x, y } = event.currentTarget.dataset;
						x = (parseFloat(x) || 0) + event.deltaRect.left;
						y = (parseFloat(y) || 0) + event.deltaRect.top;
						Object.assign(event.target.style, {
							width: `${event.rect.width}px`,
							height: `${event.rect.height}px`,
							transform: `translate(${x}px, ${y}px)`,
						});
						Object.assign(event.target.dataset, { x, y });
					},
					onend: (event) => {
						let { x, y } = event.currentTarget.dataset;
						let { width, height } = event.currentTarget.getBoundingClientRect();
						let { left, top } = event.currentTarget.style;
						let pLeft = parseFloat(x) + parseFloat(left);
						let pTop = parseFloat(y) + parseFloat(top);
						let sizes = {
							width,
							height,
							pLeft,
							pTop,
						};
					},
				});
				interact(image).draggable({
					onstart: (event) => {
						position.x = parseFloat(event.currentTarget.style.left);
						position.y = parseFloat(event.currentTarget.style.top);
						position.startx = parseFloat(event.currentTarget.style.left);
						position.starty = parseFloat(event.currentTarget.style.top);
					},
					onmove: (event) => {
						position.x += event.dx;
						position.y += event.dy;
						event.currentTarget.style.left = `${position.x}px`;
						event.currentTarget.style.top = `${position.y}px`;
					},
					onend: (event) => {},
				});
			}
		},
	},
	mounted() {
		let concatenate = `slide${this.slide}composant${this.composant}`;
		let el = this.$refs[concatenate];
		let selected_items;
		let position = {
			x: 0,
			y: 0,
			startx: 0,
			starty: 0,
		};
		interact(el).resizable({
			margin: 5,
			edges: {
				top: ".edge-top",
				left: ".edge-left",
				bottom: ".edge-bottom",
				right: ".edge-right",
			},

			onmove: (event) => {
				let { x, y } = event.currentTarget.dataset;

				x = (parseFloat(x) || 0) + event.deltaRect.left;
				y = (parseFloat(y) || 0) + event.deltaRect.top;

				Object.assign(event.target.style, {
					width: `${event.rect.width}px`,
					height: `${event.rect.height}px`,
					transform: `translate(${x}px, ${y}px)`,
				});

				Object.assign(event.target.dataset, { x, y });
			},
			onend: (event) => {
				let { x, y } = event.currentTarget.dataset;
				let { width, height } = event.currentTarget.getBoundingClientRect();
				let { left, top } = event.currentTarget.style;
				let pLeft = parseFloat(x) + parseFloat(left);
				let pTop = parseFloat(y) + parseFloat(top);
				let sizes = {
					width,
					height,
					pLeft,
					pTop,
				};
				this.updateSize(event.currentTarget, sizes, this.slide, this.composant);
			},
		});
		interact(el).draggable({
			onstart: (event) => {
				position.x = parseFloat(event.currentTarget.style.left);
				position.y = parseFloat(event.currentTarget.style.top);
				position.startx = parseFloat(event.currentTarget.style.left);
				position.starty = parseFloat(event.currentTarget.style.top);
			},
			onmove: (event) => {
				position.x += event.dx;
				position.y += event.dy;
				event.currentTarget.style.left = `${position.x}px`;
				event.currentTarget.style.top = `${position.y}px`;
			},
			onend: (event) => {
				this.updatePosition(event.currentTarget, this.slide, this.composant, position.startx, position.starty);
			},
		});
	},
};
</script>

<style lang="scss">
.pptx-image {
	background-position: center;
	background-repeat: no-repeat;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		position: absolute;
		top: 0;
		left: 0;
		transform-origin: center;
		//transform: translate3d(-50%, -50%, 0);
	}
	&.contain {
		img {
			object-fit: contain;
		}
	}
	&.rounded {
		border-radius: 50%;
		overflow: hidden;
	}
	&.crop {
		position: relative;
		overflow: hidden;
		img {
			position: absolute;
			object-fit: initial;
			width: auto;
			height: auto;
			top: 0px;
			left: 0px;
			transform: none;
			transform-origin: center;
		}
	}
}
.slide.selected {
	overflow: visible;
	.pptx-element.selected {
		z-index: 90;
		.pptx-image.crop {
			overflow: hidden;
			&.edit-mode {
				overflow: visible;
				img {
					opacity: 0.5 !important;
				}
			}
		}
	}
}
</style>
