<template>
	<div :class="classes" :type="type" :data-slide="slide" :data-composant="composant"
		:ref="`slide${slide}composant${composant}`"
		:style="`position: absolute; left: ${x}px; top: ${y}px; width: ${w}px; height: ${h}px;`">
		<iframe frameborder="0" :src="getYouTubeId(link)"></iframe>
		<div class="corner edge-top edge-left"></div>
		<div class="corner edge-top edge-right"></div>
		<div class="corner edge-bottom edge-right"></div>
		<div class="corner edge-bottom edge-left"></div>
	</div>
</template>
<script>
import interact from "interactjs";

export default {
	name: "PPTXVIDEO",
	components: {},
	props: {
		slide: Number,
		composant: Number,
		type: String,
		x: Number,
		y: Number,
		w: Number,
		h: Number,
		link: String,
	},
	data() {
		return {};
	},
	methods: {
		updateSize(el, sizes, slide, composant) {
			el.style.transform = "none";
			el.dataset.x = 0;
			el.dataset.y = 0;
			this.$store.dispatch("updateElementSize", { sizes, slide, composant });
		},
		updatePosition(el, slide, composant, startx, starty) {
			el.style.transform = "none";
			let sizes = {
				x: parseFloat(el.style.left),
				y: parseFloat(el.style.top),
				differencex: parseFloat(el.style.left) - startx,
				differencey: parseFloat(el.style.top) - starty,
			};
			this.$store.dispatch("updateElementPosition", { sizes, slide, composant });
		},
		getYouTubeId(url) {
			const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
			const match = url.match(regExp);
			return `//www.youtube.com/embed/${match[2]}?controls=0&loop=1`;
		},
	},
	computed: {
		classes() {
			let classes = `pptx-video resizable draggable `;
			return classes;
		},
	},
	mounted() {
		let concatenate = `slide${this.slide}composant${this.composant}`;
		let el = this.$refs[concatenate];
		let position = {
			x: 0,
			y: 0,
			startx: 0,
			starty: 0,
		};
		interact(el)
			.resizable({
				margin: 5,
				edges: {
					top: ".edge-top",
					left: ".edge-left",
					bottom: ".edge-bottom",
					right: ".edge-right",
				},

				onmove: (event) => {
					let { x, y } = event.currentTarget.dataset;

					x = (parseFloat(x) || 0) + event.deltaRect.left;
					y = (parseFloat(y) || 0) + event.deltaRect.top;

					Object.assign(event.target.style, {
						width: `${event.rect.width}px`,
						height: `${event.rect.height}px`,
						transform: `translate(${x}px, ${y}px)`,
					});

					Object.assign(event.target.dataset, { x, y });
				},
				onend: (event) => {
					let { x, y } = event.currentTarget.dataset;
					let { width, height } = event.currentTarget.getBoundingClientRect();
					let { left, top } = event.currentTarget.style;
					let pLeft = parseFloat(x) + parseFloat(left);
					let pTop = parseFloat(y) + parseFloat(top);
					let sizes = {
						width,
						height,
						pLeft,
						pTop,
					};
					this.updateSize(event.currentTarget, sizes, this.slide, this.composant);
				},
			})
			.draggable({
				onstart: (event) => {
					position.x = parseFloat(event.currentTarget.style.left);
					position.y = parseFloat(event.currentTarget.style.top);
					position.startx = parseFloat(event.currentTarget.style.left);
					position.starty = parseFloat(event.currentTarget.style.top);
				},
				onmove: (event) => {
					position.x += event.dx;
					position.y += event.dy;
					event.currentTarget.style.left = `${position.x}px`;
					event.currentTarget.style.top = `${position.y}px`;
				},
				onend: (event) => {
					this.updatePosition(event.currentTarget, this.slide, this.composant, position.startx, position.starty);
				},
			});
	},
};
</script>

<style lang="scss">
.pptx-video {
	display: flex;
	align-items: center;
	background: rgba(white, 0.1);
	align-items: center;
	justify-content: center;
	text-align: center;
	font-size: 12px;
	flex-direction: column;
	border: solid 1px rgba(black, 0.05);
	overflow: hidden;
	iframe {
		min-width: 100%;
		min-height: 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		user-select: none;
		border: none;
		transform: translate(-50%, -50%);
		pointer-events: none;
	}
}
.pptx-element.selected {
	.pptx-video {
	}
}
</style>
