<template>
	<div :class="classes" :type="type" :data-slide="slide" :data-composant="composant"
		:ref="`slide${slide}composant${composant}`" :style="`border: ${line.dashType} ${line.width}px ${
			line.color
		}; position: absolute; background-color: ${fill}; left: ${x}px; top: ${y}px; width: ${w}px; height: ${h}px; transform: rotateZ(${rotate}deg); border-radius: ${rectRadius}px; opacity: ${
			(100 - transparency) / 100
		}`">
		<toolbox :data-slide="slide" :data-composant="composant" v-if="current" />
		<div class="corner edge-top edge-left"></div>
		<div class="corner edge-top edge-right"></div>
		<div class="corner edge-bottom edge-right"></div>
		<div class="corner edge-bottom edge-left"></div>
	</div>
</template>
<script>
import interact from "interactjs";
import toolbox from "../pptx-library/toolbox.vue";

export default {
	name: "PPTXSHAPE",
	components: { toolbox },
	props: {
		type: String,
		slide: Number,
		composant: Number,
		x: Number,
		y: Number,
		w: Number,
		h: Number,
		line: Object,
		rotate: Number,
		fill: String,
		transparency: Number,
		rectRadius: Number,
		click_a_travers: Boolean,
		shadow: Object,
		current: Boolean,
	},
	data() {
		return {};
	},
	methods: {
		updateSize(el, sizes, slide, composant) {
			el.style.transform = "none";
			el.dataset.x = 0;
			el.dataset.y = 0;
			this.$store.dispatch("updateElementSize", { sizes, slide, composant });
		},
		updatePosition(el, slide, composant, startx, starty) {
			el.style.transform = "none";
			let sizes = {
				x: parseFloat(el.style.left),
				y: parseFloat(el.style.top),
				differencex: parseFloat(el.style.left) - startx,
				differencey: parseFloat(el.style.top) - starty,
			};
			this.$store.dispatch("updateElementPosition", { sizes, slide, composant });
		},
	},
	computed: {
		classes() {
			let classes = `pptx-shape resizable draggable `;
			if (this.click_a_travers) {
				classes += " clickatravers";
			}
			if (this.shadow && this.shadow.type === "outer") {
				classes += " shadow";
			}
			return classes;
		},
	},
	mounted() {
		let concatenate = `slide${this.slide}composant${this.composant}`;
		let el = this.$refs[concatenate];
		let position = {
			x: 0,
			y: 0,
			startx: 0,
			starty: 0,
		};
		interact(el)
			.resizable({
				margin: 10,
				edges: {
					top: ".edge-top",
					left: ".edge-left",
					bottom: ".edge-bottom",
					right: ".edge-right",
				},

				onmove: (event) => {
					let { x, y } = event.currentTarget.dataset;

					x = (parseFloat(x) || 0) + event.deltaRect.left;
					y = (parseFloat(y) || 0) + event.deltaRect.top;

					Object.assign(event.target.style, {
						width: `${event.rect.width}px`,
						height: `${event.rect.height}px`,
						transform: `translate(${x}px, ${y}px)`,
					});

					Object.assign(event.target.dataset, { x, y });
				},
				onend: (event) => {
					let { x, y } = event.currentTarget.dataset;
					let { width, height } = event.currentTarget.getBoundingClientRect();
					let { left, top } = event.currentTarget.style;
					let pLeft = parseFloat(x) + parseFloat(left);
					let pTop = parseFloat(y) + parseFloat(top);
					let sizes = {
						width,
						height,
						pLeft,
						pTop,
					};
					this.updateSize(event.currentTarget, sizes, this.slide, this.composant);
				},
			})
			.draggable({
				onstart: (event) => {
					position.x = parseFloat(event.currentTarget.style.left);
					position.y = parseFloat(event.currentTarget.style.top);
					position.startx = parseFloat(event.currentTarget.style.left);
					position.starty = parseFloat(event.currentTarget.style.top);
				},
				onmove: (event) => {
					position.x += event.dx;
					position.y += event.dy;
					event.currentTarget.style.left = `${position.x}px`;
					event.currentTarget.style.top = `${position.y}px`;
				},
				onend: (event) => {
					this.updatePosition(event.currentTarget, this.slide, this.composant, position.startx, position.starty);
				},
			});
	},
};
</script>

<style lang="scss">
.pptx-shape {
	min-height: 1px;
	&.clickatravers {
		pointer-events: none;
	}
	&.shadow {
		box-shadow: 0 0 30px 0 rgba(black, 0.5);
	}
}
</style>
