<template>
	<section id="utilisateurs">
		<Header />
		<Users />
	</section>
</template>
<script>
import Header from "../components/Header";
import Users from "../components/Utilisateurs";
export default {
	name: "Utilisateurs",
	components: { Header, Users },
	data() {
		return {};
	},
	methods: {},
};
</script>

<style lang="scss">
#utilisateurs {
	background: white;
	background-image: url("../assets/img/fondruche2.jpeg");
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	background-attachment: fixed;
}
</style>
