<template>
	<div id="navigator">
		<draggable
			class="ordering_list"
			v-model="presentation.slides"
			draggable=".item"
			ghost-class="ghost"
			@change="log"
			:multiDrag="true"
			multiDragKey="Shift"
			selectedClass="tomove"
		>
			<div
				:class="index === currentDiapositive ? 'item selected' : 'item'"
				v-for="(slide, index) in currentPresentation.fields.slides"
				:key="`ordering${index}`"
				:data-index="index"
				@click="changeSlide($event, index)"
				@contextmenu.prevent="handle($event, index)"
			>
				<div class="numero">{{ index + 1 }}</div>
				<!-- <div class="zone-image">
					<img v-if="slide.preview_image" class="preview" :src="slide.preview_image" />
					<div v-else class="rectangle_blanc"></div>
				</div> -->
				<div class="name"><input type="text" v-model="slide.name" /></div>
			</div>
		</draggable>
		<vue-context ref="menu">
			<li>
				<a @click.prevent="showChooseSlide()">Insérer une slide</a>
			</li>
			<li>
				<a @click.prevent="duplicateSlide()">Dupliquer cette slide</a>
			</li>
			<li>
				<a @click.prevent="removeSlide()">Supprimer cette slide</a>
			</li>
			<li v-if="(currentDiapositive != null && currentUser.fields.role === 'super_administrateur') || currentUser.fields.role === 'administrateur'">
				<a @click.prevent="createSlideModele() && hideComposantSubMenu()">Exporter en tant que modèle</a>
			</li>
		</vue-context>
	</div>
</template>
<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
//import draggable from "vuedraggable";
import { Sortable, MultiDrag } from "sortablejs";
import draggable from "vuedraggable-multi";
import html2canvas from "html2canvas";
import VueContext from "vue-context";
import { Dialog } from "vant";
export default {
	name: "Navigator",
	components: { draggable, VueContext },
	data() {
		return {};
	},
	computed: {
		...mapState({
			currentPresentation: "current_presentation",
		}),
		...mapGetters({
			presentation: "currentPresentation",
			currentDiapositive: "currentDiapositive",
			currentUser: "currentUser",
		}),
	},
	methods: {
		...mapMutations({
			removeElementSlide: "removeElementSlide",
			showChooseSlide: "showChooseSlide",
		}),
		...mapActions({
			selectSlide: "selectSlide",
			hideComposantSubMenu: "hideComposantSubMenu",
			duplicateSlide: "duplicateSlide",
			addSlide: "addSlide",
			createSlideModele: "exportSlideToModele",
		}),
		removeSlide() {
			Dialog.confirm({
				title: "Suppression d'une diapositive",
				message: "Voulez-vous vraiment supprimer cette diapositive ?",
				confirmButtonText: "Oui",
				cancelButtonText: "Annuler",
			})
				.then(() => {
					this.removeElementSlide();
				})
				.catch(() => {
					// on cancel
				});
		},
		log(e) {
			this.$store.commit("reorganized_slides", { newIndex: e.newIndex, oldIndex: e.oldIndex });
		},
		generateMiniatures() {
			let items = [...document.querySelectorAll("#navigator .ordering_list .item")];
			let indent = 0;
			items.map((el, index) => {
				indent++;
				if (el.querySelector(".zone-image .rectangle_blanc")) {
					setTimeout(() => {
						this.$store.dispatch("generateSlideMiniature", index);
					}, indent * 500);
				}
			});
		},
		changeSlide(e, val) {
			if (!e.shiftKey) {
				this.selectSlide(val);
			}
		},
		handle(e, val) {
			this.selectSlide(val).then(() => {
				this.$refs.menu.open(e);
			});
		},
	},
	mounted() {
		setTimeout(() => {
			this.generateMiniatures();
		}, 1000);
	},
};
</script>

<style lang="scss" scoped>
#navigator {
	position: sticky;
	top: 58px;
	height: calc(100vh - 58px);
	padding: 20px 17px 20px 23px;
	width: 100%;
	max-width: 200px;
	min-width: 180px;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	box-shadow: 0px 0px 10px rgba(black, 0.1);
	z-index: 10;
	transition: scroll-position 0.2s ease-out;
	will-change: scroll-position;
	.item {
		display: flex;
		text-align: center;
		position: relative;
		align-items: center;
		cursor: grab;
		border-bottom: dashed 1px rgba(black, 0.2);
		padding: 5px 0;
		.numero {
			color: $rouge;
			flex: 0 0 auto;
			width: 20px;
			font-size: 10px;
			font-weight: 500;
			border-radius: 50%;
			pointer-events: none;
			text-align: left;
			cursor: move;
		}
		.zone-image {
			margin: 0 0 5px 0;

			background: $gris;
			position: relative;
			&:before {
				padding-bottom: 56.25%;
				display: block;
				content: "";
			}
			img {
				display: block;
				width: 100%;
				height: auto;
				top: 0;
				left: 0;
				position: absolute;
			}
			.rectangle_blanc {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				background: rgba(black, 0.2);
			}
		}
		.grabcursor {
			flex: 0 0 auto;
			i,
			svg {
				color: black;
			}
		}
		.name {
			font-size: 11px;
			font-weight: 500;
			flex: 1;
			input {
				border: none;
				width: 100%;
				height: 20px;
				padding: 2px 5px;
				&:focus,
				&:hover {
					background: rgba(black, 0.05);
				}
			}
		}
		&.selected {
			opacity: 1;
			color: $rouge;
			font-weight: bold;
			.zone-image {
				outline: solid 3px $rouge;
			}
		}
		&.tomove {
			opacity: 1;
			color: $bleu;
			font-weight: bold;
			background: $bleuclair;
			border: solid 1px $bleu;
			border-radius: 2px;
			margin-bottom: 3px;
			padding: 4px 10px;
			input[type="text"] {
				background: transparent;
			}
		}
		&:hover {
			opacity: 1;
		}
		&.arecup {
			display: none;
		}
	}
	.v-context {
		border-radius: 2px;
		border: none;
		box-shadow: 3px 3px 10px rgba($bleu, 0.1);
		padding: 0;
		li {
			a {
				padding: 10px 20px;
				font-size: 11px;
				font-weight: 500;
				border-bottom: solid 2px $gris;
				cursor: pointer;
				transition: all 0.3s ease;
				&:hover {
					background: $bleuclair;
				}
			}
		}
	}
}
</style>
