<template>
	<div :class="classes" :type="type" :data-slide="slide" :data-composant="composant"
		:ref="`slide${slide}composant${composant}`"
		:style="`position: absolute; color: ${color}; text-align: ${align}; vertical-align: ${valign}; left: ${x}px; top: ${y}px; width: ${w}px; height: ${h}px; font-size: ${fontSize}pt; padding: ${margin}px; line-height: ${lineSpacing}`">
		{{ borderColor }}
		<table cellpadding="0" cellspacing="0">
			<tr v-for="(row, rowindex) in rows" :key="`row${rowindex}`">
				<td :style="`vertical-align: ${valign}; text-align: ${align}; border-color: ${borderColor}; width: ${Math.round(100 / cols)}%`"
					v-for="(col, colindex) in cols" :key="`line${colindex}`">
					<div v-if="content && content[rowindex] && content[rowindex][colindex]" contenteditable="true"
						@blur="updateTableContent($event.target.parentNode.parentNode.parentNode)"
						v-html="content[rowindex][colindex].text || ''" />
					<div v-else contenteditable="true" @blur="updateTableContent($event.target.parentNode.parentNode.parentNode)"></div>
				</td>
			</tr>
		</table>
		<div class="corner edge-top edge-left"></div>
		<div class="corner edge-top edge-right"></div>
		<div class="corner edge-bottom edge-right"></div>
		<div class="corner edge-bottom edge-left"></div>
	</div>
</template>
<script>
import interact from "interactjs";
import { mapMutations } from "vuex";
export default {
	name: "PPTXTABLEAU",
	components: {},
	props: {
		slide: Number,
		composant: Number,
		type: String,
		x: Number,
		y: Number,
		w: Number,
		h: Number,
		fill: String,
		fontSize: Number,
		lineSpacing: Number,
		align: String,
		valign: String,
		content: Array,
		bold: Boolean,
		color: String,
		borderColor: String,
		italic: Boolean,
		margin: Number,
		rows: Number,
		cols: Number,
		first_colonne_heading: Boolean,
		first_row_heading: Boolean,
		last_row_heading: Boolean,
		last_colonne_heading: Boolean,
	},
	data() {
		return {};
	},
	computed: {
		classes() {
			let classes = `pptx-tableau resizable draggable `;
			if (this.first_colonne_heading) {
				classes += "first_colonne ";
			}
			if (this.first_row_heading) {
				classes += "first_row ";
			}
			if (this.last_row_heading) {
				classes += "last_row ";
			}
			if (this.last_colonne_heading) {
				classes += "last_colonne ";
			}
			return classes;
		},
	},
	methods: {
		...mapMutations({
			updateTable: "updateTable",
		}),
		updateSize(el, sizes, slide, composant) {
			el.style.transform = "none";
			el.dataset.x = 0;
			el.dataset.y = 0;
			this.$store.dispatch("updateElementSize", { sizes, slide, composant });
		},
		updatePosition(el, slide, composant, startx, starty) {
			el.style.transform = "none";
			let sizes = {
				x: parseFloat(el.style.left),
				y: parseFloat(el.style.top),
				differencex: parseFloat(el.style.left) - startx,
				differencey: parseFloat(el.style.top) - starty,
			};
			this.$store.dispatch("updateElementPosition", { sizes, slide, composant });
		},
		updateTableContent(table) {
			let temp_content = [];
			for (let i = 1; i < this.rows + 1; i++) {
				let line_tab = [];
				for (let j = 1; j < this.cols + 1; j++) {
					let cell = table.querySelector(`tr:nth-child(${i}) td:nth-child(${j})`);
					if (cell != null) {
						line_tab.push({
							text: cell.innerText,
						});
					} else {
						line_tab.push({
							text: "",
						});
					}
				}
				temp_content.push(line_tab);
			}
			this.updateTable(temp_content);
		},
	},
	mounted() {
		let concatenate = `slide${this.slide}composant${this.composant}`;
		let el = this.$refs[concatenate];
		let position = {
			x: 0,
			y: 0,
			startx: 0,
			starty: 0,
		};
		interact(el)
			.resizable({
				margin: 5,
				edges: {
					top: ".edge-top",
					left: ".edge-left",
					bottom: ".edge-bottom",
					right: ".edge-right",
				},

				onmove: (event) => {
					let { x, y } = event.currentTarget.dataset;

					x = (parseFloat(x) || 0) + event.deltaRect.left;
					y = (parseFloat(y) || 0) + event.deltaRect.top;

					Object.assign(event.target.style, {
						width: `${event.rect.width}px`,
						height: `${event.rect.height}px`,
						transform: `translate(${x}px, ${y}px)`,
					});

					Object.assign(event.target.dataset, { x, y });
				},
				onend: (event) => {
					let { x, y } = event.currentTarget.dataset;
					let { width, height } = event.currentTarget.getBoundingClientRect();
					let { left, top } = event.currentTarget.style;
					let pLeft = parseFloat(x) + parseFloat(left);
					let pTop = parseFloat(y) + parseFloat(top);
					let sizes = {
						width,
						height,
						pLeft,
						pTop,
					};
					this.updateSize(event.currentTarget, sizes, this.slide, this.composant);
				},
			})
			.draggable({
				onstart: (event) => {
					position.x = parseFloat(event.currentTarget.style.left);
					position.y = parseFloat(event.currentTarget.style.top);
					position.startx = parseFloat(event.currentTarget.style.left);
					position.starty = parseFloat(event.currentTarget.style.top);
				},
				onmove: (event) => {
					position.x += event.dx;
					position.y += event.dy;
					event.currentTarget.style.left = `${position.x}px`;
					event.currentTarget.style.top = `${position.y}px`;
				},
				onend: (event) => {
					this.updatePosition(event.currentTarget, this.slide, this.composant, position.startx, position.starty);
				},
			});
	},
};
</script>

<style lang="scss">
.pptx-tableau {
	line-height: 1.4;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	table {
		width: 100%;
		height: 100%;
		max-height: 100%;
		background: #fbfcfc;
		tr {
			td {
				border: solid 2px #ffffff;
				cursor: text;
				user-select: $rougeclair;
				div {
					display: inline-block;
					width: 100%;
					padding: 10px;
					font-weight: inherit;
				}
			}
		}
	}
	&.first_colonne {
		table {
			tr {
				td:first-child {
					font-weight: bold;
				}
			}
		}
	}
	&.last_colonne {
		table {
			tr {
				td:last-child {
					font-weight: bold;
				}
			}
		}
	}
	&.first_row {
		table {
			tr:first-child {
				td {
					font-weight: bold;
				}
			}
		}
	}
	&.last_row {
		table {
			tr:last-child {
				td,
				td > * {
					font-weight: bold;
				}
			}
		}
	}
}
.pptx-element.selected {
	.pptx-tableau {
		background: rgba(white, 0.1);
		backdrop-filter: blur(2px);
		table {
			width: calc(100% - 20px);
			margin: 10px;
		}
	}
}
</style>
