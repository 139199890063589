<template>
	<div :class="classes" :type="type" :data-slide="slide" :data-composant="composant" :data-sommaire="useForSommaire"
		:ref="`slide${slide}composant${composant}`"
		:style="`color: ${color}; transform: rotateZ(${rotate}deg); position: absolute; vertical-align: ${valign}; left: ${x}px; top: ${y}px; width: ${w}px; height: ${h}px; font-size: ${fontSize}pt; padding: ${margin}px; line-height: ${lineSpacing};`">
		<!-- <div class="content" v-html="htmlcontent" contenteditable="true" @blur="changeContent($event, slide, composant)"></div> -->
		<!-- <div class="content" v-if="lien === undefined || lien.url === ''" contenteditable @blur="changeContent($event, slide, composant)">{{ content }}</div>
		<div class="content" v-else>
			<a class="textelien" :href="lien.url" target="_blank" contenteditable @blur="changeContent($event, slide, composant)">{{ content }}</a>
		</div> -->

		<toolbox :data-slide="slide" :data-composant="composant" v-if="current" />
		<div class="content" v-html="content" contenteditable @blur="changeContent($event, slide, composant)" />

		<div class="corner edge-top edge-left"></div>
		<div class="corner edge-top edge-right"></div>
		<div class="corner edge-bottom edge-right"></div>
		<div class="corner edge-bottom edge-left"></div>
	</div>
</template>
<script>
import interact from "interactjs";
import toolbox from "../pptx-library/toolbox.vue";

export default {
	name: "PPTXTEXT",
	components: { toolbox },
	props: {
		slide: Number,
		composant: Number,
		type: String,
		x: Number,
		y: Number,
		w: Number,
		h: Number,
		fontSize: Number,
		lineSpacing: Number,
		align: String,
		valign: String,
		content: String,
		bold: Boolean,
		color: String,
		rotate: Number,
		italic: Boolean,
		margin: Number,
		useForSommaire: Boolean,
		isUpperCase: Boolean,
		lien: Object,
		fill: String,
		current: Boolean,
	},
	data() {
		return {};
	},
	methods: {
		changeContent(e, slide, composant) {
			let newtext = e.currentTarget.innerHTML;
			newtext.replace("<br />", "\n");
			this.$store.dispatch("updateContent", { newtext, slide, composant });
		},
		editContent(e) {
			console.log(e);
		},
		updateSize(el, sizes, slide, composant) {
			el.style.transform = "none";

			el.dataset.x = 0;
			el.dataset.y = 0;
			this.$store.dispatch("updateElementSize", { sizes, slide, composant });
		},
		updatePosition(el, slide, composant, startx, starty) {
			el.style.transform = "none";

			let sizes = {
				x: parseFloat(el.style.left),
				y: parseFloat(el.style.top),
				differencex: parseFloat(el.style.left) - startx,
				differencey: parseFloat(el.style.top) - starty,
			};
			this.$store.dispatch("updateElementPosition", { sizes, slide, composant });
		},
	},
	computed: {
		htmlcontent() {
			return this.content.replace("\n", "<br />");
		},
		classes() {
			let classes = `pptx-text resizable draggable ${this.valign} `;
			// if (this.bold) {
			// 	classes += "bold ";
			// }
			// if (this.italic) {
			// 	classes += "italic ";
			// }
			if (this.isUpperCase) {
				classes += "uppercase ";
			}
			return classes;
		},
	},
	mounted() {
		let concatenate = `slide${this.slide}composant${this.composant}`;
		let selected_items;
		let el = this.$refs[concatenate];
		let position = {
			x: 0,
			y: 0,
			startx: 0,
			starty: 0,
		};

		interact(el)
			.resizable({
				margin: 6,
				edges: {
					top: ".edge-top",
					left: ".edge-left",
					bottom: ".edge-bottom",
					right: ".edge-right",
				},

				onmove: (event) => {
					let { x, y } = event.currentTarget.dataset;

					x = (parseFloat(x) || 0) + event.deltaRect.left;
					y = (parseFloat(y) || 0) + event.deltaRect.top;

					Object.assign(event.target.style, {
						width: `${event.rect.width}px`,
						height: `${event.rect.height}px`,
						transform: `translate(${x}px, ${y}px)`,
					});

					Object.assign(event.target.dataset, { x, y });
				},
				onend: (event) => {
					let { x, y } = event.currentTarget.dataset;
					let { width, height } = event.currentTarget.getBoundingClientRect();
					let { left, top } = event.currentTarget.style;
					let pLeft = parseFloat(x) + parseFloat(left);
					let pTop = parseFloat(y) + parseFloat(top);
					let sizes = {
						width,
						height,
						pLeft,
						pTop,
					};
					this.updateSize(event.currentTarget, sizes, this.slide, this.composant);
				},
			})
			.draggable({
				onstart: (event) => {
					selected_items = document.querySelectorAll(".pptx-element.selected[data-type='text']");
					position.x = parseFloat(event.currentTarget.style.left);
					position.y = parseFloat(event.currentTarget.style.top);
					position.startx = parseFloat(event.currentTarget.style.left);
					position.starty = parseFloat(event.currentTarget.style.top);
				},
				onmove: (event) => {
					if (selected_items.length === 1) {
						position.x += event.dx;
						position.y += event.dy;
						event.currentTarget.style.left = `${position.x}px`;
						event.currentTarget.style.top = `${position.y}px`;
					} else if (selected_items.length > 1) {
						position.x += event.dx;
						position.y += event.dy;
						selected_items.forEach((el) => {
							el.firstChild.style.left = `${parseFloat(el.firstChild.style.left) + event.dx}px`;
							el.firstChild.style.top = `${parseFloat(el.firstChild.style.top) + event.dy}px`;
						});
					}
				},
				onend: (event) => {
					this.updatePosition(event.currentTarget, this.slide, this.composant, position.startx, position.starty);
				},
			});
	},
};
</script>

<style lang="scss">
.pptx-text {
	line-height: 1.4;
	overflow: visible;
	display: flex;
	flex-direction: column;
	&.top {
		justify-content: flex-start;
	}
	&.middle {
		justify-content: center;
	}
	&.bottom {
		justify-content: flex-end;
	}
	.content {
		outline: none;
		cursor: text;
		width: 100%;
		font-weight: inherit;
		touch-action: none;
		pointer-events: none;
		padding: 3pt 7.2pt;
		white-space: break-spaces;
		word-break: break-word;
		a {
			color: inherit;
			font-weight: inherit;
			font-size: inherit;
		}
		u {
			text-decoration: underline;
		}
		p {
			font-weight: inherit;
			line-height: inherit;
		}
		i {
			font-weight: inherit;
			line-height: inherit;
		}
		strong,
		b {
			font-weight: bold;
			line-height: inherit;
		}
		u {
			text-decoration: underline;
			font-weight: inherit;
		}
		ul {
			margin: 0 0 12pt 0;
			li {
				position: relative;
				padding-left: 16px;

				&:before {
					content: "ᐳ";
					left: 0;
					position: absolute;
				}
			}
		}
		ol {
			margin: 8pt 0 0;
			li {
				padding-left: 16px;
				counter-increment: section;
				&:before {
					content: counter(section) ".";
					left: 0;
					position: absolute;
				}
				&::marker {
					text-indent: 16px !important;
				}
			}
		}
		blockquote {
			color: #e30613;
			font-size: 8;
			font-style: italic;
			font-weight: bold;
			text-align: center;
		}
		h1 {
			font-size: 18pt;
			line-height: inherit;
			font-weight: normal;
		}
		h2 {
			font-size: 13pt;
			line-height: inherit;
			font-weight: normal;
		}
		h3 {
			font-size: 11pt;
			line-height: inherit;
			font-weight: normal;
		}

		a {
			color: inherit;
			text-decoration: underline;
		}
	}
	&.bold > *,
	strong > * {
		font-weight: bold;
	}
	.ql-align-center {
		text-align: center;
	}
	.ql-align-right {
		text-align: right;
	}
	&.italic {
		font-style: italic;
	}
	&.uppercase > * {
		text-transform: uppercase;
	}
	&:hover,
	&.selected {
		outline: dotted 2px rgba(black, 0.1);
	}
	
}

.pptx-element.selected {
	.pptx-text {
		background: rgba(white, 0.1);
		backdrop-filter: blur(2px);

		.content {
			touch-action: none;
			pointer-events: none;
		}
	}
}
</style>
