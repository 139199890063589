<template>
	<section id="navbar">
		<div class="center">
			<div class="composants_menu">
				<div class="tools">
					<div class="pointer" @click="minusZoom"><i class="fal fa-minus"></i></div>
					<div class="zoom">{{ Math.round($store.state.properties.zoom * 100) }}%</div>
					<div class="pointer" @click="upZoom"><i class="fal fa-plus"></i></div>
				</div>
				<div class="actionbutton" @click="addSlideVierge()">
					<i class="fal fa-layer-plus"></i>
					<span class="tooltip">Ajouter une diapositive vierge</span>
				</div>
				<div class="actionbutton" @click="addText()" v-if="currentDiapositive != null">
					<i class="fal fa-text"></i>
					<span class="tooltip">Ajouter une zone de texte</span>
				</div>
				<div class="actionbutton" @click="addShape()" v-if="currentDiapositive != null">
					<i class="fal fa-draw-square"></i>
					<span class="tooltip">Ajouter une forme</span>
				</div>
				<div class="actionbutton" @click="addImage()" v-if="currentDiapositive != null">
					<i class="fal fa-image"></i>
					<span class="tooltip">Ajouter une image</span>
				</div>
				<div class="actionbutton" @click="addGraph()" v-if="currentDiapositive != null">
					<i class="fal fa-chart-pie"></i>
					<span class="tooltip">Ajouter un graphique</span>
				</div>
				<div class="actionbutton" @click="addTable()" v-if="currentDiapositive != null">
					<i class="fal fa-table"></i>
					<span class="tooltip">Ajouter un tableau</span>
				</div>
				<!-- <div class="actionbutton" @click="addListe()" v-if="currentDiapositive != null">
					<i class="fal fa-list"></i>
					<span class="tooltip">Ajouter un liste</span>
				</div> -->
				<div class="actionbutton" @click="addVideo()" v-if="currentDiapositive != null">
					<i class="fal fa-video"></i>
					<span class="tooltip">Ajouter une vidéo</span>
				</div>
			</div>
		</div>
		<div class="droite">
			<div class="export" @click="exportPPT()">
				<span v-if="!generationEnCours"><span>Aperçu dans Powerpoint</span></span>
				<span v-else><i class="fal fa-spinner-third fa-spin"></i><span>Génération...</span></span>
			</div>
			<div class="save" @click="saveModeleFromEditor()">
				<span v-if="!$store.state.savemodeleeditor"> <i class="fal fa-save"></i>
					<span>Sauvegarder</span></span>
				<span v-else><i class="fal fa-spinner-third fa-spin"></i><span>Sauvegarde...</span></span>
			</div>
		</div>
	</section>
</template>
<script>
import { Dialog, Notify } from "vant";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
	name: "",
	components: {},
	data() {
		return {
			generation_en_cours: false,
		};
	},
	computed: {
		versions() {
			let versions = [];
			if (this.presentation.versions) {
				this.presentation.versions.forEach((el, index) => {
					let filename = el.filename.split("_date_");
					if (filename[0] && filename[1]) {
						let nom_fichier = filename[0].replaceAll("_", " ");
						let ladate = filename[1].replaceAll("_", " ");
						ladate = ladate.replace(".json", "");
						versions.push({
							id: el.id,
							index,
							nom_fichier,
							ladate,
						});
					}
				});
			}
			return versions;
		},
		...mapGetters({
			currentUser: "currentUser",
			presentation: "currentPresentation",
			currentDiapositive: "currentDiapositive",
			currentComposant: "currentComposant",
			generationEnCours: "generationEnCours",
		}),
	},
	methods: {
		addSlideVierge() {
			this.$store.dispatch("addSlide", {
				modele: "vierge",
			});
		},
		upZoom() {
			this.$store.commit("zoomplus");
		},
		minusZoom() {
			this.$store.commit("zoommoins");
		},
		restore(payload) {
			Dialog.confirm({
				title: "Procédure de restauration",
				message: "Voulez-vous restaurer la présentation à une version antérieure ?",
				confirmButtonText: "Oui",
				cancelButtonText: "Annuler",
			}).then(() => {
				this.switchPresentationVersion(payload);
			});
		},
		openVersion() {
			alert("Gestion de la restauration");
		},
		...mapMutations({
			showSaveModele: "showSaveModele",
			showChooseSlide: "showChooseSlide",
		}),
		...mapActions({
			showShare: "showShare",
			addNewSlide: "addSlide",
			addText: "addText",
			addShape: "addShape",
			addVideo: "addVideo",
			addImage: "addImage",
			addListe: "addListe",
			addTable: "addTable",
			addGraph: "addGraph",
			changePresentationName: "changePresentationName",
			saveModeleFromEditor: "saveModeleFromEditor",
			exportPPT: "exportPPTFromModeleEditor",
			modifyStatut: "modifyStatut",
			showStatutBox: "showStatutBox",
			switchPresentationVersion: "switchPresentationVersion",
		}),
		changeNomPresentation(e) {
			this.titre = e.target.innerHTML;
		},
	},
	mounted() {},
};
</script>

<style lang="scss" scoped>
#navbar {
	//position: fixed;
	top: 0px;
	background: white;
	display: flex;
	justify-content: space-between;
	padding: 5px 15px;
	box-shadow: 0px 0px 10px rgba(black, 0.1);
	z-index: 10;
	align-items: center;
	flex-wrap: wrap;
	position: sticky;
	gap: 10px;
	.center {
		display: flex;
		grid-area: bar;
		flex: 1;
		align-items: center;
		justify-content: space-between;
		.composants_menu {
			padding: 6px 0px;
			background: $gris;
			border-radius: 20px;
			display: flex;
			align-items: center;
			.tools {
				background: white;
				display: flex;
				position: sticky;
				font-size: 11px;
				font-weight: bold;
				align-items: center;
				border-radius: 20px;
				z-index: 99;
				margin-left: 10px;
				margin-right: 20px;

				.pointer {
					padding: 10px 0 10px 20px;
					cursor: pointer;
					&:hover {
						color: $rouge;
					}
					&:last-child {
						padding: 10px 20px 10px 0px;
					}
				}
				.zoom {
					font-weight: 500;
					padding: 10px 15px;
				}
			}
		}
		.action_menu {
			padding: 6px 0px;
			background: $gris;
			border-radius: 20px;
			display: flex;
			align-items: center;
		}
		.actionbutton {
			font-size: 20px;
			padding: 8px 12px;
			transition: all 0.2s ease;
			cursor: pointer;
			display: flex;
			align-items: center;
			border-left: solid 1px $gris2;
			position: relative;
			&.separator {
				border-left: solid 4px white;
			}
			&:first-of-type {
				border: none;
			}
			span.tooltip {
				font-size: 11px !important;
				padding: 0 6px;
				display: none;
			}
			select {
				background: transparent;
				border: none;
				font-size: 11px !important;
				padding: 0 10px;
				display: none;
			}
			.versions {
				display: none;
				position: absolute;
				top: 99%;
				font-size: 12px;
				z-index: 999;
				background: white;
				left: 0;
				width: 220px;
				transform: translateX(-50%);
				border-radius: 10px;
				box-shadow: 2px 2px 8px rgba(black, 0.06);
				.version {
					display: flex;
					flex-direction: column;
					padding: 10px 16px;
					align-items: flex-start;
					border-bottom: solid 1px rgba(black, 0.06);
					transition: all 0.3s ease;
					overflow: hidden;
					.date {
						font-weight: 500;
						color: black;
						margin-bottom: 3px;
						font-size: 10px;
						&::first-letter {
							text-transform: uppercase;
						}
					}
					.nom {
						font-size: 9px;
						font-weight: 300;
						color: lighten(black, 40%);
					}
					.restore {
						display: none;
						background: $rouge;
						color: white;
						font-weight: 500;
						margin: 10px 0 5px 0;
						border-radius: 10px;
						font-size: 9px;
						padding: 4px 6px;
					}
					&:last-child {
						border: none;
					}
					&:hover {
						background: $rougeclair;
						.restore {
							display: inline-block;
							animation: appearFromLeft 0.3s ease-out backwards;
						}
					}
				}
			}
			&:hover {
				color: $rouge;

				span.tooltip,
				select {
					display: block;
					animation: appearFromLeft 0.3s ease-out forwards;
					background: $gris;
					position: absolute;
					padding: 13px 20px;
					top: 100%;
					font-weight: 500;
					left: -20px;
					z-index: 999;
					border-radius: 4px;
					width: auto;
					text-align: right;
					white-space: nowrap;
					box-shadow: 0px 4px 4px rgba(black, 0.1);
				}
				.versions {
					display: block;
					animation: appearFromLeft 0.3s ease-out forwards;
				}
			}
		}
	}
	.droite {
		flex: 0 0 auto;
		display: flex;
		grid-area: bouton;
		gap: 20px;
		.export {
			font-size: 10px;
			text-transform: uppercase;
			letter-spacing: 0.02em;
			font-weight: bold;
			padding: 10px;
			display: flex;
			align-items: center;
			cursor: pointer;
			transition: all 0.3s ease;
			justify-content: center;
			span {
				display: flex;
				align-items: center;
				font-weight: 500;
				i {
					font-size: 22px;
					margin-right: 8px;
				}
			}
			&:hover {
				opacity: 0.7;
			}
		}
		.save {
			font-size: 11px;
			text-transform: uppercase;
			letter-spacing: 0.02em;
			font-weight: bold;
			padding: 10px 20px;
			border-radius: 30px;
			display: flex;
			align-items: center;
			background: $rouge;
			color: white;
			cursor: pointer;
			transition: all 0.3s ease;
			justify-content: center;
			span {
				display: flex;
				align-items: center;
				font-weight: 500;
				i {
					font-size: 22px;
					margin-right: 8px;
				}
			}
			&:hover {
				animation: hand 1s alternate-reverse infinite;
			}
		}
	}
}
#versions {
}
</style>
