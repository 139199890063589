<template>
	<div id="chooseimage" v-if="showOverlayChooseImage">
		<div class="box">
			<div class="closeOverlay" @click="showChooseImage()"><i class="fal fa-times"></i></div>
			<h3>Sélectionner une image dans la bibliothèque</h3>
			<div class="gallery">
				<div
					:class="image.id === current ? 'current thumb' : 'thumb'"
					v-for="image in images"
					:key="image.id"
					@click="setElementSouhaite(image.id, image.url, image.data.width, image.data.height)"
				>
					<img
						loading="lazy"
						v-if="image.data && image.data.sizes && image.data.sizes.thumbnail"
						:src="`https://mediabase.lezardscreation.fr/wp-content/uploads/${image.data.sizes.thumbnail.file}`"
					/>
					<img loading="lazy" v-else :src="`${image.url}`" />
					<div
						class="delete"
						@click="deleteMedia(image.id)"
						v-if="$store.state.user.fields.role === 'super_administrateur' || $store.state.user.fields.role === 'administrateur'"
					>
						<i class="fas fa-times"></i>
					</div>
				</div>
			</div>
			<div class="buttons">
				<button class="create" @click="updateElementImage(el_souhaite)">
					<span v-if="current > 0">Insérer</span>
				</button>
			</div>
		</div>
	</div>
</template>
<script>
import { Dialog } from "vant";
import { mapGetters, mapActions, mapMutations } from "vuex";
import process from "process";

export default {
	name: "createZone",
	components: {},
	data() {
		return {
			current: 0,
			el_souhaite: {
				lien: "",
				w: 0,
				h: 0,
				is_dynamic: false,
				dynamic_code: "",
				data: null,
			},
		};
	},
	computed: {
		...mapGetters({
			images: "images_delagalerie",
			showOverlayChooseImage: "showOverlayChooseImage",
		}),
	},
	methods: {
		setElementSouhaite(id, url, width, height) {
			this.current = id;
			this.el_souhaite = {
				lien: url,
				w: width,
				h: height,
				is_dynamic: false,
				dynamic_code: "",
				data: null,
			};
		},
		...mapActions({
			deleteMedia: "deleteMedia",
		}),
		...mapMutations({
			updateElementImage: "updateElementImage",
			showChooseImage: "showChooseImage",
		}),
	},
};
</script>

<style lang="scss" scoped>
#chooseimage {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: rgba($gris, 0.97);
	backdrop-filter: blur(20px) saturate(20);
	z-index: 99;
	display: flex;
	align-items: flex-start;
	justify-content: flex-end;
	border-radius: 0;
	.box {
		padding: 80px 30px 80px;
		background: white;
		box-shadow: 2px 3px 20px rgba($black, 0.1);
		font-size: 12px;
		width: calc(100vw - 200px);
		animation: appearFromRight 0.5s ease backwards;
		animation-delay: 0.2s;
		color: rgba(black, 0.7);
		position: relative;
		height: 100%;
		display: flex;
		align-items: stretch;
		flex-wrap: wrap;
		gap: 30px;
		.closeOverlay {
			position: absolute;
			width: 50px;
			height: 50px;
			top: 0;
			right: 0;
			background: white;
			border-radius: 0 0 0 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			font-size: 20px;
			cursor: pointer;
			transition: all 0.3s ease;
			box-shadow: inset 2px -2px 4px rgba(black, 0.05);
			background: rgba($rouge, 0.1);
			color: $rouge;
			&:hover {
				box-shadow: inset 2px -2px 10px rgba($rouge, 0.2);
				background: $rouge;
				color: white;
			}
		}
		p {
			font-size: 13px;
			line-height: 20px;
			font-weight: 300;
			max-width: 60%;
			strong {
				font-weight: 500;
			}
		}
		h3 {
			font-weight: 600;
			margin-bottom: 16px;
			font-size: 20px;
			flex: 0 0 100%;
			position: absolute;
			top: 30px;
		}
		.gallery {
			display: flex;
			gap: 20px;
			flex: 1;
			background: $gris;
			padding: 30px;
			border-radius: 20px;
			overflow-y: auto;
			margin-bottom: 10px;
			height: 100%;
			background-image: url("../assets/img/fond-alveole.png");
			background-position: top left;
			background-repeat: repeat;
			flex-wrap: wrap;
			align-items: flex-start;
			justify-content: center;
			align-content: flex-start;
			.thumb {
				padding: 5px;
				display: flex;
				background: $gris2;
				align-items: center;
				justify-content: center;
				transition: all 0.2s ease;
				position: relative;
				overflow: hidden;
				flex: 0 0 auto;
				border: solid 2px transparent;
				width: 150px;
				height: 150px;
				&:hover {
					transform: translateY(2px);
					filter: drop-shadow(4px 4px 16px rgba(black, 0.1));
				}
				.delete {
					display: none;
				}
				&.current {
					border: solid 2px $rouge;
					.delete {
						position: absolute;
						top: 0;
						display: block;
						right: 0;
						background: $rouge;
						color: $rougeclair;
						cursor: pointer;
						padding: 8px 8px 12px 14px;
						box-shadow: 0px 0px 6px rgba($rouge, 0.4);
						border-radius: 0 0 0 20px;
						&:hover {
							background: black;
							color: white;
						}
					}
				}
			}
			&:after {
				content: "";
				height: 10px;
				width: 100%;
				display: block;
				grid-column: span 3;
			}
		}
		.buttons {
			display: flex;
			justify-content: flex-end;
			position: absolute;
			bottom: 20px;
			right: 30px;
			button {
				background: $rouge;
				display: flex;
				align-items: center;
				i {
					margin-left: 10px;
					transform: translateY(2px);
				}
				&:hover {
					animation: hand 1s ease-in-out alternate-reverse infinite;
					background: darken($rouge, 5%);
				}
			}
		}
	}
}
</style>
