<template>
	<section id="squelette">
		<div class="les-squelettes">
			<div class="gallery">
				<Header />
				<div class="thebarre">
					<h4>Recherche par mot clé</h4>
					<div class="search">
						<input type="search" v-model="search_squelette" placeholder="Mots clés" @keyup="filterModeles()" />
						<button type="submit"><i class="fal fa-search"></i></button>
					</div>
				</div>
				<div v-if="$store.state.squelettes_ready">
					<div
						:class="`${squelette.classes} squelette ligne
						all-prestations all-filieres all-types all-geographie all-procedures all-toolbox all-materiel all-digitalisation all-formation all-qualite
						all-groupe_mauffrey
					`"
						v-for="(squelette, index) in squelettes_treated"
						:key="squelette.id"
					>
						<div class="numero">{{ index + 1 }}</div>
						<div class="preview">
							<img :src="squelette.fields.preview_url" v-if="squelette.fields.preview_url" />
							<img src="https://mediabase.lezardscreation.fr/wp-content/uploads/image-vierge-1.png" v-else />
						</div>
						<div class="nom">
							<h2>
								{{ squelette.fields.nom }} <span :class="`statut ${squelette.fields.status}`">{{ squelette.fields.status }}</span>
								<div class="langue">
									<i class="fa-regular fa-globe"></i>
									<span>{{ squelette.fields.langue }}</span>
								</div>
							</h2>

							<div class="tags" v-if="squelette.fields.tags">
								<i class="fa-regular fa-tags"></i>
								{{ squelette.fields.tags }}
							</div>
							<div class="composition" v-if="squelette.fields.composition_new">
								<i class="fa-solid fa-rectangle-vertical-history"></i>
								Composé de {{ JSON.parse(squelette.fields.composition_new).length }} slides
							</div>
							<div class="author"><i class="fa-regular fa-user"></i>Créé par {{ squelette.fields.nom_author[0] }}</div>
							<div class="author"><i class="fa-regular fa-user"></i>Importé {{ squelette.fields.useCounter }} fois dans les drafts</div>
						</div>
						<div class="actions">
							<div class="duplicate" @click="duplicateSquelette(squelette.id)"><i class="fa-regular fa-copy"></i></div>
							<router-link :to="`/squelette/${squelette.id}`"><i class="fa-regular fa-edit"></i></router-link>
							<div class="delete" @click="deleteSquelette(squelette.id)"><i class="fa-regular fa-trash"></i></div>
						</div>
					</div>
				</div>
				<div v-else class="chargement">
					<i class="fad fa-spinner-third fa-spin"></i>
					<div>
						<strong>Nous sommes en train de charger les squelettes</strong>
						Le chargement des squelettes peut durer un certain temps car nous devons au préalable charger tous les modèles. La vitesse de téléchargement
						dépend essentiellement de la vitesse de votre connexion.
						<br />Veuillez patienter quelques instants.
					</div>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
import Header from "../components/Header";
import { mapGetters, mapActions, mapMutations } from "vuex";
import process from "process";
import moment from "moment";
export default {
	name: "Squelettes",
	components: { Header },
	data() {
		return {
			vierge: "",
			search_squelette: "",
			selected_type: "all-types",
			selected_filiere: "all-filieres",
			selected_prestation: "all-prestations",
			selected_geographie: "all-geographie",
			selected_procedures: "all-procedures",
			selected_toolbox: "all-toolbox",
			selected_materiel: "all-materiel",
			selected_digitalisation: "all-digitalisation",
			selected_formation: "all-formation",
			selected_qualite: "all-qualite",
			selected_groupe: "all-groupe_mauffrey",
		};
	},
	methods: {
		...mapActions({
			getSquelettes: "getSquelettes",
			exportPPTModele: "exportPPTModele",
			duplicateSquelette: "duplicateSquelette",
		}),
		...mapMutations({
			changeModeleName: "changeModeleName",
			deleteSquelette: "deleteSquelette",
		}),
		filterModeles() {
			let gallery_items = document.querySelectorAll(".ligne");
			if (this.search_squelette.length > 3) {
				gallery_items.forEach((el) => {
					let mots_cles = `${el.querySelector(".nom h2").innerText}`;
					if (el.querySelector(".nom .categories")) {
						mots_cles += `${el.querySelector(".nom .categories").innerText}`;
					}
					if (el.querySelector(".nom .tags")) {
						mots_cles += `${el.querySelector(".nom .tags").innerText}`;
					}
					if (
						el.classList.contains(this.selected_type) &&
						el.classList.contains(this.selected_filiere) &&
						el.classList.contains(this.selected_prestation) &&
						el.classList.contains(this.selected_geographie) &&
						el.classList.contains(this.selected_procedures) &&
						el.classList.contains(this.selected_toolbox) &&
						el.classList.contains(this.selected_materiel) &&
						el.classList.contains(this.selected_digitalisation) &&
						el.classList.contains(this.selected_formation) &&
						el.classList.contains(this.selected_qualite) &&
						el.classList.contains(this.selected_groupe) &&
						mots_cles
							.toString()
							.toLowerCase()
							.normalize("NFD")
							.replace(/[\u0300-\u036f]/g, "")
							.replace(/'/g, "")
							.replace("’", "")
							.includes(
								this.search_squelette
									.toString()
									.toLowerCase()
									.normalize("NFD")
									.replace(/[\u0300-\u036f]/g, "")
									.replace(/'/g, "")
									.replace("’", "")
							)
					) {
						el.classList.remove("hidden");
					} else {
						el.classList.add("hidden");
					}
				});
			} else {
				gallery_items.forEach((el) => {
					if (
						el.classList.contains(this.selected_type) &&
						el.classList.contains(this.selected_filiere) &&
						el.classList.contains(this.selected_prestation) &&
						el.classList.contains(this.selected_geographie) &&
						el.classList.contains(this.selected_procedures) &&
						el.classList.contains(this.selected_toolbox) &&
						el.classList.contains(this.selected_materiel) &&
						el.classList.contains(this.selected_digitalisation) &&
						el.classList.contains(this.selected_formation) &&
						el.classList.contains(this.selected_qualite) &&
						el.classList.contains(this.selected_groupe)
					) {
						el.classList.remove("hidden");
					} else {
						el.classList.add("hidden");
					}
				});
			}
		},
	},
	computed: {
		...mapGetters({
			currentUser: "currentUser",
			prestations: "prestations",
			filieres: "filieres",
			categories: "categories",
			squelettesPresentations: "squelettesPresentations",
			squelettesSlidesVierges: "squelettesSlidesVierges",
			squelettesSlides: "squelettesSlides",
			squelettesGroupesSlides: "squelettesGroupesSlides",
			squelettes: "squelettes",
		}),
		squelettes_treated() {
			if(this.currentUser.fields.role.includes("administrateur")) {
				return this.squelettes.filter(el => el.fields.status === "visible" || el.fields.status === "brouillon" || el.fields.author[0] === this.currentUser.id || el.fields.proposer_comme_public === true);
			} else {
				return this.squelettes.filter(el => el.fields.status === "prive" && el.fields.author[0] === this.currentUser.id);
			}
			//return  this.squelettes;
		}
	},
	mounted() {},
};
</script>
<style lang="scss" scoped>
#squelette {
	background: white;
	background-image: url("../assets/img/fondruche3-bis.jpg");
	background-position: bottom;
	background-size: contain;
	background-repeat: no-repeat;
	height: 100vh;

	background-attachment: fixed;
	.les-squelettes {
		display: flex;
		padding: 0;
		align-items: stretch;
		flex-wrap: wrap;
		&:after {
			content: "";
			height: 10px;
			width: 100%;
			display: block;
			grid-column: span 3;
		}
		.thebarre {
			margin: 0;
			display: flex;
			flex-direction: column;
			gap: 10px;
			padding: 10px 30px;
			max-width: 400px;
			-webkit-overflow-scrolling: touch;
			overscroll-behavior: none;

			h4 {
				font-weight: 500;
				margin-bottom: 2px;
				font-size: 12px;
				top: 30px;
			}
			.radios {
				display: flex;
				flex-direction: column;
				margin-bottom: 12px;
				position: relative;
				padding: 16px 10px 4px;
				border: solid 1px darken($rougeclair, 6%);
				border-radius: 6px;
				legend {
					position: absolute;
					top: 0;
					left: 10px;
					font-weight: 500;
					color: $rouge;
					font-size: 12px;
					background: $rougeclair;
					padding: 3px 10px;
					transform: translateY(-50%);
					border: solid 1px $rougeclair;
					border-radius: 6px;
				}
				.radio {
					margin-bottom: 6px;
					display: flex;
					gap: 10px;
					align-items: center;
					font-size: 12px;
					font-weight: 500;
					cursor: pointer;
					color: lighten(black, 20%);
					transition: all 0.3s ease;
					&:hover {
						color: black;
					}
					input[type="radio"]:checked {
						background: $rouge;
					}
				}
			}
			select {
				width: auto;
				background: white;
				height: 28px;
				border: none;
				padding: 0 10px;
				font-size: 12px;
				font-weight: normal;
				transition: all 0.3s ease;
				outline: none;
			}
			.search {
				width: 100%;
				background: white;
				height: 40px;
				padding: 0;
				border: none;
				border-bottom: solid 1px black;
				margin-bottom: 30px;
				font-size: 13px;
				appearance: none;
				font-weight: 500;
				transition: all 0.3s ease;
				outline: none;
				display: flex;
				align-items: center;
				input {
					flex: 1;
					appearance: none;
					border: none;
					height: 40px;
					background: transparent;
					padding: 0 40px 0 0;
					font-size: 12px;
				}
				button {
					background: none;
					color: rgba(black, 0.5);
					padding: 0 0 0 10px;
					margin: 0;
					flex: 0 0 auto;
					font-size: 13px;
				}
			}
		}
		.gallery {
			display: block;
			flex: 1;
			padding-bottom: 100px;
			.ligne {
				display: flex;
				position: relative;
				align-items: flex-start;
				padding: 20px 30px 30px 30px;
				width: 100%;
				position: relative;
				transition: all 0.3s ease;
				background: white;
				z-index: 1;
				border-radius: 5px;
				&:hover {
					z-index: 999;
					background: lighten(slategray, 56%);
				}
				&:nth-child(odd) {
					background: lighten(slategray, 48%);
					&:hover {
						z-index: 999;
					}
				}
				.langue {
					color: $vert;
					background: $vertclair;
					font-size: 10px;
					padding: 2px 4px;
					border-radius: 4px;
					display: flex;
					align-items: center;
					margin-left: 10px;
					i,
					svg {
						margin-right: 6px;
					}
				}
				.numero {
					position: absolute;
					top: 10px;
					left: 25px;
					font-size: 11px;
					font-weight: 500;
					z-index: 99;
					background: #444;
					color: $rougeclair;
					width: 20px;
					height: 20px;
					border-radius: 50%;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				.preview {
					width: 120px;
					overflow: hidden;
					border-radius: 4px;
					border: solid 2px white;
					box-shadow: 4px 4px 10px rgba(black, 0.1);
					flex: 0 0 auto;
					position: relative;
					img {
						width: 100%;
						z-index: 3;
						aspect-ratio: 16/9;
						object-fit: cover;
					}
				}
				.nom {
					padding: 0px 20px;
					flex: 1;
					h2 {
						font-size: 16px;
						line-height: 20px;
						font-weight: 500;
						margin-bottom: 4px;
						display: flex;
						align-items: center;
						.statut {
							margin-left: 8px;
							font-size: 10px;
							padding: 2px 6px;
							text-transform: capitalize;
							border-radius: 4px;
							&.visible {
								color: $vert;
								background: $vertclair;
							}
							&.brouillon {
								color: $jaune;
								background: $jauneclair;
							}
						}
					}

					.author,
					.composition,
					.last_modified,
					.skeleton {
						color: slategray;
						font-size: 10px;
						padding: 3px 0;
						display: flex;
						align-items: center;
						i,
						svg {
							margin-right: 6px;
						}
					}
					.categories {
						display: flex;
						align-items: center;
						gap: 2px;
						color: $vertfonce;
						font-size: 10px;
						margin-bottom: 4px;
						i,
						svg {
							transform: translateY(1px);
						}
						span.tag {
							padding: 0.3em 0.6em;
							font-size: 10px;
							color: $vertfonce;
							border-radius: 2px;
							background: rgba($vertfonce, 0.05);
						}
					}
					.tags {
						display: flex;
						align-items: center;
						gap: 2px;
						color: $rouge;
						font-size: 10px;
						margin-bottom: 4px;
						i,
						svg {
							transform: translateY(1px);
						}
						span.tag {
							padding: 0.3em 0.6em;
							font-size: 10px;
							color: $rouge;
							border-radius: 2px;
							background: $rougeclair;
						}
					}
				}
				.actions {
					flex: 0 0 auto;
					display: flex;
					align-content: center;
					align-self: center;
					gap: 10px;
					a {
						width: 30px;
						height: 30px;
						background: $bleuclair;
						color: $bleu;
						border-radius: 50%;
						display: flex;
						justify-content: center;
						align-items: center;
						transition: all 0.3s ease;
						cursor: pointer;
						&:hover {
							background: $bleu;
							color: $bleuclair;
							transform: translateY(3px);
						}
					}
					.duplicate {
						width: 30px;
						height: 30px;
						background: $vertclair;
						color: $vert;
						border-radius: 50%;
						display: flex;
						justify-content: center;
						align-items: center;
						transition: all 0.3s ease;
						cursor: pointer;
						&:hover {
							background: $vert;
							color: white;
							transform: translateY(3px);
						}
					}
					.delete {
						width: 30px;
						height: 30px;
						background: $rougeclair;
						color: $rouge;
						border-radius: 50%;
						display: flex;
						justify-content: center;
						align-items: center;
						transition: all 0.3s ease;
						cursor: pointer;
						&:hover {
							background: $rouge;
							color: $rougeclair;
							transform: translateY(3px);
						}
					}
					.export {
						width: 30px;
						height: 30px;
						background: rgba(slategray, 0.05);
						color: slategray;
						border-radius: 50%;
						display: flex;
						justify-content: center;
						align-items: center;
						transition: all 0.3s ease;
						cursor: pointer;
						&:hover {
							background: slategray;
							color: white;
							transform: translateY(3px);
						}
					}
					.pointer-events-none {
						pointer-events: none;
						cursor: not-allowed;
					}
				}
				&.hidden {
					display: none;
				}
			}
		}
	}
	.chargement {
		display: flex;
		align-items: flex-start;
		padding: 30px;
		font-weight: 500;
		flex: 1;
		i {
			font-size: 20px;
			color: $rouge;
			margin-right: 10px;
			display: inline-block;
		}
		div {
			margin-top: 4px;
			font-weight: 300;
			font-size: 13px;
			line-height: 20px;
			strong {
				display: block;
				font-weight: bold;
				font-size: 15px;
			}
		}
	}
}
</style>
