<template>
	<section id="tab_utilisateurs">
		<div class="loading" v-if="!ready">
			<div>
				<i class="fal fa-spin fa-spinner"></i>
				Nous listons les utilisateurs. <br />Merci pour votre patience...
			</div>
		</div>
		<div class="filtres">
			<div class="search">
				<input type="text" v-model="search_user" placeholder="Nom ou prénom" />
				<i class="fa-light fa-search"></i>
			</div>
			<div class="statut">
				<select v-model="statut">
					<option value="tous">Filtrer par rôle</option>
					<option value="collaborateur">Collaborateur</option>
					<option value="editeur">Éditeur</option>
					<option value="administrateur">Administrateur</option>
					<option value="super_administrateur">Super Administrateur</option>
				</select>
			</div>
		</div>
		<div class="tablezone">
			<table cellpadding="0" cellspacing="0" id="tableUsers" class="table">
				<thead>
					<tr>
						<th width="25%">Nom</th>
						<th width="25%">Email</th>
						<th width="10%">Nombre de présentations</th>
						<th width="15%">Dernière connexion</th>
						<th width="15%">Rôle</th>
					</tr>
				</thead>
				<tbody v-if="ready">
					<tr v-for="user in final_users" :key="user.id" :data-id="user.id">
						<td>{{ user.fields.nom }}</td>
						<td>{{ user.fields.email }}</td>
						
						<td v-if="user.fields.presentations">{{ user.fields.presentations.length }}</td>
						<td v-else>0</td>
						<td>{{ user.fields.derniere_connexion_formatee }}</td>
						<td>
							<select :class="user.fields.role" @change="changeUserRole($event, user.id)">
								<option value="collaborateur" :selected="user.fields.role === 'collaborateur'">Collaborateur</option>
								<option value="editeur" :selected="user.fields.role === 'editeur'">Éditeur</option>
								<option value="administrateur" :selected="user.fields.role === 'administrateur'">Administrateur</option>
								<option value="super_administrateur" :selected="user.fields.role === 'super_administrateur'">Super Administrateur</option>
							</select>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</section>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
	name: "Utilisateurs",
	components: {},
	data() {
		return {
			ready: false,
			search_user: null,
			statut: "tous",
		};
	},
	computed: {
		...mapGetters({
			users: "users",
		}),
		final_users() {
			let temp = [...this.users];
			//trier par derniere_connexion
			temp.sort((a, b) => (a.fields.derniere_connexion < b.fields.derniere_connexion ? 1 : -1));
			if(this.search_user) {
				temp = temp.filter((el) => el.fields.nom.toLowerCase().includes(this.search_user.toLowerCase()));
			}
			if(this.statut !== "tous") {
				temp = temp.filter((el) => el.fields.role === this.statut);
			}
			return temp;
		},
	},
	methods: {
		...mapActions({
			getUsers: "getUsers",
		}),
		...mapMutations({
			showCreate: "showCreate",
			changeRole: "changeRole",
		}),
		changeUserRole(e, id) {
			this.changeRole({
				role: e.target.value,
				id: id,
			});
		},
	},
	mounted() {
		this.$store.dispatch("getUsers").then(() => {
			this.ready = true;
		});
	},
};
</script>

<style lang="scss">
#tab_utilisateurs {
	padding: 30px;
	position: relative;
	overflow: hidden;
	min-height: calc(100vh - 60px);
	.loading {
		padding: 40px;
		border-radius: 20px;
		background: $gris;
		div {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			text-align: center;
			font-weight: normal;
			font-size: 12px;
			line-height: 15px;
			i {
				color: $rouge;
				font-size: 20px;
				margin-bottom: 20px;
			}
		}
	}
	.tablezone {
		padding: 0px;
		border-radius: 20px;

		.dataTable-bottom {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			font-size: 11px;
			font-weight: 500;
			border-radius: 0 0 20px 20px;
			padding-bottom: 40px;
			.dataTable-info {
				padding: 0px 20px 15px 20px;
				opacity: 0.7;
				font-style: italic;
			}
			.dataTable-pagination {
				background: white;
				border-radius: 8px;
				box-shadow: 3px 3px 12px rgba(black, 0.05);
				ul {
					display: flex;
					margin: 0;
					padding: 0;
					list-style: none;
					li {
						border-left: solid 1px rgba(black, 0.05);
						transition: all 0.3s ease;
						a {
							color: black;
							padding: 12px 16px;
							display: block;
						}
						&.pager {
							font-size: 10px;
						}
						&.active {
							a {
								color: $rouge;
							}
						}
						&:hover {
							background: $gris;
						}
					}
				}
			}
		}
		.dataTable-top {
			display: flex;
			padding: 20px 0 20px 20px;
			align-items: center;
			justify-content: space-between;
			font-size: 12px;
			font-weight: 500;
			select {
				padding: 10px;
				border: none;
				border-radius: 15px;
				background: $gris;
				margin: 0 6px;
				font-weight: 500;
				outline: none;
			}
			.dataTable-search {
				border-radius: 15px;
				padding: 0;
				background: $gris;
				border-radius: solid 1px $gris2;
				border: solid 1px transparent;
				transition: all 0.3s ease;
				input {
					width: 100%;
					height: 43px;
					border: none;
					padding: 0 16px;
					border-radius: 15px;
					width: 280px;
					background: transparent;
					font-weight: 500;
					font-size: 13px;
				}
			}
		}
		table {
			width: 100%;
			background: white;
			border-radius: 8px;
			border: solid 1px $gris2;
			box-shadow: 3px 3px 12px rgba(black, 0.05);
			thead {
				tr {
					th {
						border-bottom: thin solid $gris2;
						height: 48px;
						padding: 0 10px;
						user-select: none;
						text-align: left;
						font-size: 11px;
						a {
							font-weight: 600;
							color: black !important;
						}
					}
				}
			}
			tbody {
				tr {
					padding: 0;
					margin: 0;
					border: none;
					cursor: pointer;
					td {
						border: none;
						border-bottom: thin solid $gris;
						height: 48px;
						font-size: 12px;
						padding: 0 10px;
						font-weight: 300;
						transition: all 0.3s ease;
						&.dataTables-empty {
							display: none;
						}
						&:nth-child(2),
						&:nth-child(3) {
							font-weight: bold;
						}
						&:last-child {
							span {
								background: $gris2;
								display: inline-block;
								padding: 6px 12px;
								font-weight: 500;
								font-size: 12px;
								border-radius: 20px;
								&.brouillon {
									background: #fdf4e0;
									color: $jaune;
								}
								&.pret {
									background: #f4f8ff;
									color: $bleu;
								}
								&.envoye {
									background: #e5f7ef;
									color: $vert;
								}
							}
						}
						select {
							background: transparent;
							color: black;
							font-weight: 500;
							border: none;
							appearance: none;
							outline: none;
							cursor: pointer;
							padding: 6px 14px;
							text-align: center;
							border-radius: 20px;
							&.super_administrateur {
								background: $rougeclair;
								color: $rouge;
							}
							&.administrateur {
								background: $jauneclair;
								color: $jaune;
							}
							&.editeur {
								background: $vertclair;
								color: $vert;
							}
							&.collaborateur {
								background: $gris;
								color: black;
							}
						}
					}
					&:hover {
						td {
							background: $gris;
						}
					}
				}
			}
		}
		.dataTable-container {
			padding: 40px;
			border-radius: 20px 20px 0 0;
			background: $gris;
			background-image: url("../assets/img/fond-alveole.png");
			background-position: top left;
			background-repeat: repeat;
		}
	}
	.filtres {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: 20px;
		margin-bottom: 20px;
		.statut {
			background: $gris2;
			padding: 3px 3px 3px 10px;
			border-radius: 4px;
			border: solid 1px $gris2;
			select {
				height: 34px;
				background: transparent;
				border: none;
				outline: none;
				color: rgba(black, .6);
				font-size: 12px;
				min-width: 200px;
			}
		}
		.search {
			display: flex;
			align-items: center;
			justify-content: space-between;
			background: $gris2;
			padding: 3px 3px 3px 10px;
			border-radius: 4px;
			border: solid 1px $gris2;
			&:hover {
				border: solid 1px $rouge;
			}
			input[type="text"] {
				background: transparent;
				border: none;
				outline: none;
				height: 30px;
				font-size: 12px;
				color: black;
				padding: 0 10px;
				width: 100%;
				min-width: 200px;
			}
			i {
				color: black;
				font-size: 12px;
				width: 40px;
				height: 34px;
				box-shadow: 0px 0px 2px rgba(black, .1);
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 2px;
				background: white;
			}
		}
	}
	
}
</style>
