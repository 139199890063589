<template>
	<section id="page404">
		<img class="logo" src="../assets/logo.png" />
		<h1>Oooooops !</h1>
		<p>Il semblerait que vous vous soyez perdu.e</p>
	</section>
</template>
<script>
export default {
	name: "page404",
	components: {},
	data() {
		return {};
	},
	methods: {},
};
</script>

<style lang="scss" scoped>
#page404 {
	background: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
	font-size: 20px;
	.logo {
		width: 150px;
		margin-top: -50px;
	}
	h1 {
		font-size: 100px;
		margin-bottom: 20px;
		margin-top: 0;
		&::first-letter {
			color: $rouge;
		}
	}
}
</style>
