<template>
	<div id="about">
		<Header />
		<section id="tutoriels">
			<div class="colonne-gauche">
				<ul>
					<li v-for="(item, index) in tutos" :key="`tuto${index}`" @click="setCurrentIndex(index)" :class="index === currentIndex ? 'selected' : ''">
						<span class="number">{{
							(index + 1).toLocaleString("en-US", {
								minimumIntegerDigits: 2,
								useGrouping: false,
							})
						}}</span>
						<h3>{{ item.name }}</h3>
						<div class="resume">
							{{ item.resume }}
						</div>
					</li>
				</ul>
			</div>
			<div class="colonne-droite" v-if="currentIndex >= 0">
				<h2>{{ tutos[currentIndex].name }}</h2>
				<div class="resume">{{ tutos[currentIndex].resume }}</div>
				<div class="steps">
					<div class="step" v-for="(step, index) in tutos[currentIndex].step" :key="`step${index}`">
						<div class="image"><img :src="require('../assets/img/tutoriels/' + step.image)" /></div>
						<div class="content">
							<div class="numero">
								{{
									(index + 1).toLocaleString("en-US", {
										minimumIntegerDigits: 2,
										useGrouping: false,
									})
								}}
							</div>
							<h4>{{ step.titre }}</h4>
							<div class="description" v-html="step.description" />
						</div>
					</div>
				</div>
			</div>
			<div class="colonne-droite" v-else>
				<p class="annonce"><strong>Commencez par choisir un tutoriel</strong></p>
			</div>
		</section>
	</div>
</template>

<script>
import tutoriels from "../assets/json/tutos.json";
import Header from "../components/Header";

export default {
	name: "tutoriels",
	components: { Header },
	data() {
		return {
			tutos: tutoriels,
			currentIndex: -1,
		};
	},
	mounted() {},
	methods: {
		setCurrentIndex(number) {
			this.currentIndex = number;
			window.scroll({ left: 0, top: 0, behavior: "smooth" });
		},
	},
};
</script>

<style lang="scss">
#about {
	background: white;
	#tutoriels {
		padding: 30px;
		display: flex;
		gap: 50px;
		width: 100%;
		align-items: flex-start;
		.colonne-gauche {
			flex: 0 0 300px;
			background: $gris;
			background-image: url("../assets/img/fond-alveole.png");
			background-position: top left;
			background-repeat: repeat;
			padding: 40px 20px 20px 40px;
			border-radius: 20px;
			overflow-y: auto;
			-webkit-overflow-scrolling: touch;
			height: calc(100vh - 140px);
			box-shadow: inset 2px 2px 6px rgba(black, 0.1);
			.title {
				font-size: 16px;
				font-weight: 500;
				margin-bottom: 20px;
			}
			ul {
				padding: 0;
				margin: 0;
				li {
					position: relative;
					padding: 10px;
					margin-bottom: 40px;
					cursor: pointer;
					transition: all 0.3s ease;
					span.number {
						font-size: 60px;
						text-transform: uppercase;
						color: rgba(black, 0.1);
						font-weight: bold;
						letter-spacing: 1px;
						position: absolute;
						top: -20px;
						left: -20px;
					}
					h3 {
						font-size: 18px;
						line-height: 21px;
						margin: 0 0 5px 0;
					}
					.resume {
						font-size: 12px;
						font-weight: 300;
						line-height: 16px;
						padding-right: 10px;
					}
					&.selected {
						color: $rouge;
					}
					&:hover {
						text-shadow: 1px 1px 2px rgba(black, 0.1);
					}
				}
			}
		}
		.colonne-droite {
			flex: 1;
			height: calc(100vh - 120px);
			overflow-y: auto;
			-webkit-overflow-scrolling: touch;
			.annonce {
				text-align: center;
				font-weight: bold;
				font-size: 13px;
				padding: 100px 50px 50px;
				strong {
					font-weight: 600 !important;
				}
			}
			h2 {
				font-size: 26px;
				font-weight: bold;
				margin-bottom: 10px;
				text-align: center;
			}
			.resume {
				font-size: 14px;
				font-weight: 300;
				line-height: 20px;
				padding-right: 10px;
				max-width: 500px;
				margin: 0 auto;
				text-align: center;
			}
			.steps {
				padding: 100px 0;
				.step {
					display: flex;
					align-items: center;
					max-width: 1100px;
					margin: 40px auto;
					.content {
						flex: 1;
						padding: 50px;
						position: relative;
						.description {
							font-size: 14px;
							line-height: 20px;
							font-weight: 300;
							b,
							strong {
								font-weight: 500 !important;
								text-decoration: underline;
							}
						}
						.numero {
							font-size: 40px;
							text-transform: uppercase;
							color: rgba($rouge, 0.03);
							font-weight: 900;
							transform: translate(-5px, 0px);
						}
						h4 {
							font-size: 18px;
							margin: 5px 0 10px;
						}
					}
					.image {
						flex: 1;
						background: $gris;
						padding: 20px;
						border-radius: 20px;
						img {
							width: 100%;
							height: auto;
						}
					}
				}
			}
		}
	}
}
</style>
