<template>
	<div :class="`col ${visible ? 'active' : ''}`">
		<h3 @click="visible = !visible">{{ titre }}</h3>
		<ul>
			<form @submit.prevent="addNewCat({ nom: item_name, type: type })">
				<input type="text" maxlength="24" required v-model="item_name" placeholder="Nom de la sous-catégorie" />
				<button type="submit">Ajouter</button>
			</form>
			<h6>Sous-catégories existantes</h6>
			<li v-for="item in categories" :key="item.id">
				<input type="text" v-model="item.fields.nom" @change="changeCatName({ id: item.id, type: type })" />
				<div class="tools">
					<span @click="deleteCat({ id: item.id, type: type })"> <i class="fal fa-trash"></i> Supprimer</span>
				</div>
			</li>
		</ul>
	</div>
</template>
<script>
import { mapMutations, mapGetters } from "vuex";

export default {
	name: "Categories",
	components: {},
	props: {
		type: String,
		titre: String,
	},
	data() {
		return {
			item_name: "",
			visible: false,
		};
	},
	computed: {
		categories() {
			return this.$store.state[this.type];
		},
	},
	methods: {
		...mapMutations({
			addNewCat: "addNewCat",
			deleteCat: "deleteCat",
			changeCatName: "changeCatName",
		}),
	},
};
</script>

<style lang="scss"></style>
