<template>
	<section id="modeles">
		<div class="les-modeles">
			<div class="thebarre">
				<h4>Recherche par mot clé</h4>
				<div class="search">
					<input type="search" v-model="search_modele" placeholder="Mots clés" @keyup="filterModeles()" />
					<button type="submit"><i class="fal fa-search"></i></button>
				</div>
				<h4>Langue</h4>
				<div class="radios">
					<div class="radio">
						<input type="radio" value="all-langues" id="all-types" v-model="selected_langue" @change="filterModeles()" />
						<label for="all-types">Tous les langues</label>
					</div>
					<div class="radio">
						<input type="radio" value="english" id="langue_en" v-model="selected_langue" @change="filterModeles()" />
						<label for="langue_en">English</label>
					</div>
					<div class="radio">
						<input type="radio" value="français" id="langue_fr" v-model="selected_langue" @change="filterModeles()" />
						<label for="langue_fr">Français</label>
					</div>
					<div class="radio">
						<input type="radio" value="espanol" id="langue_es" v-model="selected_langue" @change="filterModeles()" />
						<label for="langue_es">Espanol</label>
					</div>
					
				</div>

				<h4>Type de modèles</h4>
				<div class="radios">
					<div class="radio">
						<input type="radio" value="all-types" id="all-types" v-model="selected_type" @change="filterModeles()" /><label for="all-types"
							>Tous les types</label
						>
					</div>
					<div class="radio">
						<input type="radio" value="slidevierge" id="slidevierge" v-model="selected_type" @change="filterModeles()" />
						<label for="slidevierge">Vierge</label>
					</div>
					<div class="radio">
						<input type="radio" value="slideslide" id="slideslide" v-model="selected_type" @change="filterModeles()" /><label for="slideslide"
							>Prêt à l'emploi</label
						>
					</div>
					<div class="radio">
						<input type="radio" value="private" id="slideslide" v-model="selected_type" @change="filterModeles()" /><label for="slideslide">Privés</label>
					</div>
				</div>

				<div class="radios">
					<legend>Groupe Mauffrey</legend>
					<select v-model="selected_groupe" @change="filterModeles()">
						<option :value="null">Choisissez...</option>
						<option value="all-groupe_mauffrey">Tous</option>
						<option v-for="item in categories.groupe_mauffrey" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
					</select>
				</div>

				<div class="radios">
					<legend>Géographie</legend>
					<select v-model="selected_geographie" @change="filterModeles()">
						<option :value="null">Choisissez...</option>
						<option value="all-geographie">Tous</option>
						<option v-for="item in categories.geographie" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
					</select>
				</div>

				<div class="radios">
					<legend>Environnement / Sécurité</legend>
					<select v-model="selected_qualite" @change="filterModeles()">
						<option :value="null">Choisissez...</option>
						<option value="all-qualite">Tous</option>
						<option v-for="item in categories.qualite" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
					</select>
				</div>

				<div class="radios">
					<legend>Formation</legend>
					<select v-model="selected_formation" @change="filterModeles()">
						<option :value="null">Choisissez...</option>
						<option value="all-formation">Tous</option>
						<option v-for="item in categories.formation" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
					</select>
				</div>

				<div class="radios">
					<legend>Digitalisation</legend>
					<select v-model="selected_digitalisation" @change="filterModeles()">
						<option :value="null">Choisissez...</option>
						<option value="all-digitalisation">Tous</option>
						<option v-for="item in categories.digitalisation" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
					</select>
				</div>

				<div class="radios">
					<legend>Métiers</legend>
					<select v-model="selected_prestation" @change="filterModeles()">
						<option :value="null">Choisissez...</option>
						<option value="all-prestations">Tous</option>
						<option v-for="item in categories.metiers" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
					</select>
				</div>

				<div class="radios">
					<legend>Filières</legend>
					<select v-model="selected_filiere" @change="filterModeles()">
						<option :value="null">Choisissez...</option>
						<option value="all-filieres">Tous</option>
						<option v-for="item in categories.filieres" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
					</select>
				</div>

				<div class="radios">
					<legend>Matériel</legend>
					<select v-model="selected_materiel" @change="filterModeles()">
						<option :value="null">Choisissez...</option>
						<option value="all-materiel">Tous</option>
						<option v-for="item in categories.materiel" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
					</select>
				</div>

				<div class="radios">
					<legend>Toolbox</legend>
					<select v-model="selected_toolbox" @change="filterModeles()">
						<option :value="null">Choisissez...</option>
						<option value="all-toolbox">Tous</option>
						<option v-for="item in categories.toolbox" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
					</select>
				</div>

				<div class="radios">
					<legend>Procédures</legend>
					<select v-model="selected_procedures" @change="filterModeles()">
						<option :value="null">Choisissez...</option>
						<option value="all-procedures">Tous</option>
						<option v-for="item in categories.procedures" :key="item.id" :value="item.id">{{ item.fields.nom }}</option>
					</select>
				</div>

				<div class="reset" @click="resetFilters()">Effacer les filtres</div>
			</div>
			<div class="gallery populaires">
				<Header />
				<div v-if="!$store.state.squelettes_ready" class="chargement">
					<i class="fad fa-spinner-third fa-spin"></i>
					<div>
						<strong>Nous sommes en train de charger les modèles</strong>
						Le chargement des modèles peut durer un certain temps car ils sont nombreux. La vitesse de téléchargement dépend essentiellement de la vitesse
						de votre connexion.
						<br />Veuillez patienter quelques instants.
					</div>
				</div>
				<div
					:class="`${modele.classes} modele ligne all-types all-langues`"
					:style="`order: ${100000000 - modele.fields.useCounter}`"
					v-for="modele in modeles"
					:key="modele.id"
				>
					<div class="numero">Importé {{ modele.fields.useCounter }} fois</div>
					<div class="preview">
						<img :src="modele.fields.preview_url" v-if="modele.fields.preview_url" />
						<img src="https://mediabase.lezardscreation.fr/wp-content/uploads/image-vierge-1.png" v-else />
					</div>
					<div class="nom">
						<h2>
							{{ modele.fields.nom }}
							<span v-if="modele.fields.prive" class="private-mention">Privé</span>
							<div class="langue">
								<i class="fa-regular fa-globe"></i>
								<span>{{ modele.fields.langue }}</span>
							</div>
						</h2>
						
						<div class="categories">
							<i class="fa-regular fa-folders"></i>
							<span>Classé dans </span>
							<span class="tag" v-if="modele.fields.nom_filiere">{{ modele.fields.nom_filiere[0] }}</span>
							<span class="tag" v-if="modele.fields.nom_prestation">{{ modele.fields.nom_prestation[0] }}</span>
							<span class="tag" v-if="modele.fields.nom_procedure">{{ modele.fields.nom_procedure[0] }}</span>
							<span class="tag" v-if="modele.fields.nom_toolbox">{{ modele.fields.nom_toolbox[0] }}</span>
							<span class="tag" v-if="modele.fields.nom_materiel">{{ modele.fields.nom_materiel[0] }}</span>
							<span class="tag" v-if="modele.fields.nom_digitalisation">{{ modele.fields.nom_digitalisation[0] }}</span>
							<span class="tag" v-if="modele.fields.nom_formation">{{ modele.fields.nom_formation[0] }}</span>
							<span class="tag" v-if="modele.fields.nom_qualite">{{ modele.fields.nom_qualite[0] }}</span>
							<span class="tag" v-if="modele.fields.nom_geographie">{{ modele.fields.nom_geographie[0] }}</span>
							<span class="tag" v-if="modele.fields.nom_groupe">{{ modele.fields.nom_groupe[0] }}</span>
						</div>
						<div class="tags" v-if="modele.fields.tags">
							<i class="fa-regular fa-tags"></i>
							<span v-for="(tag, index) in modele.fields.tags.split(',')" :key="tag + index" class="tag">
								{{ tag }}
							</span>
						</div>
						<div class="author"><i class="fa-regular fa-user"></i>Créé par {{ modele.fields.nom_cree_par[0] }}</div>
						<div class="last_modified">
							<i class="fa-regular fa-clock"></i> Dernière modification le {{ modele.fields.derniere_modification | moment("D/MM/Y") }}
						</div>
					</div>
					<div class="actions">
						<div class="duplicate" @click="duplicateModele(modele.id)"><i class="fa-regular fa-copy"></i></div>
						<router-link :to="`/modele/${modele.id}`"><i class="fa-regular fa-edit"></i></router-link>
						<div class="delete" @click="deleteModele(modele.id)"><i class="fa-regular fa-trash"></i></div>
						<div class="export" @click="exportPPTModele(modele.id)">
							<i class="fa-solid fa-file-powerpoint" v-if="!$store.state.generation_en_cours"></i>
							<i class="fa-solid fa-spinner-third fa-spin pointer-events-none" v-else></i>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
import Header from "../components/Header";
import { mapGetters, mapActions, mapMutations } from "vuex";
import process from "process";
import moment from "moment";
export default {
	name: "Modeles",
	components: { Header },
	data() {
		return {
			vierge: "",
			search_modele: "",
			selected_type: "all-types",
			selected_filiere: null,
			selected_prestation: null,
			selected_geographie: null,
			selected_procedures: null,
			selected_toolbox: null,
			selected_materiel: null,
			selected_digitalisation: null,
			selected_formation: null,
			selected_qualite: null,
			selected_groupe: null,
			selected_langue: "all-langues",
		};
	},
	methods: {
		...mapActions({
			getModeles: "getModeles",
			exportPPTModele: "exportPPTModele",
			duplicateModele: "duplicateModele",
		}),
		...mapMutations({
			changeModeleName: "changeModeleName",
			deleteModele: "deleteModele",
		}),

		filterModeles() {
			let gallery_items = document.querySelectorAll(".ligne");
			console.log("je filtre");
			gallery_items.forEach((el) => {
				let count = 0;
				if (el.classList.contains(this.selected_type)) {
					count++;
				}
				if (el.classList.contains(this.selected_langue)) {
					count++;
				}
				if (this.search_modele.length >= 3) {
					let temp = 0;
					let mots_cles = `${el.querySelector(".nom h2").innerText}`;
					if (el.querySelector(".nom .tags")) {
						mots_cles += `${el.querySelector(".nom .tags").innerText}`;
					}
					if (
						mots_cles
							.toString()
							.toLowerCase()
							.normalize("NFD")
							.replace(/[\u0300-\u036f]/g, "")
							.replace(/'/g, "")
							.replace("’", "")
							.includes(
								this.search_modele
									.toString()
									.toLowerCase()
									.normalize("NFD")
									.replace(/[\u0300-\u036f]/g, "")
									.replace(/'/g, "")
									.replace("’", "")
							)
					) {
						temp++;
						console.log("je suis compris dedans");
					}
					if (temp > 0) {
						count++;
					}
				}
				if (
					this.selected_filiere != null ||
					this.selected_prestation != null ||
					this.selected_geographie != null ||
					this.selected_procedures != null ||
					this.selected_toolbox != null ||
					this.selected_materiel != null ||
					this.selected_digitalisation != null ||
					this.selected_formation != null ||
					this.selected_qualite != null ||
					this.selected_groupe != null
				) {
					if (
						el.classList.contains(this.selected_filiere) ||
						el.classList.contains(this.selected_prestation) ||
						el.classList.contains(this.selected_geographie) ||
						el.classList.contains(this.selected_procedures) ||
						el.classList.contains(this.selected_toolbox) ||
						el.classList.contains(this.selected_materiel) ||
						el.classList.contains(this.selected_digitalisation) ||
						el.classList.contains(this.selected_formation) ||
						el.classList.contains(this.selected_qualite) ||
						el.classList.contains(this.selected_groupe)
					) {
						count++;
					}
					if (this.search_modele.length >= 3) {
						if (count >= 4) {
							el.classList.remove("hidden");
						} else {
							el.classList.add("hidden");
						}
					} else {
						if (count >= 3) {
							el.classList.remove("hidden");
						} else {
							el.classList.add("hidden");
						}
					}
				} else {
					if (this.search_modele.length >= 3) {
						if (count >= 3) {
							el.classList.remove("hidden");
						} else {
							el.classList.add("hidden");
						}
					} else {
						if (count >= 2) {
							el.classList.remove("hidden");
						} else {
							el.classList.add("hidden");
						}
					}
				}
			});
		},
		resetFilters() {
			this.selected_filiere = null;
			this.selected_prestation = null;
			this.selected_geographie = null;
			this.selected_procedures = null;
			this.selected_toolbox = null;
			this.selected_materiel = null;
			this.selected_digitalisation = null;
			this.selected_formation = null;
			this.selected_qualite = null;
			this.selected_groupe = null;
			this.search_modele = "";
			this.selected_type = "all-types";
			this.filterModeles();
		},
	},
	computed: {
		...mapGetters({
			prestations: "prestations",
			filieres: "filieres",
			categories: "categories",
			modelesPresentations: "modelesPresentations",
			modelesSlidesVierges: "modelesSlidesVierges",
			modelesSlides: "modelesSlides",
			modelesGroupesSlides: "modelesGroupesSlides",
			modeles: "modeles",
		}),
	},
	mounted() {},
};
</script>

<style lang="scss">
#modeles {
	background: white;
	background-image: url("../assets/img/fondruche3-bis.jpg");
	background-position: bottom;
	background-size: contain;
	background-repeat: no-repeat;
	height: 100vh;
	background-attachment: fixed;
	.les-modeles {
		display: flex;
		padding: 0;
		align-items: stretch;
		flex-wrap: wrap;

		&:after {
			content: "";
			height: 10px;
			width: 100%;
			display: block;
			grid-column: span 3;
		}
		.thebarre {
			margin: 0;
			display: flex;
			flex-direction: column;
			gap: 10px;
			position: sticky;
			top: 0px;
			padding: 30px;
			flex: 0 0 360px;
			height: 100vh;
			background: rgba(white, 0.001);
			backdrop-filter: blur(10px);
			overflow-y: auto;
			-webkit-overflow-scrolling: touch;
			overscroll-behavior: none;
			box-shadow: 0px 0px 20px rgba(black, 0.2);
			h4 {
				font-weight: 500;
				margin-bottom: 2px;
				font-size: 12px;
				top: 30px;
			}
			.reset {
				font-size: 12px;
				font-weight: 500;
				color: #444;
				text-decoration: underline;
				padding: 4px 10px;
			}
			.radios {
				display: flex;
				flex-direction: column;
				margin-bottom: 12px;
				position: relative;
				padding: 16px 10px 4px;
				border: solid 1px darken($rougeclair, 6%);
				border-radius: 6px;
				legend {
					position: absolute;
					top: 0;
					left: 10px;
					font-weight: 500;
					color: $rouge;
					font-size: 12px;
					background: $rougeclair;
					padding: 3px 10px;
					transform: translateY(-50%);
					border: solid 1px $rougeclair;
					border-radius: 6px;
				}
				.radio {
					margin-bottom: 6px;
					display: flex;
					gap: 10px;
					align-items: center;
					font-size: 12px;
					font-weight: 500;
					cursor: pointer;
					color: lighten(black, 20%);
					transition: all 0.3s ease;
					&:hover {
						color: black;
					}
					input[type="radio"]:checked {
						background: $rouge;
					}
				}
			}
			select {
				width: auto;
				background: white;
				height: 28px;
				border: none;
				padding: 0 10px;
				font-size: 12px;
				font-weight: normal;
				transition: all 0.3s ease;
				outline: none;
			}
			.search {
				width: 100%;
				background: white;
				height: 40px;
				padding: 0;
				border: none;
				border-bottom: solid 1px black;
				margin-bottom: 30px;
				font-size: 13px;
				appearance: none;
				font-weight: 500;
				transition: all 0.3s ease;
				outline: none;
				display: flex;
				align-items: center;
				input {
					flex: 1;
					appearance: none;
					border: none;
					height: 40px;
					background: transparent;
					padding: 0 40px 0 0;
					font-size: 12px;
				}
				button {
					background: none;
					color: rgba(black, 0.5);
					padding: 0 0 0 10px;
					margin: 0;
					flex: 0 0 auto;
					font-size: 13px;
				}
			}
		}
		.gallery {
			display: block;
			flex: 1;
			padding-left: 20px;
			padding-bottom: 100px;
			.chargement {
				display: flex;
				align-items: flex-start;
				padding: 30px;
				font-weight: 500;
				flex: 1;
				i {
					font-size: 20px;
					color: $rouge;
					margin-right: 10px;
					display: inline-block;
				}
				div {
					margin-top: 0px;
					font-weight: 300;
					font-size: 13px;
					line-height: 20px;
					strong {
						display: block;
						font-weight: 600;
						font-size: 15px;
					}
				}
			}
			.ligne {
				display: flex;
				position: relative;
				align-items: flex-start;
				padding: 20px 10px 30px 10px;
				width: 100%;
				position: relative;
				transition: all 0.3s ease;
				background: white;
				z-index: 1;
				border-radius: 5px;
				&:hover {
					box-shadow: 0px 0px 20px rgba(black, 0.2);
					z-index: 999;
				}
				&:nth-child(odd) {
					background: lighten(slategray, 48%);
					&:hover {
						box-shadow: 0px 0px 20px rgba(black, 0.2);
						z-index: 999;
					}
				}
				.langue {
					color: $vert;
					background: $vertclair;
					font-size: 10px;
					padding: 2px 4px;
					border-radius: 4px;
					display: flex;
					align-items: center;
					i,
					svg {
						margin-right: 6px;
					}
				}
				.numero {
					position: absolute;
					top: 0px;
					left: 5px;
					font-size: 11px;
					padding: 3px 5px;
					font-weight: 500;
					z-index: 99;
					background: $jauneclair;
					color: $jaune;
					border-radius: 2px;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				.preview {
					width: 120px;
					overflow: hidden;
					border-radius: 4px;
					border: solid 2px white;
					box-shadow: 4px 4px 10px rgba(black, 0.1);
					flex: 0 0 auto;
					img {
						width: 100%;
					}
				}
				.nom {
					padding: 0px 20px;
					flex: 1;
					h2 {
						font-size: 16px;
						line-height: 20px;
						font-weight: 500;
						margin-bottom: 4px;
						display: flex;
						align-items: center;
						gap: 10px;
						.private-mention {
							padding: 3px 5px;
							border-radius: 2px;
							font-size: 10px;
							line-height: 10px;
							color: white;
							background: black;
						}
					}

					.author,
					.last_modified,
					.skeleton {
						color: slategray;
						font-size: 10px;
						padding: 3px 0;
						display: flex;
						align-items: center;
						i,
						svg {
							margin-right: 6px;
						}
					}
					.categories {
						display: flex;
						align-items: center;
						gap: 2px;
						color: $vertfonce;
						font-size: 10px;
						margin-bottom: 4px;
						i,
						svg {
							transform: translateY(1px);
						}
						span.tag {
							padding: 0.3em 0.6em;
							font-size: 10px;
							color: $vertfonce;
							border-radius: 2px;
							background: rgba($vertfonce, 0.05);
						}
					}
					.tags {
						display: flex;
						align-items: center;
						gap: 2px;
						color: $rouge;
						font-size: 10px;
						margin-bottom: 4px;
						i,
						svg {
							transform: translateY(1px);
						}
						span.tag {
							padding: 0.3em 0.6em;
							font-size: 10px;
							color: $rouge;
							border-radius: 2px;
							background: $rougeclair;
						}
					}
				}
				.actions {
					flex: 0 0 auto;
					display: flex;
					align-content: center;
					align-self: center;
					gap: 10px;
					a {
						width: 30px;
						height: 30px;
						background: $bleuclair;
						color: $bleu;
						border-radius: 50%;
						display: flex;
						justify-content: center;
						align-items: center;
						transition: all 0.3s ease;
						cursor: pointer;
						&:hover {
							background: $bleu;
							color: $bleuclair;
							transform: translateY(3px);
						}
					}
					.delete {
						width: 30px;
						height: 30px;
						background: $rougeclair;
						color: $rouge;
						border-radius: 50%;
						display: flex;
						justify-content: center;
						align-items: center;
						transition: all 0.3s ease;
						cursor: pointer;
						&:hover {
							background: $rouge;
							color: $rougeclair;
							transform: translateY(3px);
						}
					}
					.export {
						width: 30px;
						height: 30px;
						background: rgba(slategray, 0.05);
						color: slategray;
						border-radius: 50%;
						display: flex;
						justify-content: center;
						align-items: center;
						transition: all 0.3s ease;
						cursor: pointer;
						&:hover {
							background: slategray;
							color: white;
							transform: translateY(3px);
						}
					}
					.duplicate {
						width: 30px;
						height: 30px;
						background: $vertclair;
						color: $vert;
						border-radius: 50%;
						display: flex;
						justify-content: center;
						align-items: center;
						transition: all 0.3s ease;
						cursor: pointer;
						&:hover {
							background: $vert;
							color: white;
							transform: translateY(3px);
						}
					}
					.pointer-events-none {
						pointer-events: none;
						cursor: not-allowed;
					}
				}
				&.hidden {
					display: none;
				}
			}
			&.populaires {
				display: flex;
				flex-direction: column;
				.ligne {
					order: attr(data-counter);
				}
			}
		}
	}
}
</style>
